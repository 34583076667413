import { Accordion, Container, Row, Col, Image } from 'react-bootstrap'
import HandBG from './../../assets/images/backgrounds/hand-bg.svg'
import './Features.scss'

export default function Features() {
  return (
    <section className="features-section  section-spacing-top section-spacing-bottom">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="feat-intro">
              <h3 className="intro-title f-archivo">Features</h3>
              <p className="lined-subtitle">
                Our one-of-a-kind wallet built on private permissioned blockchain
                platform helps you to effortlessly invest in tokenized
                commercial private markets.
              </p>
            </div>
          </Col>

          <Col lg={6}>
            <div className="feat-hidden-bg">
              <Image src={HandBG} alt="features" className="d-lg-none" fluid />
            </div>
            <div className="feat-acc-wrap">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header as={'h3'} className="f-archivo">
                    <div className="acc-num me-4"> 01 </div> Multiple wallet
                    integrations
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      Link external wallets with our ERC 1155 supported
                      Digitrust wallet to facilitate quick and easy transfer of
                      funds along with swapping of crypto-tokens with Trust
                      tokens.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header as={'h3'} className="f-archivo">
                    <div className="acc-num me-4"> 02 </div> Automated payments
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      Receive timely payouts from shares purchased against Trust
                      Tokens listed on your private permissioned network.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header as={'h3'} className="f-archivo">
                    <div className="acc-num me-4"> 03 </div> Wallet Duality
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      Protect your investment from the volatility of the markets
                      as well as enjoy high returns by staking tokens for the
                      long term using DigiTrust as a Hot and Cold wallet
                      respectively.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header as={'h3'} className="f-archivo">
                    <div className="acc-num me-4"> 04 </div> Secured investment
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="mb-0">
                      Our encrypted wallet keeps ensures maximum security.
                      Assets in Digitrust wallet can be accessed only with your
                      private key making your investments transparent and
                      extremely secure.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
