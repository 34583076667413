import React from 'react'
import oneI from './../../assets/newAssets/TE/1.png'
import twoI from './../../assets/newAssets/TE/2.png'
import threeI from './../../assets/newAssets/TE/3.png'
import fourI from './../../assets/newAssets/TE/4.png'

const TTwo = () => {
  return (
    <div className="TEsecTwo">
      <div className="TEsecTwo-t">
        <h1>How it works?</h1>
        <h2>
          Deploy security tokens with the highly secure and robust tokenisation
          engine that also streamlines the full lifecycle for compliance,
          primary issuance, allocation, management and rapid transfer of
          security tokens.
        </h2>
      </div>
      <div className="TEsecTwo-b">
        <div className="itemTe">
          <div className="itemTe-l">
            <h1>01</h1>
            <div className="itcon">
              <h2>Compliance</h2>
              <h3>
              Security tokens represent securities issued using blockchain and they must adhere to the securities laws of issuers and investors, which is facilitated by the internet and blockchain technology in financial instrument issuance and lifecycle management.
              </h3>
            </div>
          </div>
          <div className="itemTe-r">
            <img src={oneI} alt="" />
          </div>
        </div>
        <div className="itemTe">
          <div className="itemTe-r">
            <img src={twoI} alt="" />
          </div>
          <div className="itemTe-l">
            <h1>02</h1>
            <div className="itcon">
              <h2>Issuance</h2>
              <h3>
              When security tokens are issued, they must be represented as permission tokens and allocated only to eligible and approved investors to implement compliant transfers.
              </h3>
            </div>
          </div>
        </div>
        <div className="itemTe">
          <div className="itemTe-l">
            <h1>03</h1>
            <div className="itcon">
              <h2>On chain custody</h2>
              <h3>
              With centralised tokenized securities on a decentralised infrastructure, issuers have control over their token supply and investors cannot lose their securities, no matter what happens to their wallets.
              </h3>
            </div>
          </div>
          <div className="itemTe-r">
            <img src={threeI} alt="" />
          </div>
        </div>
        <div className="itemTe">
          <div className="itemTe-r">
            <img src={fourI} alt="" />
          </div>
          <div className="itemTe-l">
            <h1>04</h1>
            <div className="itcon">
              <h2>Distribution</h2>
              <h3>
                The issuer can access an international network of distributors
                and investors, both in primary and secondary markets, by using a
                public blockchain and controlling compliance at the token level.
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TTwo
