import React from 'react'
import imgOne from '../../assets/Securex.png'
import imgkey from "../../assets/securexkey.png"
const SecurexTrustHero = () => {
  return (
    <div className="SecuritiesOne-Main WL Securix">
      <div className="SecuritiesOne-L">
        <div className="secTitle">
          <h1>
          Secure and Robust
            <span>Investment platform</span>
          </h1>
          <svg
            width="219"
            height="4"
            viewBox="0 0 219 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.99095"
              y1="2.95588"
              x2="217.991"
              y2="1.00993"
              stroke="url(#paint0_radial_5111_13207)"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <defs>
              <radialGradient
                id="paint0_radial_5111_13207"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(107.342 3.00681) rotate(-0.516165) scale(111.663 861)"
              >
                <stop stopColor="#219EBC" />
                <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>

          <p>
          Safeguard owner and investor assets from potential security threats and manual interference by leveraging latest crptographic encryption standards, hardware isolations, and PBFT consensus algorithms 

          </p>
        </div>

        
    

        <div className="scrollInd">
          Scroll{' '}
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1L6 6L1 0.999999"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="SecuritiesOne-R">
        <div className="srbsvg">
          <svg
            width="980"
            height="1025"
            viewBox="0 0 980 1025"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3">
              <circle
                opacity="0.1"
                cx="322.912"
                cy="555.582"
                r="217.589"
                transform="rotate(-15 322.912 555.582)"
                stroke="white"
                strokeWidth="2"
              />
              <path
                opacity="0.1"
                d="M604.704 480.078C642.575 621.416 558.801 766.666 417.592 804.503C276.383 842.34 131.208 758.437 93.336 617.098C55.4644 475.76 139.238 330.51 280.448 292.673C421.657 254.836 566.832 338.739 604.704 480.078Z"
                stroke="white"
                strokeWidth="2"
              />
              <path
                opacity="0.1"
                d="M681.389 459.53C726.371 627.406 626.849 799.932 459.102 844.88C291.356 889.827 118.904 790.176 73.9218 622.3C28.9397 454.425 128.462 281.898 296.208 236.951C463.955 192.003 636.406 291.654 681.389 459.53Z"
                stroke="white"
                strokeWidth="2"
              />
              <path
                opacity="0.1"
                d="M869.438 409.141C929.804 634.429 796.314 865.94 571.283 926.237C346.253 986.533 114.892 852.784 54.5261 627.496C-5.83964 402.208 127.65 170.697 352.681 110.401C577.711 50.1038 809.072 183.853 869.438 409.141Z"
                stroke="white"
                strokeWidth="2"
              />
            </g>
          </svg>
        </div>
        {/* <img style={{width: "837.72px", marginTop: "-20%"}} className="digiHero" src={imgOne} alt="" /> */}
        <img className="digiHero" src={imgOne} alt="" />
      </div>
    </div>
  )
}

export default SecurexTrustHero
