import React from 'react'
import './NSecTwoAP.scss'
import cardBG from './../../../assets/ApproachPage/new/cardTbg.png'
import cbg1 from './../../../assets/ApproachPage/new/cbg1.png'
import cbg2 from './../../../assets/ApproachPage/new/cbg2.png'
import cbg3 from './../../../assets/ApproachPage/new/cbg3.png'
import ad from './../../../assets/ApproachPage/new/ad.png'
import NSecThreeAP from './NSecThreeAP'
import NSecThreeApBtm from './NSecThreeApBtm'
import NSecFourAP from './../../SectionWhat/newWhat/NSecFourAP';

const NSecTwoAP = () => {
  return (
    <div className="newSD">
      <div className="NSecTwoAPMain">
        <div data-aos="fade-up" className="NSecTwoAPMainL">
          <h2>A unique Platform</h2>
          <p>
            Lilypads accelerates the investment velocity of investors and
            sponsors in an integrated way with institutional-grade resilient
            commercial real estate opportunities and the highly convenient
            investment vehicles of SPVs and Syndicates.
          </p>
          <button onClick={()=> window.open("https://cogniertechnology.com/contact", "_blank")} className="MainGetStartedBTN">
            Request Demo
            {/* <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i> */}
          </button>
        </div>
        <div className="NSecTwoAPMainR">
          {/* cardStart */}
          <div data-aos="fade-up" className="CardSAp">
            <img className="bgIc" src={cbg1} alt="" />
            <a href="#Investor">
              <img className="ad" src={ad} alt="" />
            </a>
            <div className="cTcon">
              <h2>Investors</h2>
              <p>
                The Lilypads real estate investor’s network allows you to expand your book of contacts to access opportunities and further grow your portfolio returns
              </p>
            </div>
          </div>
          {/* cardEnd */}

          {/* cardStart */}
          <div data-aos="fade-up" style={{ top: '60px' }} className="CardSAp card2">
            <img className="bgIc" src={cbg2} alt="" />
            <a href="#creditPartners">
              <img className="ad" src={ad} alt="" />
            </a>
            <div style={{ bottom: '130px' }} className="cTcon">
              <h2>Owner Operator</h2>
              <p>
              Real estate Owner Operators can indulge in asset digitization and tokenized ownership. Returns generated from the shares of tokenized assets are automatically transferred to Owner Operators' accounts without any intermediaries.              </p>
            </div>
          </div>
          {/* cardEnd */}

          {/* cardStart */}
          <div data-aos="fade-up" style={{ top: '-70px' }} className="CardSAp">
            <img className="bgIc" src={cbg3} alt="" />
            <a href="#brokers">
              <img className="ad" src={ad} alt="" />
            </a>
            <div style={{ bottom: '130px' }} className="cTcon">
              <h2>brokers </h2>
              <p>
                Lilypads empower the integrated community of brokers with custom tools and market leading technology like Online Professional Network, People Search, Address Book, Company Search and a complete marketing suite.
              </p>
            </div>
          </div>
          {/* cardEnd */}
        </div>
      </div>
      <div className="s1S" id="Investor">
        <NSecThreeAP />
      </div>
      <div className="s1S"  id="creditPartners">
        <NSecThreeApBtm />
      </div>

      <div className="s1S"  id="brokers">
        <NSecFourAP />
      </div>

    </div>
  )
}

export default NSecTwoAP
