import React, { useState, useEffect } from 'react'
import './NSecThreeWhat.scss'
import bhW from './../../../assets/what/whatbg.png'
import bhWp from './../../../assets/what/whatbgp.png'
import WhatCtgCard from './../../allCards/WhatCtgCard'
import rArrow from './../../../assets/LP/rArrow.svg'
import ResizeObserver, { useResizeDetector } from 'react-resize-detector'
const NSecThreeWhat = () => {
  const [posi, setPosi] = useState(1)
  let maxS = 2
  const { width, height, ref } = useResizeDetector()
  useEffect(() => {
    if (width < 1190) {
      maxS = 3
    }

    if (width <= 770) {
      maxS = 4
    }
  })
  function leftA(e) {
    e.preventDefault()

    setPosi(posi == 1 ? maxS : posi - 1)
  }
  function rightA(e) {
    e.preventDefault()

    setPosi(posi == maxS ? 1 : posi + 1)
  }
  return (
    <div ref={ref} className="NSecThreeWhatMain">
      <img className="bhWimg" src={bhW} alt="" />
      <img className="bhWimgMOB" src={bhWp} alt="" />
      <div data-aos="fade-up" className="NSecThreeWhatT">
        <p>
          Cognier Technology Group continuously unravels opportunities that drive the
          market and align with the investment goals of our clients
        </p>
      </div>
      <div  className="NSecThreeWhatTMOB">
        {/* CS */}
        <div className="nstwmC">
          <div className="nstwmCL">01.</div>
          <div className="nstwmCR">
            <div className="headTC">Technological Leverage</div>
            <div className="contentTC">
              Our industry expertise in AI/ML, Blockchain and Data Science
              advances the investment agenda of our partners and grants them
              access to opportunities demonstrating growth potential
            </div>
          </div>
        </div>
        {/* CE */}

        {/* CS */}
        <div className="nstwmC">
          <div className="nstwmCL">02.</div>
          <div className="nstwmCR">
            <div className="headTC">Strategy Development</div>
            <div className="contentTC">
              Our overall goal is to formulate compelling, scalable strategies
              including competitive analysis and market evaluation of lucrative
              opportunities that cater to the investment objectives of our
              clients
            </div>
          </div>
        </div>
        {/* CE */}

        {/* CS */}
        <div className="nstwmC">
          <div className="nstwmCL">03.</div>
          <div className="nstwmCR">
            <div className="headTC">Business Expansion</div>
            <div className="contentTC">
              Cognier Technology Group is instrumental in propelling the businesses of our partners
              through a reciprocal exchange of opportunities across our
              extensive network of Investors, Sponsors, Brokers
            </div>
          </div>
        </div>
        {/* CE */}

        {/* CS */}
        <div className="nstwmC">
          <div className="nstwmCL">04.</div>
          <div className="nstwmCR">
            <div className="headTC">Operation Management</div>
            <div className="contentTC">
              Our team utilizes their expertise in operational guidance to ease the technological transformation of organizations with optimization and automation of their investment processes to reap tremendous returns
            </div>
          </div>
        </div>
        {/* CE */}
      </div>
      <div
      
        className={
          'NSecThreeWhatM  ' +
          +(posi == 1 && ' one ') +
          (posi == 2 && ' two ') +
          (posi == 3 && ' three ') +
          (posi == 4 && ' four ') +
          (posi == 5 && ' five ') +
          (posi == 6 && ' six ')
        }
      >
        <WhatCtgCard
          number="01"
          title="Technological Leverage"
          content="   Our industry expertise in AI/ML, Blockchain and Data Science
          advances the investment agenda of our partners and grants them
          access to opportunities demonstrating growth potential"
        />
        <WhatCtgCard
          number="02"
          title="Strategy Development"
          content="Our overall goal is to formulate compelling, scalable strategies including competitive analysis and market evaluation of lucrative opportunities that cater to the investment objectives of our clients"
        />
        <WhatCtgCard
          number="03"
          title="Business Expansion"
          content="Cognier Technology Group is instrumental in propelling the businesses of our partners through a reciprocal exchange of opportunities across our extensive network of Investors, Sponsors, Brokers and so on."
        />
        <WhatCtgCard
          number="04"
          title="Operation management"
          content="Our team utilizes their expertise is operational guidance to ease the digital transformation of organizations with optimization and automation of their investment processes to reap tremendous returns"
        />
      </div>
      <div className="NSecThreeWhatB">
        <div 
          className={
            'PosiScrollI  ' +
            (posi == 1 && ' one ') +
            (posi == 2 && ' two ') +
            (posi == 3 && ' three ') +
            (posi == 4 && ' four ') +
            (posi == 5 && ' five ') +
            (posi == 6 && ' six ')
          }
        >
          <div className="PosiScrollIinside"></div>
        </div>
        <div data-aos="zoom-in"  className="BtnCons6n">
          <img onClick={leftA} className="aLimg" src={rArrow} alt="" />
          <img onClick={rightA} className="aRimg" src={rArrow} alt="" />
        </div>
      </div>
    </div>
  )
}

export default NSecThreeWhat
