import React, { useState, useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import './SecTwoWho.scss'
import rArrow from './../../assets/LP/rArrow.svg'
import c1 from './../../assets/who/c1.png'
import c2 from './../../assets/who/c2.png'
import c3 from './../../assets/who/c3.png'
import c4 from './../../assets/who/c4.png'
import secT from './../../assets/who/secT.png'
import SecTwoWhoCard from './../allCards/SecTwoWhoCard'

// card imgs
import oneT from './../../assets/LP/ListImg/01.svg'
import twoT from './../../assets/LP/ListImg/02.svg'
import threeT from './../../assets/LP/ListImg/03.svg'
import fourT from './../../assets/LP/ListImg/04.svg'
import Cbg from './../../assets/who/C.png'
import Canimation from './../Canimation';
const SecTwoWho = () => {
  const [posi, setPosi] = useState(false)
  const [sliderPosi, setSliderPosi] = useState(1)

  function rightA(e) {
    e.preventDefault()
    setSliderPosi(sliderPosi == 4 ? 1 : sliderPosi + 1)
  }

  function leftA(e) {
    e.preventDefault()
    setSliderPosi(sliderPosi == 1 ? 4 : sliderPosi - 1)
  }

  return (
    <div className="secTwoWho">
      <div className="secTwoWhoTop">
        <div className="secTwoWhoTDESK">
          <Canimation/>
        </div>
        {/* <img data-aos="fade-up"  className="deskI" src={secT} alt="" /> */}
        <div className="secTwoWhoTopIMOB">
          <div className="imgConSTW">
            <img className="ti" src={Cbg} alt="" />
            <img className="bi" src={Cbg} alt="" />
          </div>
          <div className="tText">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h1 style={{ color: '#fff' }}>Our Approach</h1>
            </div>
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h2>
                Cognier Technology Group implements research-backed data science approach and
                advanced platform technology innovations to aim for long-term
                growth and stand out in the crowd
              </h2>
            </div>
          </div>
          <div className="dividerC"></div>
          <SecTwoWhoCard
            i={oneT}
            head="Partnership"
            content="Cultivate long-term association with our partners through successive investments "
          />
          <SecTwoWhoCard
            i={twoT}
            head="Research"
            content="We thrive on our commitment to unlock untapped opportunities"
          />
          <SecTwoWhoCard
            i={threeT}
            head="Progress"
            content="We utilize our vast expertise in data science innovations for future-proof advancement"
          />
          <SecTwoWhoCard
            i={fourT}
            head="Value Generation"
            content="Our approach is scalable to the extent of unlocking superlative value for our clients"
          />
          <div className="secTwoWhoTopIMOBB">
            <h2>Our Strategies</h2>
            <p>
              Cognier Technology Group embodies unparalleled strategies that act
              as a blueprint for securing steady yields{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="bottomMOB">
        {/* CS */}
        <div
          onClick={() => {
            setSliderPosi(1)
          }}
          className={'bmCa ' + (sliderPosi == 1 && ' active ')}
        >
          <img src={c1} alt="" />
          <div className="headT">Alignment of Growth</div>
          <div className="contentT">
            <p>
              We have the ability to tap into the knowledge, resources,
              capabilities and broad network of Cognier Technology Group to add
              value to our platforms and businesses within the commercial
              real-estate industry
            </p>
          </div>
        </div>
        {/* CE */}

        {/* CS */}
        <div
          onClick={() => {
            setSliderPosi(2)
          }}
          className={'bmCa ' + (sliderPosi == 2 && ' active ')}
        >
          <img src={c2} alt="" />
          <div className="headT">Platform Building Approach</div>
          <div className="contentT">
            <p>
              We direct commercial real estate owners and operators to potentially lucurative, value-add areas through software platforms, thereby improving their asset performances
            </p>
          </div>
        </div>
        {/* CE */}

        {/* CS */}
        <div
          onClick={() => {
            setSliderPosi(3)
          }}
          className={'bmCa ' + (sliderPosi == 3 && ' active ')}
        >
          <img src={c3} alt="" />
          <div className="headT">Private Equity Investment Flexibility</div>
          <div className="contentT">
            <p>
              We maintain flexibility in the designs and time frames of our investments with long term capital as an individualizing factor. We redistribute these opportunities in acquisitions, joint ventures and asset investments.
            </p>
          </div>
        </div>
        {/* CE */}

        {/* CS */}
        <div
          onClick={() => {
            setSliderPosi(4)
          }}
          className={'bmCa ' + (sliderPosi == 4 && ' active ')}
        >
          <img src={c4} alt="" />
          <div className="headT">Research Beyond Capital</div>
          <div className="contentT">
            <p>
              We strive endlessly to empower and add value to our management
              team and capital by focusing primarily on extensive,
              sector-specific market research that corresponds ideas with
              reality
            </p>
          </div>
        </div>
        {/* CE */}
      </div>
      <div className="bottom">
        <div className="bSlider">
          <div className="bSControl">
            <div className="bSControlCon">
              <div data-aos="fade-up"  className="bsctcon">
                <h2>Our Strategies</h2>
                <h3>
                  Cognier Technology Group embodies unparalleled strategies that
                  act as a blueprint for securing steady yields.
                </h3>
              </div>
              <div className="BtnCons">
                <img onClick={leftA} className="aLimg" src={rArrow} alt="" />
                <img onClick={rightA} className="aRimg" src={rArrow} alt="" />
              </div>
            </div>
          </div>
          <div
            className={
              'bSildeCon  ' +
              (sliderPosi == 1 && ' one ') +
              (sliderPosi == 2 && ' two ') +
              (sliderPosi == 3 && ' three ') +
              (sliderPosi == 4 && ' four ')
            }
          >
            {/* CARD START */}
            <div  
            data-aos-duration="3500"
              onClick={() => {
                setSliderPosi(1)
              }}
              className={'sCard ' + (sliderPosi == 1 && ' active ')}
            >
              <div className="sCardCon">
                <div className="sCardT">
                  <img src={c1} alt="" />
                </div>
                <div className="sCardB">
                  <h2>
                    Alignment of <span>Growth</span>
                  </h2>
                  <h3>
                    We have the ability to tap into the knowledge, resources,
                    capabilities and broad network of Cognier Technology Group
                    to add value to our platforms and businesses within the
                    commercial real-estate industry.
                  </h3>
                </div>
              </div>
            </div>
            {/* CARD END */}

            {/* CARD START */}
            <div  
              onClick={() => {
                setSliderPosi(2)
              }}
              className={'sCard ' + (sliderPosi == 2 && ' active ')}
            >
              <div className="sCardCon">
                <div className="sCardT">
                  <img src={c2} alt="" />
                </div>
                <div className="sCardB">
                  <h2>
                    Platform Building <span>Approach</span>{' '}
                  </h2>
                  <h3>
                    We build platforms and direct commercial real estate owners
                    and operators to potentially lucurative, value-add areas
                    through software platforms, thereby improving their asset
                    performances
                  </h3>
                </div>
              </div>
            </div>
            {/* CARD END */}

            {/* CARD START */}
            <div 
              onClick={() => {
                setSliderPosi(3)
              }}
              className={'sCard ' + (sliderPosi == 3 && ' active ')}
            >
              <div className="sCardCon">
                <div className="sCardT">
                  <img src={c3} alt="" />
                </div>
                <div className="sCardB">
                  <h2 style={{ width: '100%' }}>
                    Private Equity
                    <span> Investment Flexibility</span>
                  </h2>
                  <h3>
                    We maintain flexibility in the designs and time frames of
                    our investments with long term capital as an individualizing
                    factor. We redistribute these opportunities in acquisitions
                    joint ventures, asset investments and so on
                  </h3>
                </div>
              </div>
            </div>
            {/* CARD END */}

            {/* CARD START */}
            <div 
              onClick={() => {
                setSliderPosi(4)
              }}
              className={'sCard ' + (sliderPosi == 4 && ' active ')}
            >
              <div className="sCardCon">
                <div className="sCardT">
                  <img src={c4} alt="" />
                </div>
                <div className="sCardB">
                  <h2>Research Beyond Capital</h2>
                  <h3>
                    We strive endlessly to empower and add value to our
                    management team and capital by focusing primarily on
                    extensive, sector-specific market research that corresponds
                    ideas with reality
                  </h3>
                </div>
              </div>
            </div>
            {/* CARD END */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecTwoWho
