import React, { useRef, useState, useEffect } from "react";
import "./SecOneApplication.scss";
import { useSelector, useDispatch } from "react-redux";
import Header from "./../Header";
import arrowDown from "./../../assets/arrowDown.svg";
import axios from "axios";
import Footer from './../SectionsLP/Footer';
const SecOneApplication = () => {
  const jdR = useSelector((state) => state.jdR);

  //Dropdown
  const [dropYears, setdropYears] = useState(false);
  const [dropMonths, setdropMonths] = useState(false);
  let dropRef = useRef();
  let dropmRef = useRef();

  function yearsDD(e) {
    e.preventDefault();
    setdropYears(!dropYears);
  }
  function monthsDD(e) {
    e.preventDefault();
    setdropMonths(!dropMonths);
  }

  //Form REF
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [previousPosition, setPreviousPosition] = useState("");
  const [message, setMessage] = useState("");
  const [expYears, setExpYears] = useState("");
  const [expMonths, setExpMonths] = useState("");
  const [resume, setResume] = useState(null);
  // const jobId = `${jdR + 1}`;
  const jobId = `${jdR }`;

  const experience = `${expYears}` + "." + `${expMonths}` + " years";

  function resumeFileChange(e) {
    if (e.target.files && e.target.files.length !== 0) {
      const uploadedFile = e.target.files[0];

      if (resume?.name !== uploadedFile.name) {
        setResume(uploadedFile);
        // console.log(resume);
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    console.log(jobId);
    const config = {
      headers: {
        "X-Api-Key": "VbrBlQq1.02f1dcYZaguPQNqg6vEyHWolN9NghIvl",
      },
    };

    const body = new FormData();

    body.append("name", fullName);
    body.append("previous_position", previousPosition);
    body.append("experience", experience);
    body.append("resume", resume);
    body.append("email_address", email);
    body.append("phone_number", phoneNo);
    body.append("message", message);
    body.append("job", jobId);
    const url = `https://api.cogniertechnology.com/api/applications/`;

    if (expYears == "" || expMonths == "") {
      alert("enter experience");
    } else {
      axios
        .post(url, body, config)
        .then((response) => {
          console.log(response);
          console.log("successfully submitted application");
          alert("success");
        })
        .catch((error) => {
          console.log(error.response);
          alert("Fail to submit");
        });
    }
  }

  //to close dropdown if click elsewhere

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      try {
        if (!dropRef.current.contains(e.target)) {
          setdropYears(false);
        }
        if (!dropmRef.current.contains(e.target)) {
          setdropMonths(false);
        }
      } catch (event) {
        // console.log("mouseDown");
      }
    });
  }, []);

  return (
    <div className="SecOneApplicationMain">
      <Header active="5" />
      <div className="SecOneApplicationMainWrapper">
        <div className="leftApplication">
          <div className="leftApplicationWrapper">
          <span>
            Cognier {">"} Careers 
          </span>
          <h2>Application Form</h2>
          </div>
        
        </div>
        <div className="rightApplication">
          <form onSubmit={handleSubmit}>
            <div className="formItemCon">
              <span>Full Name</span>
              <input
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="formItemCon">
              <span>Email Address</span>
              <input
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="formItemCon">
              <span>Phone No.</span>
              <input
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </div>

            <div className="formItemCon">
              <span>Previous Positon</span>
              <input
                required
                value={previousPosition}
                onChange={(e) => setPreviousPosition(e.target.value)}
              />
            </div>
            <div className="expCon">
              <div className="formItemCon">
                <span>Experience</span>
                {/* <span>{expYears}</span> */}
                <div
                  ref={dropRef}
                  onClick={yearsDD}
                  className={"dropdown " + (dropYears && " active ")}
                >
                  <div className="dropdown-select ">
                    <span className="select">{expYears} Years</span>
                    <img src={arrowDown} alt="" />
                  </div>
                  <div className="dropdown-list">
                    <div
                      onClick={() => {
                        setExpYears("0");
                      }}
                      className="dropdown-list-item"
                    >
                      0 year
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("1");
                      }}
                      className="dropdown-list-item"
                    >
                      1 year
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("2");
                      }}
                      className="dropdown-list-item"
                    >
                      2 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("3");
                      }}
                      className="dropdown-list-item"
                    >
                      3 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("4");
                      }}
                      className="dropdown-list-item"
                    >
                      4 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("5");
                      }}
                      className="dropdown-list-item"
                    >
                      5 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("6");
                      }}
                      className="dropdown-list-item"
                    >
                      6 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("7");
                      }}
                      className="dropdown-list-item"
                    >
                      7 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("8");
                      }}
                      className="dropdown-list-item"
                    >
                      8 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("9");
                      }}
                      className="dropdown-list-item"
                    >
                      9 years
                    </div>
                    <div
                      onClick={() => {
                        setExpYears("10+");
                      }}
                      className="dropdown-list-item"
                    >
                      10 years +
                    </div>
                  </div>
                </div>
              </div>

              <div className="formItemCon">
                <div
                  ref={dropmRef}
                  onClick={monthsDD}
                  className={"dropdown " + (dropMonths && " active ")}
                >
                  <div className="dropdown-select ">
                    <span className="select">{expMonths} Months</span>
                    <img src={arrowDown} alt="" />
                  </div>
                  <div className="dropdown-list">
                    <div
                      onClick={() => {
                        setExpMonths("0");
                      }}
                      className="dropdown-list-item"
                    >
                      0 month
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("1");
                      }}
                      className="dropdown-list-item"
                    >
                      1 month
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("2");
                      }}
                      className="dropdown-list-item"
                    >
                      2 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("3");
                      }}
                      className="dropdown-list-item"
                    >
                      3 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("4");
                      }}
                      className="dropdown-list-item"
                    >
                      4 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("5");
                      }}
                      className="dropdown-list-item"
                    >
                      5 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("6");
                      }}
                      className="dropdown-list-item"
                    >
                      6 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("7");
                      }}
                      className="dropdown-list-item"
                    >
                      7 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("8");
                      }}
                      className="dropdown-list-item"
                    >
                      8 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("9");
                      }}
                      className="dropdown-list-item"
                    >
                      9 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("10");
                      }}
                      className="dropdown-list-item"
                    >
                      10 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("11");
                      }}
                      className="dropdown-list-item"
                    >
                      11 months
                    </div>
                    <div
                      onClick={() => {
                        setExpMonths("12");
                      }}
                      className="dropdown-list-item"
                    >
                      12 months
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="formItemCon filecon">
              <span> Resume</span>
                      <label for="resume" className="fileT">Drag {"&"} drop or <p> Browse </p>  your CV</label>
              <input
                onChange={resumeFileChange}
                type="file"
                id="resume"
                defaultValue={resume?.name || ""}
                className="file"
                required
              />
            </div>
            <div className="formItemCon messageCon">
              <span>Why do you want to join Cognier Technology?</span>
              <textarea
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
      < Footer/>
    </div>
  );
};

export default SecOneApplication;
