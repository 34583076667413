import SecOneCL from './../components/SectionCL/SecOneCL'
import Footer from './../components/SectionsLP/Footer'

import SecTwoCl from './../components/SectionCL/SecTwoCl'
import { useEffect } from 'react'
import SecTwoCLNew from './../components/SectionCL/SecTwoCLNew'
import imgCl from './../assets/PAGESIMG/Careerlisting.png'
import Header from './../components/Header'
import SecOneCLNew from './../components/SectionCL/SecOneCLNew'

const CareerListingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Header />
      {/* <SecOneCL /> */}
      {/* <img
        style={{ paddingBottom: '60px' }}
        className="w-100"
        src={imgCl}
        alt=""
      /> */}
      <SecOneCLNew />
      {/* <SecTwoCl /> */}
      <SecTwoCLNew />

      {/* <Footer /> */}
    </div>
  )
}

export default CareerListingPage
