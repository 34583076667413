
export const std =(num)=>{
    return{
        type: 'SET',
        payload: num

    };
};


export const nds =(num)=>{
    return{
        type: 'NDSET',
        payload: num

    };
};

export const current = (data) => {
    return{
        type: 'CURRENT', 
        payload: data
    }
}