import React from 'react'
import oneI from './../../assets/newAssets/TE/b1.png'
import twoI from './../../assets/newAssets/TE/b2.png'
import threeI from './../../assets/newAssets/TE/b3.png'
import fourI from './../../assets/newAssets/TE/4.png'

const TEsecTwo = () => {
  return (
    <div className="TEsecTwo">
      {/* <div className="TEsecTwo-t">
        <h1>How it works?</h1>
        <h2>
          Deploy security tokens with the highly secure and robust tokenisation
          engine that also streamlines the full lifecycle for compliance,
          primary issuance, allocation, management and rapid transfer of
          security tokens.
        </h2>
      </div> */}
      <div className="TEsecTwo-b">
        <div className="itemTe">
          <div className="itemTe-l">
            <h1>01</h1>
            <div className="itcon">
              <h2>Secure Setllements</h2>
              <h3>
              The Blubloc Network eliminates any kind of cyber attacks, internal fraud and exploits by automatically reassigning addresses to each user on a predefined frequency
              </h3>
            </div>
          </div>
          <div className="itemTe-r">
            <img src={oneI} alt="" />
          </div>
        </div>
        <div className="itemTe">
          <div className="itemTe-r">
            <img src={twoI} alt="" />
          </div>
          <div className="itemTe-l">
            <h1>02</h1>
            <div className="itcon">
              <h2>Real-time insight into account</h2>
              <h3>
              Lilypads provide you insight into your investment account and helps you improve performance by providing access to real-time market updates, news feeds, and premium research
              </h3>
            </div>
          </div>
        </div>
        <div className="itemTe">
          <div className="itemTe-l">
            <h1>03</h1>
            <div className="itcon">
              <h2>Visibility</h2>
              <h3>
              Lilypads AI-based platform detects investment-grade properties and top deals making your property searching process seamless and efficient. 
              </h3>
            </div>
          </div>
          <div className="itemTe-r">
            <img src={threeI} alt="" />
          </div>
        </div>
        {/* <div className="itemTe">
          <div className="itemTe-r">
            <img src={fourI} alt="" />
          </div>
          <div className="itemTe-l">
            <h1>04</h1>
            <div className="itcon">
              <h2>Distribution</h2>
              <h3>
                The issuer can access an international network of distributors
                and investors, both in primary and secondary markets, by using a
                public blockchain and controlling compliance at the token level.
              </h3>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default TEsecTwo
