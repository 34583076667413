import React, { useState, useEffect, useRef } from 'react'

import Sec6P1 from './../../assets/Founders/left.png'
import Sec6SP1 from './../../assets/Founders/sLeft.svg'
import Sec6P2 from './../../assets/Founders/mid.png'
import leftD from './../../assets/Founders/leftD.png'
import midD from './../../assets/Founders/midD.png'
import joeJs from './../../assets/Founders/joe.json'
import rightD from './../../assets/Founders/rightD.png'
import Sec6SP2 from './../../assets/Founders/sMid.svg'
// import Sec6P3 from "./../../assets/Founders/right.png";
import Sec6P3 from './../../assets/Founders/joe.gif'
import joeStatic from './../../assets/Founders/joeStatic.png'
import Sec6SP3 from './../../assets/Founders/sRight.svg'
import Sec6P4 from './../../assets/Sec6P4.svg'
import Sec6OurTeam from './../../assets/Sec6OurTeam.svg'
import useMousePosition from './../MousePosition'
import './FounderPhoto.scss'
import Aos from 'aos'
import 'aos/dist/aos.css'

const FounderPhoto = () => {
  const [memberName, setMemberName] = useState('Thomas lah')
  const [memberLinkedIn, setmemberLinkedIM] = useState(
    'https://www.linkedin.com/in/thomas-p-lah/',
  )
  const [photo1, setphoto1] = useState()
  const [photo2, setphoto2] = useState(true)
  const [photo3, setphoto3] = useState()
  const [photoANo, setphotoANo] = useState('two')
  const [memberDesc, setmemberDesc] = useState(
    "Thomas P. Lah plans Cognier Technology Group's strategic business approach in the real estate industry by coming up with an effective business blueprint by evaluating the current level of growth, market need, programs, policies, procedures, and regulations used by the firm in order to ensure effective and prudent management of the resources and business processes. Thomas believes in the need to create products and processes that greatly and positively affect the community in which we live and operate",
  )

  function teamMemberOne(e) {
    e.preventDefault()
    setMemberName('Karthik Krishnan ( EVP )')
    setmemberLinkedIM('https://www.linkedin.com/in/kkrishnan93/')
    setphoto1(true)
    setphotoANo('one')
    setphoto2()
    setphoto3()
    setmemberDesc(
      'Karthik Krishnan has been shaping Cognier’s strategic outcomes, financial strength, and operational efficiency nearly since inception.  Karthik serves as the corporation’s chief financial and administrative point of contact.  His wide-ranging portfolio includes several business functions: accounting and finance; compliance, oversight, and governance; board and shareholder management; HR and vendor management; and strategic capital investments.  Karthik is obsessed with building efficient and innovative solutions that simplify, reimagine, and transform legacy processes.',
    )
    console.log('one')
  }
  function teamMemberTwo(e) {
    e.preventDefault()
    setMemberName('Thomas Lah ( CEO )')
    setmemberLinkedIM('https://www.linkedin.com/in/thomas-p-lah/')
    setphoto1()
    setphoto2(true)
    setphotoANo('two')
    setphoto3()
    setmemberDesc(
      "Thomas P. Lah plans Cognier Technology Group's strategic business approach in the real estate industry by coming up with an effective business blueprint by evaluating the current level of growth, market need, programs, policies, procedures, and regulations used by the firm in order to ensure effective and prudent management of the resources and business processes. Thomas believes in the need to create products and processes that greatly and positively affect the community in which we live and operate",
    )
    console.log('two')
  }
  function teamMemberThree(e) {
    e.preventDefault()
    setMemberName('Joe Copsey ( COO )')
    setmemberLinkedIM('https://www.linkedin.com/in/joecopsey/')
    setphoto1()
    setphoto2()
    setphoto3(true)
    setphotoANo('three')
    setmemberDesc(
      "Joe Copsey, is first and foremost the company's culture steward. An adamant believer in the holistic business approach, Joe is responsible for the execution of day-to-day strategies / sales processes and consistently strives to implement ways to be cost effective and efficient in tieing all departments together for our clients and business partners. Joe is passionate about removing barriers to bring investors and the Commercial Real Estate community together with a world class software plartform via Lilypads. ",
    )
    console.log('three')
  }

  return (
    <div className="FounderPhotoMainComp">
      <div className="FounderPhotoMainCompCon">
        <div className="FPTT">
          <h3>Entreprenuers, Innovators and Engineers</h3>
        </div>
        <div data-aos="fade-right" className="left">
          <div className={'imgcon ' + `${photoANo}`}>
            <div
              onMouseOver={teamMemberOne}
              className={'ImgSC ' + (photo1 && ' active ') + ' imgOne '}
            >
              <img
                className="img "
                onMouseOver={teamMemberOne}
                // src={Sec6P1}
                src={leftD}
                alt="Sec6P1"
              />
              <img className="imgS" src={Sec6SP1} alt="Sec6SP1" />
              <div className="blueOverlay"></div>
            </div>

            <div
              onMouseOver={teamMemberTwo}
              className={'ImgSC  ' + (photo2 && ' active ') + ' imgOne '}
            >
              <img
                className="img  imgWdth "
                onMouseOver={teamMemberTwo}
                // src={Sec6P2}
                src={midD}
                alt="Sec6P2"
              />

              <img className="imgS" src={Sec6SP2} alt="Sec6SP2" />
              <div className="blueOverlay"></div>
            </div>
            <div
              onMouseOver={teamMemberThree}
              className={'ImgSC ' + (photo3 && ' active ') + ' imgOne '}
            >
              <img
                className="img"
                onMouseOver={teamMemberThree}
                src={rightD}
                alt="Sec6P3"
              />

              {/* <img className="staticImg" src={joeStatic} alt="" /> */}

              <img className="imgS lastS" src={Sec6SP3} alt="Sec6SP3" />
              <div className="blueOverlay"></div>
            </div>
            {/* <div className="containerrr" ref={containerrr}></div> */}
          </div>
        </div>
        <div data-aos="fade-left" className="right">
          <div className="rightWrapper">
            <h2>{memberName}</h2>
            <span>{memberDesc}</span>
            <div className="socialLinks">
              <ul>
                {/* <li>
                  <a href="#">Facebook</a>
                </li>
                <li>
                  <a href="#">Instagram</a>
                </li> */}
                <li>
                  <a href={memberLinkedIn}>LinkedIn</a>
                </li>
              </ul>
            </div>
          </div>
          <img src={Sec6OurTeam} alt={Sec6OurTeam} />
        </div>
      </div>
    </div>
  )
}

export default FounderPhoto
