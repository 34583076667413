import React from "react";
import "./Footer.scss";
import footerLogo from "./../../assets/footerLogo.svg";

import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { GrLinkedinOption } from "react-icons/gr";
import { Link } from "react-router-dom";
import NSecFourAP from "./../SectionWhat/newWhat/NSecFourAP";
export default function Footer(props) {
  return (
    <div className={"footerM " + (props.footerGap && " footerGap") + (props.mode) }>
      <NSecFourAP mode={props.mode} />
      <div style={{paddingTop:"20px"}} className="footerMainWrapperMOBN">
        <div className="TSec">
          <div className="TLi">EXPLORE</div>
          <div className="MLi">
            <div className="MLiL">
          
              <Link to="/whiteLabelSolution">White Label Solution</Link>
              <Link to="/multi-signature-wallet">Products</Link>
            </div>
            <div className="MLiR">
            <Link to="/who">Company</Link>
            <Link to="/multi-signature-wallet">Products</Link>
          
            </div>
          </div>
         
        </div>
        <div className="dL"></div>
        <div className="MFotS">
          <h3>Reach Us At</h3>
          <h4>6751 Columbia Gateway Dr., 3rd floor, Columbia, MD 21046</h4>
          <div className="socialLinks">
            <a
              style={{ marginRight: "30px" }}
              href="https://www.linkedin.com/company/cognier-technology/about/"
            >
              <GrLinkedinOption />
            </a>
            <a
              style={{ marginRight: "30px" }}
              href="https://www.facebook.com/CognierTechnologyGroup"
            >
              <FiFacebook />
            </a>
            <a
              style={{ marginRight: "30px" }}
              href="https://www.instagram.com/lilypadsio/"
            >
              <FiInstagram />
            </a>
            <a href="https://twitter.com/lilypads_io">
              <FiTwitter />
            </a>
          </div>
          <img src={footerLogo} alt="" />
        </div>
        <div className="BFotS">
          <div className="bfttcon">
            <Link to="/TermsAndCondition">Terms of use</Link>
            <div className="midDIVL"></div>
            <Link to="/PrivacyPolicy">Privacy Policy</Link>
          </div>
          <p>(C) Copyright 2022 by COGNIER TECHNOLOGY. All rights reserved</p>
          {/* <h6>
            Designed with <span>❤</span> at codesis.io
          </h6> */}
        </div>
      </div>
      <div className="footerMainWrapper">
        {/* <div className="top">
          <h2>EXPLORE</h2>
          <ul>
            <li>
              <Link exact to="/">
                Home
              </Link>
            </li>
            <span className="line">|</span> <li>
              <Link to="/whiteLabelSolution">White Label Solution</Link>
            </li>
            <span className="line">|</span>
            <li>
              <Link to="/multi-signature-wallet">Products</Link>
            </li>
            <span className="line">|</span>
           
            <li>
              <Link to="/who">Company</Link>
            </li>
         
          </ul>
        </div> */}
        <div className="mid">
          <div className="left">
            <div className="imgCon">
              <img src={footerLogo} alt="" />
            </div>
          </div>
          <div className="right">
            <div className="reachuswrapper">
              <h2>Reach Us At</h2>
              <span>
                6751 Columbia Gateway Dr., 3rd floor, Columbia, MD 21046
              </span>
              <div className="socialLinks">
                <a href="https://www.linkedin.com/company/cognier-technology/about/">
                  <GrLinkedinOption />
                </a>
                <a href="https://www.facebook.com/CognierTechnologyGroup">
                  <FiFacebook />
                </a>
                <a href="https://www.instagram.com/lilypadsio/">
                  <FiInstagram />
                </a>
                <a href="https://twitter.com/lilypads_io">
                  <FiTwitter />
                </a>
                {/* <FiInstagram /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="end">
          <div className="left">
            <ul>
              <li>
                <Link to="/TermsAndCondition">Terms of use</Link>
              </li>
              <span className="line">|</span>
              <li>
                <Link to="/PrivacyPolicy">Privacy Policy</Link>
              </li>
              <span className="line">|</span>
              <li>
                <Link to="/Careers">Careers</Link>
              </li>
            </ul>
          </div>
          <div className="right">
            <span>
              (C) Copyright 2022 by COGNIER TECHNOLOGY. All rights reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
