import React from 'react'
import imgOne from './one.png'
import './Securities.scss'
const SecuritiesOne = () => {
  return (
    <div className="SecuritiesOne-Main">
      <div className="SecuritiesOne-L">
        <div className="secTitle">
          <h1>
            Trade assets using<span> Real Asset Securities</span>
          </h1>
          <svg
            width="219"
            height="4"
            viewBox="0 0 219 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.99095"
              y1="2.95588"
              x2="217.991"
              y2="1.00993"
              stroke="url(#paint0_radial_5111_13207)"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <defs>
              <radialGradient
                id="paint0_radial_5111_13207"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(107.342 3.00681) rotate(-0.516165) scale(111.663 861)"
              >
                <stop stopColor="#219EBC" />
                <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>

          <p>
            Real asset investment is now simple and accessible with
            fractionalization of tokenized assets and trading of the same by
            purchasing and selling of Trust Tokens.
          </p>
        </div>
        <svg
          className="svgTwo"
          width="231"
          height="221"
          viewBox="0 0 231 221"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_5111_14813)">
            <rect
              x="30.5"
              y="20.5"
              width="46.3206"
              height="159.245"
              stroke="url(#paint0_linear_5111_14813)"
            />
            <rect
              x="92.875"
              y="63.5195"
              width="45.2451"
              height="116.226"
              stroke="url(#paint1_linear_5111_14813)"
            />
            <rect
              x="154.18"
              y="105.461"
              width="46.3206"
              height="74.2828"
              stroke="url(#paint2_linear_5111_14813)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_5111_14813"
              x="0"
              y="0"
              width="231"
              height="220.246"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="10" />
              <feGaussianBlur stdDeviation="15" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_5111_14813"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_5111_14813"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_5111_14813"
              x1="30"
              y1="20"
              x2="128.78"
              y2="136.68"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5111_14813"
              x1="92.375"
              y1="63.0195"
              x2="158.628"
              y2="167.566"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_5111_14813"
              x1="153.68"
              y1="104.961"
              x2="185.995"
              y2="186.212"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>

        <div className="scrollInd">
          Scroll{' '}
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1L6 6L1 0.999999"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="SecuritiesOne-R">
        <div className="srbsvg">
          <svg
            width="980"
            height="1025"
            viewBox="0 0 980 1025"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.3">
              <circle
                opacity="0.1"
                cx="322.912"
                cy="555.582"
                r="217.589"
                transform="rotate(-15 322.912 555.582)"
                stroke="white"
                strokeWidth="2"
              />
              <path
                opacity="0.1"
                d="M604.704 480.078C642.575 621.416 558.801 766.666 417.592 804.503C276.383 842.34 131.208 758.437 93.336 617.098C55.4644 475.76 139.238 330.51 280.448 292.673C421.657 254.836 566.832 338.739 604.704 480.078Z"
                stroke="white"
                strokeWidth="2"
              />
              <path
                opacity="0.1"
                d="M681.389 459.53C726.371 627.406 626.849 799.932 459.102 844.88C291.356 889.827 118.904 790.176 73.9218 622.3C28.9397 454.425 128.462 281.898 296.208 236.951C463.955 192.003 636.406 291.654 681.389 459.53Z"
                stroke="white"
                strokeWidth="2"
              />
              <path
                opacity="0.1"
                d="M869.438 409.141C929.804 634.429 796.314 865.94 571.283 926.237C346.253 986.533 114.892 852.784 54.5261 627.496C-5.83964 402.208 127.65 170.697 352.681 110.401C577.711 50.1038 809.072 183.853 869.438 409.141Z"
                stroke="white"
                strokeWidth="2"
              />
            </g>
          </svg>
        </div>
        <img src={imgOne} alt="" />
        {/* hi */}
      </div>
    </div>
  )
}

export default SecuritiesOne
