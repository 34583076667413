import React from 'react'
import './Digitrust.scss'
import iOne from './1.png'
import iTwo from './2.png'
import iThree from './3.png'
import iFour from './4.png'

const HowDigitrust = () => {
  return (
    <div className="HowDigitrust-Main">
      <div className="HowDigitrust-T">
        <div className="Tcontainer">
          <h1>How DigiTrust works?</h1>
          <h2>
            Cognier Technology Group embodies unparalleled strategies that act
            as a blueprint for securing steady yields
          </h2>
        </div>
      </div>
      <div className="HowDigitrust-B">
        <div className="DBHolder one ">
          <DigiTrustBar
            img={iOne}
            h1="1"
            h2="Manage Funds"
            p="Purchase tokens at market price or import funds directly from external wallets or by swapping other cryptocurrencies."
          />
        </div>
        <div className="DBHolder two">
          <DigiTrustBar
            img={iTwo}
            h1="2"
            h2="Storing Trust Tokens"
            p="Providing quick and easy access to all of your tokenized real estate investments and cryptocurrencies and allowing you to monitor real-time prices of the same.            "
          />
        </div>
        <div className="DBHolder three">
          <DigiTrustBar
            img={iThree}
            h1="3"
            h2="Monitor your Portfolio"
            p="Get a 360 degree overview of your investment portfolio with real-time market data giving you a complete analysis of your account performance."
          />
        </div>
        <div className="DBHolder four">
          <DigiTrustBar
            img={iFour}
            h1="4"
            h2="Seamless Payout"
            p="Disbursing timely dividend payments to investors based on their percentage of shares purchased."
          />
        </div>
      </div>
    </div>
  )
}

export default HowDigitrust

function DigiTrustBar({ h1, h2, p, img }) {
  return (
    <div className="DigiTrustBar">
      <h1>{h1}</h1>
      <div className="Cwraper">
        <div className="circle">
          <div className="innerC">
            <img src={img} alt="" />
          </div>
          <h2>{h2}</h2>
          <p>{p}</p>
        </div>
      </div>
    </div>
  )
}
