

import { useEffect } from 'react';
import NewsList from './../components/SectionNewsListing/NewsList';


const NewsListingPage = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <div>
            {/* <SecOneNL/> */}
            <NewsList/>
        </div>
    )
}

export default NewsListingPage
