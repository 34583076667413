import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "react-slick";
import img1 from "../../assets/Testimonial/testicon.svg";
import TooltipCard from "./TooltipCard";
import TooltipImage from "./TooltipImage";

const CustomizedTooltip = withStyles({
  tooltip: {
    fontSize: "20px",
    padding: "10px",
    maxWidth: "none",
    background: "none",
    maxHeight: "70vh",
  },
})(Tooltip);

const items = [
  {
    title: <TooltipCard />,
    image: <img src={img1} />,
    Image1: <TooltipImage />,
  },
  { title: <TooltipCard />, image: <img src={img1} />, id : "First" },
  { title: <TooltipCard />, image: <img src={img1} /> },
  { title: <TooltipCard />, image: <img src={img1} /> },
  { title: <TooltipCard />, image: <img src={img1} /> },
  { title: <TooltipCard />, image: <img src={img1} /> },
  { title: <TooltipCard />, image: <img src={img1} /> },
  { title: <TooltipCard />, image: <img src={img1} /> },
  { title: <TooltipCard />, image: <img src={img1} /> },
];

const TestimonialCard = () => {

  // React.useEffect(() => {
  //   document.querySelector('#First').dispatchEvent(new MouseEvent('mouseover', { bubbles: true }));
  // }, []);

  React.useEffect(() => {
    const button = document.querySelector('#First');
    button.dispatchEvent(new MouseEvent('mouseover', { bubbles: true }));
  }, []);


  const settings = {
    positionFixed: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="TestimonialCardMain">
      <div className="text-center TestimonialHead">
        <h3 className="f-archivo intro-title">
         We have partnered with<span className="text-prim-blue">The
          Best</span>
        </h3>
        <p style={{ maxWidth: "641px", lineHeight: "30px" }} className="mx-auto intro-sub">
          Our data-backed strategies have enabled us to identify our
          top-performing core partners within commercial real estate with a
          distinct value-added approach and helped us in reaping strong
          investment returns
        </p>
      </div>
      {/* <TooltipCard/> */}
      <div className="TestimonialCard">
        <Slider {...settings}>
          {items.map((item, index) => (
            <div key={index}>
              <CustomizedTooltip
                open={item.open}
                arrow
                title={item.title}
                placement="top"
              
              >
                <div className="CardIcon" id={item.id ? item.id : ""}>
                  {item.image}
                  <span className="Details">
                    <span className="head">Avalon Communities</span>
                    <span className="sub">Avalon Communities</span>
                  </span>
                </div>
              </CustomizedTooltip>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialCard;
