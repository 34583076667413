import React, { useEffect } from 'react'

import 'aos/dist/aos.css'
import './SecFourAP.scss'

import bgI from './../../assets/ApproachPage/secFbg.png'
import face from './../../assets/ApproachPage/sFface.svg'
const SecFourAP = () => {
  return (
    <div className="SectionFourAP">
      <img className="bgI" src={bgI} alt="bgI" />
      <div className="SectionFourAPCon">
        <div className="SectionFourAPConL">
          <h1 data-aos="fade-up">AI and Us- the best of both worlds</h1>
          <p data-aos="fade-up">
            Cognier Technology Group has struck a balance between human expertise and AI to augment
            automation in the traditonally manual operations with improved
            accuracy, speed, and a deeper knowledge of market dynamics. Such a
            collaborative relationship will scale productivity like never before
          </p>
        </div>
        <div className="SectionFourAPConR">
       
          <img  data-aos="fade-left" data-aos-duration="2000"src={face} alt="" />
        </div>
      </div>
    </div>
  )
}

export default SecFourAP
