import React from "react";

const TEsecFeatures = () => {
    return (
        <div className="TEsecFeatures">
            <div className="TEsecFeatures-t">
                <h1>Features</h1>
                <h2>
                    Early access to deals and market making you stay ahead of the
                    competition.
                </h2>
            </div>
            <div className="TEsecFeatures-b">
                <div className="itemLine"></div>
                <div className="itemTe">
                    <div className="itemTe-l">
                        <h1>01</h1>
                        <div className="itcon">
                            <h2>Connectivity</h2>
                            <h3>
                                The Blubloc Network connects to every major exchange, OTC, liquidity provider, and trading venue right after you sign up on the platform. It analyses your interests and suggests people using our proprietary social algorithm.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="itemTe">
                    <div className="itemTe-l">
                        <h1>02</h1>
                        <div className="itcon">
                            <h2>Instant Settlement</h2>
                            <h3>
                                Connect and settle instantly with any counterparty or trading venue on the network. Transaction confirmations are instantly verified via our BFT consensus algorithm thus making it more hardened for any malicious attempts.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="itemTe">
                    <div className="itemTe-l">
                        <h1>03</h1>
                        <div className="itcon">
                            <h2>Robust APIS</h2>
                            <h3>
                                The Blublock network APIs makes it seamless for other organisations and actors to connect and communicate with our encrypted network thus making it more robust and secure.
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="itemTe">
                    <div className="itemTe-l">
                        <h1>04</h1>
                        <div className="itcon">
                            <h2>Integrations</h2>
                            <h3>
                                Our network integrations are secured using encrypted keys and certificates that are verified using assymetric key encryptions which makes third party integrations feasible and hassle free.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TEsecFeatures;
