import './SecTwoCl.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'

import { Link, useParams, useHistory } from 'react-router-dom'
import { Col, Container, Row } from 'react-bootstrap'
import { Location, Shop } from 'iconsax-react'
import LPSVGIcons from './../../assets/LPSVGIcons/LPSVGIcons'
// import fourT from './../../assets/LP/ListImg/04.svg'
// import { useSelector, useDispatch } from 'react-redux'
// import { std } from './../actions'
// import { Link, useHistory } from 'react-router-dom'

// import Aos from 'aos'
// import 'aos/dist/aos.css'
// import locationMarker from './../../assets/locationMarker.svg'
// import sImg from './../../assets/career/sI.png'
import { useSelector, useDispatch } from 'react-redux'
import { std } from './../actions/index'

const SecTwoCl = () => {
  const [num, setNum] = useState()
  const [jobsAvailable, setJobsAvailable] = useState()
  const [allJobs, setAllJobs] = useState([])
  const [allJobData, setAllJobData] = useState([])
  const [allJobsPTwo, setAllJobsPTwo] = useState()
  const [allJobsPTwoD, setAllJobsPTwoD] = useState()
  const [searchT, setSearchT] = useState('')
  const [searchFilter, setSearchFilter] = useState()
  const history = useHistory()
  const [dt, setdt] = useState('')
  const [jobId, setJobId] = useState('')
  // const jdR = useSelector((state) => state.jdR)
  const jdR = 11
  // const dispatch = useDispatch()
  // const history = useHistory()
  const dispatch = useDispatch()
  //new filtering data
  const [filteredJOBS, setFilteredJOBS] = useState([])
  const [nonFiltered, setNonFiltered] = useState([])
  const [Degree, setDegree] = useState('')
  const [jobType, setJobType] = useState('')
  const [cbState, setCbState] = useState(false)
  const [expfltr, setExpfltr] = useState(false)
  const [skillFltr, setSkillFltr] = useState(false)
  const [optionFilter, setOptionFilter] = useState()
  const [cbfltr, setCbfltr] = useState([1, 2])
  const [cbStateChanger, setCbStateChanger] = useState(false)
  const [searchMode, setSearchMode] = useState(1)

  useEffect(() => {
    //get all the selected Checkboxes for job type

    const getChecked = () => {
      let job = [],
        degree = [],
        exp = [],
        skill = []
      const checked = document.querySelectorAll('input[type=checkbox]:checked')
      const checkedValues = Array.from(checked).map((item) => {
        if (item.name === 'Job') {
          job.push(item.id)
        }
        if (item.name === 'Degree') {
          degree.push(item.id)
        }
        if (item.name === 'exp') {
          exp.push(item.id)
        }
        if (item.name === 'skill') {
          skill.push(item.id)
        }

        let datampr = {
          job: job,
          degree: degree,
          exp: exp,
          skill: skill,
        }
        return datampr
      })

      setOptionFilter(checkedValues[checkedValues.length - 1])
    }
    getChecked()
  }, [cbState])

  useEffect(() => {
    const config = {
      headers: {
        'X-Api-Key': 'NNgoPXyt.HqlZJ8l7ZnlJO3k1AxHyX3XncL69Ih90',
      },
    }
    const url = `https://api.cogniertechnology.com/api/jobs`
    axios
      .get(url, config)
      .then((res) => {
        console.log(res)
        setAllJobData(res.data)
        setFilteredJOBS(res.data)

        //filter all unique degrees from all jobs
        let uniqueDegrees = [
          ...new Set(
            res.data.map((item) => item.degree.map((item) => item.degree_name)),
          ),
        ]
        let combinedDegrees = uniqueDegrees.flat()
        //remove duplicate degrees
        let uniqueCombinedDegrees = [...new Set(combinedDegrees)]
        setDegree(uniqueCombinedDegrees)

        //filter all unique job type from all jobs
        let uniqueJobType = [
          ...new Set(
            res.data.map((item) => item.job_type.map((item) => item.type_name)),
          ),
        ]
        let combinedJobType = uniqueJobType.flat()
        //remove duplicate job type
        let uniqueCombinedJobType = [...new Set(combinedJobType)]
        setJobType(uniqueCombinedJobType)
        //filter all unique experience type from all jobs
        let uniqueExperience = [
          ...new Set(
            res.data.map((item) =>
              item.experience.map((item) => item.experience_time),
            ),
          ),
        ]
        let combinedExperience = uniqueExperience.flat()
        //remove duplicate experience
        let uniqueCombinedExperience = [...new Set(combinedExperience)]
        setExpfltr(uniqueCombinedExperience)

        //filter all unique skill type from all jobs
        let uniqueSkills = [
          ...new Set(
            res.data.map((item) => item.skill.map((item) => item.skill_name)),
          ),
        ]
        let combinedSkills = uniqueSkills.flat()
        //remove duplicate skills
        let uniqueCombinedSkills = [...new Set(combinedSkills)]
        setSkillFltr(uniqueCombinedSkills)
      })

      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    setNonFiltered(
      filteredJOBS.map((name, i) => {
        return (
          <div data-aos="fade-left" key={i} className="jobSContainer">
            <div className="top">
              <div className="tl">
                <h4>{name.job_name}</h4>
                <div className="jobdataSet">
                  <div className="bottom">
                    <div
                      dangerouslySetInnerHTML={{ __html: name.job_detail }}
                    ></div>
                  </div>
                  <div className="dsCon">
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_457_1593)">
                        <path
                          d="M14.7191 2.85383C13.212 1.3467 11.1679 0.5 9.03648 0.5C6.90507 0.5 4.86096 1.3467 3.35383 2.85383C1.8467 4.36096 1 6.40507 1 8.53647C1 10.6679 1.8467 12.712 3.35383 14.2191L8.34509 19.2198C8.43313 19.3086 8.53789 19.3791 8.6533 19.4272C8.76871 19.4752 8.8925 19.5 9.01753 19.5C9.14256 19.5 9.26636 19.4752 9.38177 19.4272C9.49718 19.3791 9.60193 19.3086 9.68998 19.2198L14.7191 14.1718C16.22 12.6709 17.0631 10.6353 17.0631 8.5128C17.0631 6.39028 16.22 4.35469 14.7191 2.85383ZM13.3648 12.8174L9.03648 17.1646L4.70819 12.8174C3.85309 11.9615 3.27093 10.8713 3.03531 9.68464C2.79969 8.49796 2.92118 7.26806 3.38442 6.15041C3.84767 5.03276 4.63187 4.07754 5.6379 3.4055C6.64393 2.73346 7.82663 2.37476 9.03648 2.37476C10.2463 2.37476 11.429 2.73346 12.435 3.4055C13.4411 4.07754 14.2253 5.03276 14.6885 6.15041C15.1518 7.26806 15.2733 8.49796 15.0376 9.68464C14.802 10.8713 14.2199 11.9615 13.3648 12.8174ZM6.19515 5.62885C5.43057 6.39579 5.00122 7.43458 5.00122 8.51753C5.00122 9.60049 5.43057 10.6393 6.19515 11.4062C6.76319 11.9752 7.48662 12.3638 8.27467 12.5232C9.06272 12.6827 9.88031 12.6058 10.6248 12.3023C11.3694 11.9988 12.0077 11.4822 12.4598 10.8173C12.9118 10.1524 13.1575 9.36886 13.1659 8.56489C13.1701 8.02807 13.0667 7.49585 12.8618 6.99969C12.6568 6.50352 12.3544 6.0535 11.9725 5.67621C11.5971 5.29223 11.1496 4.98628 10.6555 4.77595C10.1614 4.56563 9.63064 4.45508 9.09368 4.45068C8.55673 4.44628 8.02421 4.54811 7.52676 4.75031C7.02931 4.9525 6.57676 5.25108 6.19515 5.62885ZM10.6371 10.0613C10.2782 10.4257 9.80383 10.6541 9.29516 10.7075C8.78648 10.7609 8.27506 10.6359 7.84834 10.3539C7.42161 10.0719 7.10608 9.6505 6.95569 9.16164C6.8053 8.67279 6.82938 8.14687 7.02383 7.67381C7.21828 7.20074 7.57102 6.80991 8.02173 6.56813C8.47245 6.32636 8.99315 6.24865 9.49482 6.34829C9.99648 6.44794 10.448 6.71875 10.7721 7.11442C11.0962 7.5101 11.2728 8.00607 11.2717 8.51753C11.2579 9.10215 11.0126 9.6574 10.5897 10.0613H10.6371Z"
                          fill="#9E2349"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_457_1593">
                          <rect
                            width="19"
                            height="19"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <h5>Maryland, USA</h5>
                  </div>
                  <div className="dsCon">
                    {name.job_type[0]?.type_name && (
                      <>
                        {' '}
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_457_1597)">
                            <path
                              d="M18.4013 12.8567C18.3363 12.8403 18.273 12.8481 18.2089 12.8481V3.27778C18.2089 3.0715 18.1255 2.87367 17.977 2.72781C17.8285 2.58194 17.6272 2.5 17.4172 2.5H1.58389C1.37392 2.5 1.17256 2.58194 1.0241 2.72781C0.875629 2.87367 0.792221 3.0715 0.792221 3.27778V12.8481C0.728096 12.8481 0.664763 12.8411 0.599846 12.8567C0.396152 12.9068 0.221044 13.0343 0.112983 13.2112C0.00492115 13.3881 -0.027258 13.5999 0.0235131 13.8001L0.623596 16.1576C0.819138 16.9229 1.57201 17.5 2.37555 17.5H16.6256C17.4291 17.5 18.1828 16.9221 18.3775 16.1568L18.9768 13.8001C19.0278 13.6 18.9958 13.3882 18.8879 13.2113C18.78 13.0344 18.6049 12.9068 18.4013 12.8567ZM2.37555 4.05556H16.6256V12.8333H11.8756C11.6658 12.8333 11.4639 12.9158 11.3158 13.0612L10.7561 13.6111H8.24497L7.68526 13.0612C7.53673 12.9155 7.33547 12.8335 7.12555 12.8333H2.37555V4.05556ZM16.6256 15.9444H2.37555C2.3043 15.9444 2.17685 15.8472 2.16022 15.7796L1.80635 14.3889H6.7978L7.35751 14.9388C7.43093 15.0111 7.51817 15.0685 7.61422 15.1076C7.71028 15.1467 7.81325 15.1668 7.91722 15.1667H11.0839C11.2937 15.1667 11.4956 15.085 11.6436 14.9388L12.2033 14.3889H17.1948L16.8409 15.7796C16.8235 15.8472 16.6968 15.9444 16.6256 15.9444Z"
                              fill="#9E2349"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_457_1597">
                              <rect
                                width="19"
                                height="19"
                                fill="white"
                                transform="translate(0 0.5)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <h5>{name.job_type[0]?.type_name}</h5>
                      </>
                    )}
                  </div>{' '}
                  <div className="dsCon">
                    {name.degree[0]?.degree_name && (
                      <>
                        {name.experience[0]?.experience_time && (
                          <>
                            <svg
                              width="19"
                              height="20"
                              viewBox="0 0 19 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_457_1601)">
                                <path
                                  d="M18.05 6.2C17.8581 6.2 13.4425 6.22755 9.5 8.1076C5.5575 6.22755 1.1419 6.2 0.95 6.2C0.698044 6.2 0.456408 6.30009 0.278249 6.47825C0.100089 6.65641 0 6.89805 0 7.15V16.5389C1.241e-06 16.6663 0.0256553 16.7925 0.0754341 16.9098C0.125213 17.0272 0.198099 17.1333 0.28975 17.2219C0.475 17.4005 0.74575 17.5117 0.9823 17.4879L1.10295 17.487C1.7518 17.487 5.18415 17.5801 9.09815 19.4107C9.11335 19.4183 9.13045 19.4155 9.14565 19.4212C9.2587 19.4677 9.37745 19.5 9.5 19.5C9.62255 19.5 9.7413 19.4677 9.8553 19.4212C9.8705 19.4155 9.8876 19.4183 9.9028 19.4107C13.8168 17.5791 17.2491 17.487 17.898 17.487L18.0187 17.4879C18.2447 17.5117 18.525 17.4005 18.7112 17.2219C18.8955 17.0424 19 16.7954 19 16.5389V7.15C19 6.89805 18.8999 6.65641 18.7218 6.47825C18.5436 6.30009 18.302 6.2 18.05 6.2ZM1.9 8.14655C3.31075 8.252 6.06195 8.60255 8.55 9.75395V17.1089C5.7 15.9926 3.2395 15.6896 1.9 15.6117V8.14655ZM17.1 15.6117C15.7605 15.6896 13.3 15.9926 10.45 17.1089V9.75395C12.9381 8.60255 15.6892 8.252 17.1 8.14655V15.6117Z"
                                  fill="#9E2349"
                                />
                                <path
                                  d="M9.5 6.2C11.074 6.2 12.35 4.92401 12.35 3.35C12.35 1.77599 11.074 0.5 9.5 0.5C7.92599 0.5 6.65 1.77599 6.65 3.35C6.65 4.92401 7.92599 6.2 9.5 6.2Z"
                                  fill="#9E2349"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_457_1601">
                                  <rect
                                    width="19"
                                    height="19"
                                    fill="white"
                                    transform="translate(0 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <h5>{name.degree[0]?.degree_name}</h5>
                          </>
                        )}
                      </>
                    )}
                    {/* <h5>{name.job_type[0]?.type_name}</h5> */}
                  </div>{' '}
                  <div className="dsCon">
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_457_1605)">
                        <path
                          d="M16.15 4.33333H13.3V3.38889C13.3 2.88792 13.0998 2.40748 12.7435 2.05324C12.3872 1.69901 11.9039 1.5 11.4 1.5H7.6C7.09609 1.5 6.61282 1.69901 6.2565 2.05324C5.90018 2.40748 5.7 2.88792 5.7 3.38889V4.33333H2.85C2.09413 4.33333 1.36922 4.63184 0.834746 5.1632C0.300267 5.69455 0 6.41522 0 7.16667V15.6667C0 16.4181 0.300267 17.1388 0.834746 17.6701C1.36922 18.2015 2.09413 18.5 2.85 18.5H16.15C16.9059 18.5 17.6308 18.2015 18.1653 17.6701C18.6997 17.1388 19 16.4181 19 15.6667V7.16667C19 6.41522 18.6997 5.69455 18.1653 5.1632C17.6308 4.63184 16.9059 4.33333 16.15 4.33333ZM7.6 3.38889H11.4V4.33333H7.6V3.38889ZM17.1 15.6667C17.1 15.9171 16.9999 16.1574 16.8218 16.3345C16.6436 16.5116 16.402 16.6111 16.15 16.6111H2.85C2.59804 16.6111 2.35641 16.5116 2.17825 16.3345C2.00009 16.1574 1.9 15.9171 1.9 15.6667V10.3683L6.346 11.8889C6.44687 11.9025 6.54913 11.9025 6.65 11.8889H12.35C12.453 11.887 12.5553 11.8711 12.654 11.8417L17.1 10.3683V15.6667ZM17.1 8.37556L12.198 10H6.802L1.9 8.37556V7.16667C1.9 6.91618 2.00009 6.67596 2.17825 6.49884C2.35641 6.32173 2.59804 6.22222 2.85 6.22222H16.15C16.402 6.22222 16.6436 6.32173 16.8218 6.49884C16.9999 6.67596 17.1 6.91618 17.1 7.16667V8.37556Z"
                          fill="#9E2349"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_457_1605">
                          <rect
                            width="19"
                            height="19"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <h5>
                      minimum {name.experience[0]?.experience_time} year exp.
                    </h5>
                  </div>
                </div>
              </div>
              <div className="tR">
                <div
                  onClick={() => {
                    dispatch(std(name.id))
                    history.push('/JobDetails')
                    // history.push(`/career/detail/${name.id}`);
                    // console.log(name.id);
                  }}
                  className="applyBtn"
                >
                  Apply
                </div>
              </div>
            </div>
          </div>
        )
      }),
    )
    // setFilteredJOBS(allJobData);
  }, [allJobData, filteredJOBS])

  useEffect(() => {
    // setFilteredJOBS(allJobData);
    // let result = filteredJOBS.filter((user) => {
    //   return user.job_name.toLowerCase().indexOf(searchT.toLowerCase()) >= 0;
    // });
    // setFilteredJOBS(result);

    let result = allJobData.filter((user) => {
      return user.job_name.toLowerCase().indexOf(searchT.toLowerCase()) >= 0
    })

    if (searchMode === 2) {
      //check if the job type has matching value from the array
      let fdata = []
      let mapr = allJobData.map((user) => {
        if (optionFilter != '') {
          let imap = user.job_type.map((item) => {
            if (optionFilter?.job?.includes(item.type_name)) {
              fdata.push(user)
              return user
            }
          })
          let imapp = user.degree.map((item) => {
            if (optionFilter?.degree?.includes(item.degree_name)) {
              fdata.push(user)
              return user
            }
          })
          let imappp = user.experience.map((item) => {
            if (optionFilter?.exp?.includes(item.experience_time)) {
              fdata.push(user)
              return user
            }
          })
          let imapppp = user.skill.map((item) => {
            if (optionFilter?.skill?.includes(item.skill_name)) {
              fdata.push(user)
              return user
            }
          })
        }
      })
      // setCbfltr(fdata);
      console.log(result)
      console.log('FD')
      console.log(optionFilter)
      setCbfltr(fdata)
      setSearchFilter(
        fdata.map((name, i) => {
          return (
            <div data-aos="fade-left" key={i} className="jobSContainer">
              <div className="top">
                <div className="tl">
                  <h4>{name.job_name}</h4>
                  <div className="jobdataSet">
                    <div className="bottom">
                      <div
                        dangerouslySetInnerHTML={{ __html: name.job_detail }}
                      ></div>
                    </div>
                    <div className="dsCon">
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_457_1593)">
                          <path
                            d="M14.7191 2.85383C13.212 1.3467 11.1679 0.5 9.03648 0.5C6.90507 0.5 4.86096 1.3467 3.35383 2.85383C1.8467 4.36096 1 6.40507 1 8.53647C1 10.6679 1.8467 12.712 3.35383 14.2191L8.34509 19.2198C8.43313 19.3086 8.53789 19.3791 8.6533 19.4272C8.76871 19.4752 8.8925 19.5 9.01753 19.5C9.14256 19.5 9.26636 19.4752 9.38177 19.4272C9.49718 19.3791 9.60193 19.3086 9.68998 19.2198L14.7191 14.1718C16.22 12.6709 17.0631 10.6353 17.0631 8.5128C17.0631 6.39028 16.22 4.35469 14.7191 2.85383ZM13.3648 12.8174L9.03648 17.1646L4.70819 12.8174C3.85309 11.9615 3.27093 10.8713 3.03531 9.68464C2.79969 8.49796 2.92118 7.26806 3.38442 6.15041C3.84767 5.03276 4.63187 4.07754 5.6379 3.4055C6.64393 2.73346 7.82663 2.37476 9.03648 2.37476C10.2463 2.37476 11.429 2.73346 12.435 3.4055C13.4411 4.07754 14.2253 5.03276 14.6885 6.15041C15.1518 7.26806 15.2733 8.49796 15.0376 9.68464C14.802 10.8713 14.2199 11.9615 13.3648 12.8174ZM6.19515 5.62885C5.43057 6.39579 5.00122 7.43458 5.00122 8.51753C5.00122 9.60049 5.43057 10.6393 6.19515 11.4062C6.76319 11.9752 7.48662 12.3638 8.27467 12.5232C9.06272 12.6827 9.88031 12.6058 10.6248 12.3023C11.3694 11.9988 12.0077 11.4822 12.4598 10.8173C12.9118 10.1524 13.1575 9.36886 13.1659 8.56489C13.1701 8.02807 13.0667 7.49585 12.8618 6.99969C12.6568 6.50352 12.3544 6.0535 11.9725 5.67621C11.5971 5.29223 11.1496 4.98628 10.6555 4.77595C10.1614 4.56563 9.63064 4.45508 9.09368 4.45068C8.55673 4.44628 8.02421 4.54811 7.52676 4.75031C7.02931 4.9525 6.57676 5.25108 6.19515 5.62885ZM10.6371 10.0613C10.2782 10.4257 9.80383 10.6541 9.29516 10.7075C8.78648 10.7609 8.27506 10.6359 7.84834 10.3539C7.42161 10.0719 7.10608 9.6505 6.95569 9.16164C6.8053 8.67279 6.82938 8.14687 7.02383 7.67381C7.21828 7.20074 7.57102 6.80991 8.02173 6.56813C8.47245 6.32636 8.99315 6.24865 9.49482 6.34829C9.99648 6.44794 10.448 6.71875 10.7721 7.11442C11.0962 7.5101 11.2728 8.00607 11.2717 8.51753C11.2579 9.10215 11.0126 9.6574 10.5897 10.0613H10.6371Z"
                            fill="#9E2349"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_457_1593">
                            <rect
                              width="19"
                              height="19"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <h5>Maryland, USA</h5>
                    </div>
                    <div className="dsCon">
                      {name.job_type[0]?.type_name && (
                        <>
                          {' '}
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_457_1597)">
                              <path
                                d="M18.4013 12.8567C18.3363 12.8403 18.273 12.8481 18.2089 12.8481V3.27778C18.2089 3.0715 18.1255 2.87367 17.977 2.72781C17.8285 2.58194 17.6272 2.5 17.4172 2.5H1.58389C1.37392 2.5 1.17256 2.58194 1.0241 2.72781C0.875629 2.87367 0.792221 3.0715 0.792221 3.27778V12.8481C0.728096 12.8481 0.664763 12.8411 0.599846 12.8567C0.396152 12.9068 0.221044 13.0343 0.112983 13.2112C0.00492115 13.3881 -0.027258 13.5999 0.0235131 13.8001L0.623596 16.1576C0.819138 16.9229 1.57201 17.5 2.37555 17.5H16.6256C17.4291 17.5 18.1828 16.9221 18.3775 16.1568L18.9768 13.8001C19.0278 13.6 18.9958 13.3882 18.8879 13.2113C18.78 13.0344 18.6049 12.9068 18.4013 12.8567ZM2.37555 4.05556H16.6256V12.8333H11.8756C11.6658 12.8333 11.4639 12.9158 11.3158 13.0612L10.7561 13.6111H8.24497L7.68526 13.0612C7.53673 12.9155 7.33547 12.8335 7.12555 12.8333H2.37555V4.05556ZM16.6256 15.9444H2.37555C2.3043 15.9444 2.17685 15.8472 2.16022 15.7796L1.80635 14.3889H6.7978L7.35751 14.9388C7.43093 15.0111 7.51817 15.0685 7.61422 15.1076C7.71028 15.1467 7.81325 15.1668 7.91722 15.1667H11.0839C11.2937 15.1667 11.4956 15.085 11.6436 14.9388L12.2033 14.3889H17.1948L16.8409 15.7796C16.8235 15.8472 16.6968 15.9444 16.6256 15.9444Z"
                                fill="#9E2349"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_457_1597">
                                <rect
                                  width="19"
                                  height="19"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h5>{name.job_type[0]?.type_name}</h5>
                        </>
                      )}
                    </div>{' '}
                    <div className="dsCon">
                      {name.degree[0]?.degree_name && (
                        <>
                          {name.experience[0]?.experience_time && (
                            <>
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_457_1601)">
                                  <path
                                    d="M18.05 6.2C17.8581 6.2 13.4425 6.22755 9.5 8.1076C5.5575 6.22755 1.1419 6.2 0.95 6.2C0.698044 6.2 0.456408 6.30009 0.278249 6.47825C0.100089 6.65641 0 6.89805 0 7.15V16.5389C1.241e-06 16.6663 0.0256553 16.7925 0.0754341 16.9098C0.125213 17.0272 0.198099 17.1333 0.28975 17.2219C0.475 17.4005 0.74575 17.5117 0.9823 17.4879L1.10295 17.487C1.7518 17.487 5.18415 17.5801 9.09815 19.4107C9.11335 19.4183 9.13045 19.4155 9.14565 19.4212C9.2587 19.4677 9.37745 19.5 9.5 19.5C9.62255 19.5 9.7413 19.4677 9.8553 19.4212C9.8705 19.4155 9.8876 19.4183 9.9028 19.4107C13.8168 17.5791 17.2491 17.487 17.898 17.487L18.0187 17.4879C18.2447 17.5117 18.525 17.4005 18.7112 17.2219C18.8955 17.0424 19 16.7954 19 16.5389V7.15C19 6.89805 18.8999 6.65641 18.7218 6.47825C18.5436 6.30009 18.302 6.2 18.05 6.2ZM1.9 8.14655C3.31075 8.252 6.06195 8.60255 8.55 9.75395V17.1089C5.7 15.9926 3.2395 15.6896 1.9 15.6117V8.14655ZM17.1 15.6117C15.7605 15.6896 13.3 15.9926 10.45 17.1089V9.75395C12.9381 8.60255 15.6892 8.252 17.1 8.14655V15.6117Z"
                                    fill="#9E2349"
                                  />
                                  <path
                                    d="M9.5 6.2C11.074 6.2 12.35 4.92401 12.35 3.35C12.35 1.77599 11.074 0.5 9.5 0.5C7.92599 0.5 6.65 1.77599 6.65 3.35C6.65 4.92401 7.92599 6.2 9.5 6.2Z"
                                    fill="#9E2349"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_457_1601">
                                    <rect
                                      width="19"
                                      height="19"
                                      fill="white"
                                      transform="translate(0 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <h5>{name.degree[0]?.degree_name}</h5>
                            </>
                          )}
                        </>
                      )}
                      {/* <h5>{name.job_type[0]?.type_name}</h5> */}
                    </div>{' '}
                    <div className="dsCon">
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_457_1605)">
                          <path
                            d="M16.15 4.33333H13.3V3.38889C13.3 2.88792 13.0998 2.40748 12.7435 2.05324C12.3872 1.69901 11.9039 1.5 11.4 1.5H7.6C7.09609 1.5 6.61282 1.69901 6.2565 2.05324C5.90018 2.40748 5.7 2.88792 5.7 3.38889V4.33333H2.85C2.09413 4.33333 1.36922 4.63184 0.834746 5.1632C0.300267 5.69455 0 6.41522 0 7.16667V15.6667C0 16.4181 0.300267 17.1388 0.834746 17.6701C1.36922 18.2015 2.09413 18.5 2.85 18.5H16.15C16.9059 18.5 17.6308 18.2015 18.1653 17.6701C18.6997 17.1388 19 16.4181 19 15.6667V7.16667C19 6.41522 18.6997 5.69455 18.1653 5.1632C17.6308 4.63184 16.9059 4.33333 16.15 4.33333ZM7.6 3.38889H11.4V4.33333H7.6V3.38889ZM17.1 15.6667C17.1 15.9171 16.9999 16.1574 16.8218 16.3345C16.6436 16.5116 16.402 16.6111 16.15 16.6111H2.85C2.59804 16.6111 2.35641 16.5116 2.17825 16.3345C2.00009 16.1574 1.9 15.9171 1.9 15.6667V10.3683L6.346 11.8889C6.44687 11.9025 6.54913 11.9025 6.65 11.8889H12.35C12.453 11.887 12.5553 11.8711 12.654 11.8417L17.1 10.3683V15.6667ZM17.1 8.37556L12.198 10H6.802L1.9 8.37556V7.16667C1.9 6.91618 2.00009 6.67596 2.17825 6.49884C2.35641 6.32173 2.59804 6.22222 2.85 6.22222H16.15C16.402 6.22222 16.6436 6.32173 16.8218 6.49884C16.9999 6.67596 17.1 6.91618 17.1 7.16667V8.37556Z"
                            fill="#9E2349"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_457_1605">
                            <rect
                              width="19"
                              height="19"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <h5>
                        minimum {name.experience[0]?.experience_time} year exp.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="tR">
                  <div
                    onClick={() => {
                      dispatch(std(name.id))
                      history.push('/JobDetails')
                      // history.push(`/career/detail/${name.id}`);
                      // console.log(name.id);
                    }}
                    className="applyBtn"
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>
          )
        }),
      )
    } else {
      setSearchFilter(
        result.map((name, i) => {
          return (
            <div data-aos="fade-left" key={i} className="jobSContainer">
              <div className="top">
                <div className="tl">
                  <h4>{name.job_name}</h4>
                  <div className="jobdataSet">
                    <div className="bottom">
                      <div
                        dangerouslySetInnerHTML={{ __html: name.job_detail }}
                      ></div>
                    </div>
                    <div className="dsCon">
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_457_1593)">
                          <path
                            d="M14.7191 2.85383C13.212 1.3467 11.1679 0.5 9.03648 0.5C6.90507 0.5 4.86096 1.3467 3.35383 2.85383C1.8467 4.36096 1 6.40507 1 8.53647C1 10.6679 1.8467 12.712 3.35383 14.2191L8.34509 19.2198C8.43313 19.3086 8.53789 19.3791 8.6533 19.4272C8.76871 19.4752 8.8925 19.5 9.01753 19.5C9.14256 19.5 9.26636 19.4752 9.38177 19.4272C9.49718 19.3791 9.60193 19.3086 9.68998 19.2198L14.7191 14.1718C16.22 12.6709 17.0631 10.6353 17.0631 8.5128C17.0631 6.39028 16.22 4.35469 14.7191 2.85383ZM13.3648 12.8174L9.03648 17.1646L4.70819 12.8174C3.85309 11.9615 3.27093 10.8713 3.03531 9.68464C2.79969 8.49796 2.92118 7.26806 3.38442 6.15041C3.84767 5.03276 4.63187 4.07754 5.6379 3.4055C6.64393 2.73346 7.82663 2.37476 9.03648 2.37476C10.2463 2.37476 11.429 2.73346 12.435 3.4055C13.4411 4.07754 14.2253 5.03276 14.6885 6.15041C15.1518 7.26806 15.2733 8.49796 15.0376 9.68464C14.802 10.8713 14.2199 11.9615 13.3648 12.8174ZM6.19515 5.62885C5.43057 6.39579 5.00122 7.43458 5.00122 8.51753C5.00122 9.60049 5.43057 10.6393 6.19515 11.4062C6.76319 11.9752 7.48662 12.3638 8.27467 12.5232C9.06272 12.6827 9.88031 12.6058 10.6248 12.3023C11.3694 11.9988 12.0077 11.4822 12.4598 10.8173C12.9118 10.1524 13.1575 9.36886 13.1659 8.56489C13.1701 8.02807 13.0667 7.49585 12.8618 6.99969C12.6568 6.50352 12.3544 6.0535 11.9725 5.67621C11.5971 5.29223 11.1496 4.98628 10.6555 4.77595C10.1614 4.56563 9.63064 4.45508 9.09368 4.45068C8.55673 4.44628 8.02421 4.54811 7.52676 4.75031C7.02931 4.9525 6.57676 5.25108 6.19515 5.62885ZM10.6371 10.0613C10.2782 10.4257 9.80383 10.6541 9.29516 10.7075C8.78648 10.7609 8.27506 10.6359 7.84834 10.3539C7.42161 10.0719 7.10608 9.6505 6.95569 9.16164C6.8053 8.67279 6.82938 8.14687 7.02383 7.67381C7.21828 7.20074 7.57102 6.80991 8.02173 6.56813C8.47245 6.32636 8.99315 6.24865 9.49482 6.34829C9.99648 6.44794 10.448 6.71875 10.7721 7.11442C11.0962 7.5101 11.2728 8.00607 11.2717 8.51753C11.2579 9.10215 11.0126 9.6574 10.5897 10.0613H10.6371Z"
                            fill="#9E2349"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_457_1593">
                            <rect
                              width="19"
                              height="19"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <h5>Maryland, USA</h5>
                    </div>
                    <div className="dsCon">
                      {name.job_type[0]?.type_name && (
                        <>
                          {' '}
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_457_1597)">
                              <path
                                d="M18.4013 12.8567C18.3363 12.8403 18.273 12.8481 18.2089 12.8481V3.27778C18.2089 3.0715 18.1255 2.87367 17.977 2.72781C17.8285 2.58194 17.6272 2.5 17.4172 2.5H1.58389C1.37392 2.5 1.17256 2.58194 1.0241 2.72781C0.875629 2.87367 0.792221 3.0715 0.792221 3.27778V12.8481C0.728096 12.8481 0.664763 12.8411 0.599846 12.8567C0.396152 12.9068 0.221044 13.0343 0.112983 13.2112C0.00492115 13.3881 -0.027258 13.5999 0.0235131 13.8001L0.623596 16.1576C0.819138 16.9229 1.57201 17.5 2.37555 17.5H16.6256C17.4291 17.5 18.1828 16.9221 18.3775 16.1568L18.9768 13.8001C19.0278 13.6 18.9958 13.3882 18.8879 13.2113C18.78 13.0344 18.6049 12.9068 18.4013 12.8567ZM2.37555 4.05556H16.6256V12.8333H11.8756C11.6658 12.8333 11.4639 12.9158 11.3158 13.0612L10.7561 13.6111H8.24497L7.68526 13.0612C7.53673 12.9155 7.33547 12.8335 7.12555 12.8333H2.37555V4.05556ZM16.6256 15.9444H2.37555C2.3043 15.9444 2.17685 15.8472 2.16022 15.7796L1.80635 14.3889H6.7978L7.35751 14.9388C7.43093 15.0111 7.51817 15.0685 7.61422 15.1076C7.71028 15.1467 7.81325 15.1668 7.91722 15.1667H11.0839C11.2937 15.1667 11.4956 15.085 11.6436 14.9388L12.2033 14.3889H17.1948L16.8409 15.7796C16.8235 15.8472 16.6968 15.9444 16.6256 15.9444Z"
                                fill="#9E2349"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_457_1597">
                                <rect
                                  width="19"
                                  height="19"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                          <h5>{name.job_type[0]?.type_name}</h5>
                        </>
                      )}
                    </div>{' '}
                    <div className="dsCon">
                      {name.degree[0]?.degree_name && (
                        <>
                          {name.experience[0]?.experience_time && (
                            <>
                              <svg
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clip-path="url(#clip0_457_1601)">
                                  <path
                                    d="M18.05 6.2C17.8581 6.2 13.4425 6.22755 9.5 8.1076C5.5575 6.22755 1.1419 6.2 0.95 6.2C0.698044 6.2 0.456408 6.30009 0.278249 6.47825C0.100089 6.65641 0 6.89805 0 7.15V16.5389C1.241e-06 16.6663 0.0256553 16.7925 0.0754341 16.9098C0.125213 17.0272 0.198099 17.1333 0.28975 17.2219C0.475 17.4005 0.74575 17.5117 0.9823 17.4879L1.10295 17.487C1.7518 17.487 5.18415 17.5801 9.09815 19.4107C9.11335 19.4183 9.13045 19.4155 9.14565 19.4212C9.2587 19.4677 9.37745 19.5 9.5 19.5C9.62255 19.5 9.7413 19.4677 9.8553 19.4212C9.8705 19.4155 9.8876 19.4183 9.9028 19.4107C13.8168 17.5791 17.2491 17.487 17.898 17.487L18.0187 17.4879C18.2447 17.5117 18.525 17.4005 18.7112 17.2219C18.8955 17.0424 19 16.7954 19 16.5389V7.15C19 6.89805 18.8999 6.65641 18.7218 6.47825C18.5436 6.30009 18.302 6.2 18.05 6.2ZM1.9 8.14655C3.31075 8.252 6.06195 8.60255 8.55 9.75395V17.1089C5.7 15.9926 3.2395 15.6896 1.9 15.6117V8.14655ZM17.1 15.6117C15.7605 15.6896 13.3 15.9926 10.45 17.1089V9.75395C12.9381 8.60255 15.6892 8.252 17.1 8.14655V15.6117Z"
                                    fill="#9E2349"
                                  />
                                  <path
                                    d="M9.5 6.2C11.074 6.2 12.35 4.92401 12.35 3.35C12.35 1.77599 11.074 0.5 9.5 0.5C7.92599 0.5 6.65 1.77599 6.65 3.35C6.65 4.92401 7.92599 6.2 9.5 6.2Z"
                                    fill="#9E2349"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_457_1601">
                                    <rect
                                      width="19"
                                      height="19"
                                      fill="white"
                                      transform="translate(0 0.5)"
                                    />
                                  </clipPath>
                                </defs>
                              </svg>
                              <h5>{name.degree[0]?.degree_name}</h5>
                            </>
                          )}
                        </>
                      )}
                      {/* <h5>{name.job_type[0]?.type_name}</h5> */}
                    </div>{' '}
                    <div className="dsCon">
                      <svg
                        width="19"
                        height="20"
                        viewBox="0 0 19 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_457_1605)">
                          <path
                            d="M16.15 4.33333H13.3V3.38889C13.3 2.88792 13.0998 2.40748 12.7435 2.05324C12.3872 1.69901 11.9039 1.5 11.4 1.5H7.6C7.09609 1.5 6.61282 1.69901 6.2565 2.05324C5.90018 2.40748 5.7 2.88792 5.7 3.38889V4.33333H2.85C2.09413 4.33333 1.36922 4.63184 0.834746 5.1632C0.300267 5.69455 0 6.41522 0 7.16667V15.6667C0 16.4181 0.300267 17.1388 0.834746 17.6701C1.36922 18.2015 2.09413 18.5 2.85 18.5H16.15C16.9059 18.5 17.6308 18.2015 18.1653 17.6701C18.6997 17.1388 19 16.4181 19 15.6667V7.16667C19 6.41522 18.6997 5.69455 18.1653 5.1632C17.6308 4.63184 16.9059 4.33333 16.15 4.33333ZM7.6 3.38889H11.4V4.33333H7.6V3.38889ZM17.1 15.6667C17.1 15.9171 16.9999 16.1574 16.8218 16.3345C16.6436 16.5116 16.402 16.6111 16.15 16.6111H2.85C2.59804 16.6111 2.35641 16.5116 2.17825 16.3345C2.00009 16.1574 1.9 15.9171 1.9 15.6667V10.3683L6.346 11.8889C6.44687 11.9025 6.54913 11.9025 6.65 11.8889H12.35C12.453 11.887 12.5553 11.8711 12.654 11.8417L17.1 10.3683V15.6667ZM17.1 8.37556L12.198 10H6.802L1.9 8.37556V7.16667C1.9 6.91618 2.00009 6.67596 2.17825 6.49884C2.35641 6.32173 2.59804 6.22222 2.85 6.22222H16.15C16.402 6.22222 16.6436 6.32173 16.8218 6.49884C16.9999 6.67596 17.1 6.91618 17.1 7.16667V8.37556Z"
                            fill="#9E2349"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_457_1605">
                            <rect
                              width="19"
                              height="19"
                              fill="white"
                              transform="translate(0 0.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>

                      <h5>
                        minimum {name.experience[0]?.experience_time} year exp.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="tR">
                  <div
                    onClick={() => {
                      dispatch(std(name.id))
                      history.push('/JobDetails')
                      // history.push(`/career/detail/${name.id}`);
                      // console.log(name.id);
                    }}
                    className="applyBtn"
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>
          )
        }),
      )
    }

    setCbStateChanger(!cbStateChanger)
  }, [searchT, cbState])

  //set timeout for 3sec
  useEffect(() => {
    setTimeout(() => {
      setCbState(!cbState)
    }, 400)
  }, [cbStateChanger])

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       "X-Api-Key": "NNgoPXyt.HqlZJ8l7ZnlJO3k1AxHyX3XncL69Ih90",
  //     },
  //   };
  //   const url = `https://api.cogniertechnology.com/api/jobs`;
  //   async function handleSearchD() {
  //     console.log("search started for " + searchT);
  //     console.log("search started for " + searchT);
  //     const res = await axios.get(url, config);
  //     console.log(res);
  //     const filteredItems = res.data.filter((namee) => {
  //       return namee.job_name == "Manag";
  //     });
  //     let result = allJobData.filter((user) => {
  //       return user.job_name.toLowerCase().indexOf(searchT.toLowerCase()) >= 0;
  //     });

  //     console.log(result);

  //   }
  //   handleSearchD();
  // }, [searchT]);
  return (
    <div className="sectionTwoCL new">
      <div className="sectionTwoCL-t"></div>
      <div className="sectionTwoCL-b">
        <div className="sectionTwoCL-b-l"></div>
        <div className="sectionTwoCL-b-r"></div>
      </div>

      <Container>
        <Row className="g-5">
          <Col md={9} className="order-last order-md-first">
            <Row>
              <Col lg={8}>
                <div data-aos="fade-up" className="searchBOX">
                  {/* <span>Search</span> */}
                  <div className="inputHolder">
                    <input
                      placeholder="Search for Job title or Skill"
                      value={searchT}
                      onChange={(e) => {
                        setSearchMode(1)
                        setSearchT(e.target.value)
                      }}
                    />
                    {/* <img src={sImg} alt="" /> */}
                    <div className="SearchBtn">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.2635 20.5271C12.5407 20.5266 14.7523 19.7643 16.5461 18.3615L22.1859 24.0013L24 22.1872L18.3602 16.5474C19.7637 14.7534 20.5265 12.5413 20.5271 10.2635C20.5271 4.60448 15.9226 0 10.2635 0C4.60448 0 0 4.60448 0 10.2635C0 15.9226 4.60448 20.5271 10.2635 20.5271ZM10.2635 2.56588C14.5088 2.56588 17.9612 6.01828 17.9612 10.2635C17.9612 14.5088 14.5088 17.9612 10.2635 17.9612C6.01828 17.9612 2.56588 14.5088 2.56588 10.2635C2.56588 6.01828 6.01828 2.56588 10.2635 2.56588Z"
                          fill="#FFFFFF"
                        />
                        <path
                          d="M12.0738 8.4496C12.5601 8.93712 12.8282 9.58116 12.8282 10.2637H15.3941C15.3952 9.5894 15.2627 8.92159 15.0042 8.29884C14.7456 7.67609 14.3662 7.11075 13.8879 6.63552C11.9455 4.69571 8.57781 4.69571 6.63672 6.63552L8.44823 8.45217C9.42327 7.4797 11.1039 7.48226 12.0738 8.4496Z"
                          fill="#FFFFFF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="jobList mt-5">
              {searchT != '' || (searchMode === 2 && optionFilter != null) ? (
                <>{searchFilter}</>
              ) : (
                <>{nonFiltered}</>
              )}
            </div>
          </Col>
          <Col md={3}>
            <div className="filter-card">
              <p className="d-flex align-items-center mb-4">
                <Location size="25" color="currentcolor" className="me-2" />
                <span className="f-archivo">Location</span>
              </p>
              <div className="d-flex flex-column">
                <label htmlFor="broker" className="contact-chip">
                  <input type="checkbox" name="relate" id="broker" />
                  <span>Maryland</span>
                </label>
              </div>
            </div>
            <div className="filter-card">
              <p className="d-flex align-items-center mb-4">
                <span className="me-2">{LPSVGIcons.JobType}</span>
                <span className="f-archivo">Job type</span>
              </p>
              <div className="d-flex flex-column">
                {jobType.length > 0 &&
                  jobType.map((degree) => (
                    <label htmlFor={degree} className="contact-chip">
                      <input
                        onClick={() => {
                          setSearchMode(2)
                          setCbState(!cbState)
                        }}
                        type="checkbox"
                        name="Job"
                        id={degree}
                      />
                      <span> {degree}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="filter-card">
              <p className="d-flex align-items-center mb-4">
                <span className="me-2">{LPSVGIcons.Degree}</span>
                <span>Degree</span>
              </p>
              <div className="d-flex flex-column">
                {Degree.length > 0 &&
                  Degree.map((degree) => (
                    <label htmlFor={degree} className="contact-chip">
                      <input
                        onClick={() => {
                          setSearchMode(2)
                          setCbState(!cbState)
                        }}
                        type="checkbox"
                        name="Degree"
                        id={degree}
                      />
                      <span> {degree}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="filter-card">
              <p className="d-flex align-items-center mb-4">
                <span className="me-2">{LPSVGIcons.Degree}</span>
                <span className="f-archivo">Experience</span>
              </p>
              <div className="d-flex flex-column">
                {expfltr.length > 0 &&
                  expfltr.map((degree) => (
                    <label htmlFor={degree} className="contact-chip">
                      <input
                        onClick={() => {
                          setSearchMode(2)
                          setCbState(!cbState)
                        }}
                        type="checkbox"
                        name="exp"
                        id={degree}
                      />
                      <span> {degree}</span>
                    </label>
                  ))}
              </div>
            </div>
            <div className="filter-card">
              <p className="d-flex align-items-center mb-4">
                <span className="me-2">{LPSVGIcons.Degree}</span>
                <span className="f-archivo">Skills</span>
              </p>
              <div className="d-flex flex-column">
                {skillFltr.length > 0 &&
                  skillFltr.map((degree) => (
                    <label htmlFor={degree} className="contact-chip">
                      <input
                        onClick={() => {
                          setSearchMode(2)
                          setCbState(!cbState)
                        }}
                        type="checkbox"
                        name="skill"
                        id={degree}
                      />
                      <span> {degree}</span>
                    </label>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SecTwoCl
