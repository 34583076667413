import React from 'react'
import SecOneAP from './../components/SectionApproach/SecOneAP'
import SecFourAP from './../components/SectionApproach/SecFourAP'
import Footer from './../components/SectionsLP/Footer'

const TechnologyPage = () => {
  return (
    <div className="TechnologyPageMain">
      <SecOneAP />
      <SecFourAP />
      <Footer />
    </div>
  )
}

export default TechnologyPage
