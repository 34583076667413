import React, { useEffect } from 'react'

import 'aos/dist/aos.css'

import './SecTwoAP.scss'
import ca1 from './../../assets/ApproachPage/secTwo/c1.png'
import ca2 from './../../assets/ApproachPage/secTwo/c2.png'
import ca3 from './../../assets/ApproachPage/secTwo/c3.png'
import ca4 from './../../assets/ApproachPage/secTwo/c4.png'

const SecTwoAP = () => {
  return (
    <div className="sectionTwoAP">
      <div className="sectionTwoAPCon">
        <div className="s2aCardGrid">
          <div  className="Gleft">
            {/* CARD START */}
            <div data-aos="fade-right" className="s2ACard">
              <div
                style={{ top: ' -176px', right: '-106px' }}
                className="blrCrcle"
              ></div>
              <div  className="cContent">
                <img className="cIMG" src={ca1} alt="" />

                <h3>Blockchain Technology</h3>
                <p>
                  The transparent and safer ledger technologies of Blockchain
                  digitizes investment management and continuous ROI analysis
                  with seamless smart contracts and increased due diligence
                </p>
              </div>
            </div>
            {/* CARD End */}

            {/* CARD START */}
            <div data-aos="fade-right" className="s2ACard">
              <div
                style={{ bottom: ' -126px', right: '106px' }}
                className="blrCrcle"
              ></div>
              <div className="cContent">
                <img className="cIMG" src={ca2} alt="" />

                <h3>Facility Management</h3>
                <p>
                Facilities management is critical to the success of every organization. The right combination of technology, processes, and expertise can increase your ROI, accelerate operational initiatives, and deliver exceptional employee and customer experiences.
                </p>
              </div>
            </div>
            {/* CARD End */}
          </div>

          {/* START OF RIGHT */}

          <div className="GRight">
            <h4  data-aos="fade-left">
              Cognier Technology Group’s investment strategies relies extensively on cutting-edge
              technological innovations to deliver robust returns to our
              investors
            </h4>

            {/* CARD START */}
            <div data-aos="fade-left" className="s2ACard">
              <div
                style={{ top: ' -230px', right: '106px' }}
                className="blrCrcle"
              ></div>
              <div className="cContent">
                <img className="cIMG" src={ca3} alt="" />

                <h3>Machine Learning</h3>
                <p>
                  Overcome the void between rigid technology and actions by
                  leveraging data automation, predictive insights, and Cognier Technology Group’s
                  competence and broad resources in global portfolio management
                </p>
              </div>
            </div>
            {/* CARD End */}

            {/* CARD START */}
            <div data-aos="fade-left" className="s2ACard">
              <div
                style={{ top: ' -140px', right: '-186px' }}
                className="blrCrcle"
              ></div>
              <div className="cContent">
                <img className="cIMG" src={ca4} alt="" />

                <h3>Data Science</h3>
                <p>
                  We churn out predictive insights from pools of unstructured
                  data to improve your investment experience with customized
                  models of data driven investment and risk analysis
                </p>
              </div>
            </div>
            {/* CARD End */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecTwoAP
