import React from 'react'

import './AllCard.scss'
import oneT from './../../assets/LP/ListImg/01.svg'
const SecTwoWhoCard = (props) => {
  return (
    <div className="SecTwoWhoCardMain">
      <div className="txtConWCM">
        <div className="tcon">
          <img data-aos="fade-up" src={props.i} alt="" />
          <h5 data-aos="fade-up">{props.head}</h5>
          <p data-aos="fade-up">
          {props.content}
          </p>
        </div>
      </div>
    </div>
  )
}

export default SecTwoWhoCard
