import React from 'react'
import SecOneContact from './../components/SectionContact/SecOneContact';


const ContactForm = () => {
    return (
        <div style={{minHeight:"100vh"}}>
            <SecOneContact />
           
        </div>
    )
}

export default ContactForm
