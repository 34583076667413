import SecOneJD from './../components/SectionJobDetails/SecOneJD';
import SecEight from './../components/SectionsLP/SecEight';
import Footer from './../components/SectionsLP/Footer';
import { useEffect } from 'react';
const JobDetailPage = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <div className="JobDetailPage">
            
            <SecOneJD/>
     
            <Footer/>
        </div>
    )
}

export default JobDetailPage
