import React, { useState, useEffect, useCallback } from "react";
import "./Header.scss";
import Logo from "./../assets/Logo.svg";
import { Link, NavLink } from "react-router-dom";
import logaMob from "./../assets/logaMob.svg";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import { GrLinkedinOption } from "react-icons/gr";
import { Container } from "react-bootstrap";
import CogIcon from "./../assets/images/icons/settings.svg";
import CheckIcon from "./../assets/images/icons/check.svg";
import HandShakeIcon from "./../assets/images/icons/handshake.svg";
import ShieldIcon from "./../assets/images/icons/shield.svg";
import BadgeIcon from "./../assets/images/icons/badge.svg";
import TokenIcon from "./../assets/images/icons/token.svg";
import BrandLogo from "./../assets/images/icons/cognier-logo.svg";
import { useRef } from "react";
import "./HeaderNew.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentPage } from "./reducers/current";

export default function Header(props) {
  const dispatch = useDispatch();
  // OLD HEADER CODE
  // const [hamburgerMenuT, setHamburgerMenuT] = useState(false);
  // const [y, setY] = useState(window.scrollY);
  // const [scrollD, setScrollD] = useState();
  // const [direction, setDirection] = useState();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // const handleNavigation = useCallback(
  //   (e) => {
  //     const window = e.currentTarget;
  //     if (y > window.scrollY) {
  //       if (window.scrollY < 90) {
  //         setDirection();
  //         setScrollD();
  //       }
  //     } else if (y < window.scrollY) {
  //       if (window.scrollY > 40) {
  //         setScrollD(true);
  //         setDirection(true);
  //       }
  //     }
  //     setY(window.scrollY);
  //   },
  //   [y]
  // );

  // useEffect(() => {
  //   setY(window.scrollY);
  //   window.addEventListener("scroll", handleNavigation);

  //   return () => {
  //     window.removeEventListener("scroll", handleNavigation);
  //   };
  // }, [handleNavigation]);

  // function HamburgerMenu(e) {
  //   e.preventDefault();
  //   setHamburgerMenuT(!hamburgerMenuT);
  // }
  // OLD HEADER CODE END

  // NEW HEADER
  const [headerScrolled, setHeaderScrolled] = useState(false);
  const [isMenuOpen, setisMenuOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        setHeaderScrolled(true);
      } else {
        setHeaderScrolled(false);
      }
    });
  }, []);

  useEffect(() => {
    let body = document.querySelector("body");
    if (isMenuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "unset";
    }
  }, [isMenuOpen]);
  // NEW HEADER END

  useEffect(() => {
    if (window.scrollY < 32) {
      return console.log("scroll");
    } else return console.log("fixed");
  }, [window.scrollY]);

  return (
    <>
      {/* <div
        className={
          "HeaderMain " +
          (direction && " active ") +
          (props.trigger && " hoverANI ") +
          (props.trigger2 && " hoverANI ")
        }
      >
        <div className={"left " + (hamburgerMenuT && " active ")}>
          <a href="/">
            <img src={Logo} alt="" />
          </a>
        </div>
        <div className="mid">
          <ul>
            <li className={props.active == "1" && " active "}>
              <Link exact to="/">
                Home
              </Link>
            </li>
            <div className="divCircle"></div>

            <li className={props.active == "2" && " active "}>
              <Link to="/Who">Who</Link>
            </li>
            <div className="divCircle"></div>
            <li className={props.active == "3" && " active "}>
              <Link to="/What">What</Link>
            </li>
            <div className="divCircle"></div>
            <li className={props.active == "4" && " active "}>
              <Link to="/Approach">Approach</Link>
            </li>
            <div className="divCircle"></div>
            <li className={props.active == "7" && " active "}>
              <Link to="/Technology">Technology</Link>
            </li>
            <div className="divCircle"></div>
            <li className={props.active == "5" && " active "}>
              <Link to="/Careers">Careers</Link>
            </li>
            <div className="divCircle"></div>
            <li className={props.active == "6" && " active "}>
              <Link to="/News">News</Link>
            </li>
          </ul>
        </div>
        <div className="right">
          <a href="/Contact">
            <button>Get In Touch</button>
          </a>
        </div>

        <div
          className={"HamMenu " + (hamburgerMenuT && " active ")}
          onClick={HamburgerMenu}
        >
          <span className="line One"></span>
          <span className="line Two"></span>
          <span className="line Three"></span>
        </div>

        <div className={"mobHead " + (hamburgerMenuT && " active ")}>
          <ul>
            <li className={props.active == "1" && " active "}>
              <Link exact to="/">
                Home
              </Link>
            </li>

            <li className={props.active == "2" && " active "}>
              <Link to="/Who">Who</Link>
            </li>

            <li className={props.active == "3" && " active "}>
              <Link to="/What">What</Link>
            </li>

            <li className={props.active == "4" && " active "}>
              <Link to="/Approach">Approach</Link>
            </li>

            <li className={props.active == "7" && " active "}>
              <Link to="/Technology">Technology</Link>
            </li>

            <li className={props.active == "5" && " active "}>
              <Link to="/Careers">Careers</Link>
            </li>

            <li className={props.active == "6" && " active "}>
              <Link to="/News">News</Link>
            </li>
          </ul>

          <div className="bottomMob">
            <div className="socialLinks">
              <a href="https://www.linkedin.com/company/cognier-technology/about/">
                <GrLinkedinOption />
              </a>
              <a href="https://www.facebook.com/CognierTechnologyGroup">
                <FiFacebook />
              </a>
              <a href="">
                <FiInstagram />
              </a>
              <a href="">
                <FiTwitter />
              </a>
              <FiInstagram />
            </div>
            <h6>
              {" "}
              (C) Copyright 2021 by Cognier Technology. All rights reserved
            </h6>
          </div>
        </div>
      </div> */}

      {/* NEW HEADER */}
      <header
        className={`custom-header ${isMenuOpen ? "header-menu-open" : ""} ${
          headerScrolled ? "is-scrolled" : ""
        }`}
      >
        <Container>
          <div className="custom-header-inner">
            <div className="brand-logo">
              <Link to="/">
                <img src={BrandLogo} className="img-fluid" />
              </Link>
            </div>

            <nav className="head-menu">
              <li className="head-menu-item">
                <Link
                  onClick={() => {
                    setisMenuOpen(false);
                    dispatch(fetchCurrentPage("WithoutLine"));
                  }}
                  to="/"
                  className="head-menu-link"
                >
                  Home
                </Link>
              </li>

              <li className="head-menu-item">
                <Link
                  onClick={() => {
                    setisMenuOpen(false);
                    dispatch(fetchCurrentPage("WithoutLine"));
                  }}
                  to="/whiteLabelSolution"
                  className="head-menu-link"
                >
                  White Label Solution
                </Link>
              </li>
              <li className="head-menu-item custom-dropdown">
                <div className="head-menu-link">Products</div>
                <ul className="head-sub-menu pc">
                  <div className="newHeaderT">
                    Find out how to unlock new revenue with{" "}
                    <span>digital assets.</span>
                  </div>
                  <div className="newHeaderB">
                    <div className="newHeaderB-T">
                      {" "}
                      <Link
                        onClick={() => {
                          setisMenuOpen(false);
                          dispatch(fetchCurrentPage("WithoutLine"));
                        }}
                        to="/multi-signature-wallet"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={BadgeIcon} alt="Badge" />
                          </div>
                          Multi Signature Wallet
                        </div>
                        <div className="newHeaderItem-B">
                        Proprietary protocols pushes transactions speeds faster than industry average
                        </div>
                      </Link>
                      <svg
                        className="sepLine"
                        width="2"
                        height="147"
                        viewBox="0 0 2 147"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 0L0.999994 147" stroke="#0F3951" />
                      </svg>
                      {/* <Link
                        onClick={() => setisMenuOpen(false)}
                        to="/TrustToken"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={TokenIcon} alt="Badge" />
                          </div>
                          Trust Tokens
                        </div>
                        <div className="newHeaderItem-B">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's
                        </div>
                      </Link> */}
                      <Link
                        onClick={() => {
                          setisMenuOpen(false);
                          dispatch(fetchCurrentPage("WithLine"));
                        }}
                        to="/Tokenization"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={CogIcon} alt="Badge" />
                          </div>
                          Tokenization
                        </div>
                        <div className="newHeaderItem-B">
                        Create, deploy  and manage your Security Tokens using our robust and secure engine.
                        </div>
                      </Link>
                      <svg
                        className="sepLine"
                        width="2"
                        height="147"
                        viewBox="0 0 2 147"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 0L0.999994 147" stroke="#0F3951" />
                      </svg>
                      {/* <Link
                        onClick={() => {
                          dispatch(fetchCurrentPage("WithoutLine"));
                          setisMenuOpen(false);
                        }}
                        to="/Securities"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={ShieldIcon} alt="Badge" />
                          </div>
                          Securities
                        </div>
                        <div className="newHeaderItem-B">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's
                        </div>
                      </Link> */}

<Link
                        onClick={() => {
                          dispatch(fetchCurrentPage("WithoutLine"));
                          setisMenuOpen(false);
                        }}
                        to="/securix"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={HandShakeIcon} alt="Badge" />
                          </div>
                          Securix
                        </div>
                        <div className="newHeaderItem-B">
                        Leverage risk scores, user defined policies for safety and compliance of your transactions
                        </div>
                      </Link>
                    </div>
                    <div className="newHeaderB-B">
                      {" "}
                      <Link
                        onClick={() => {
                          dispatch(fetchCurrentPage("WithLine"));
                          setisMenuOpen(false);
                        }}
                        to="/blue-block-network"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={CogIcon} alt="Badge" />
                          </div>
                          Blue Block Network
                        </div>
                        <div className="newHeaderItem-B">
                        The only one platform for instant settlements, rebalancing and investments 
                        </div>
                      </Link>
                      <svg
                        className="sepLine"
                        width="2"
                        height="147"
                        viewBox="0 0 2 147"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 0L0.999994 147" stroke="#0F3951" />
                      </svg>
                      <Link
                        onClick={() => {
                          dispatch(fetchCurrentPage("WithoutLine"));
                          setisMenuOpen(false);
                        }}
                        to="/kyc-aml"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={HandShakeIcon} alt="Badge" />
                          </div>
                          KYC and AML Policy Engine
                        </div>
                        <div className="newHeaderItem-B">
                        Level up to regulatory requirements and manage transaction policy from anywhere and anytime
                        </div>
                      </Link>
                      <svg
                        className="sepLine"
                        width="2"
                        height="147"
                        viewBox="0 0 2 147"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 0L0.999994 147" stroke="#0F3951" />
                      </svg>
                     
                    </div>
                    {/* <div className="newHeaderB-B">
                      {' '}
                      <Link
                        onClick={() => setisMenuOpen(false)}
                        to="/Tokenization"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={CogIcon} alt="Badge" />
                          </div>
                          Tokenization
                        </div>
                        <div className="newHeaderItem-B">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's
                        </div>
                      </Link>
                    </div> */}
                  </div>
                </ul>
                <ul className="head-sub-menu mob">
                  <li className="head-sub-item">
                    <Link
                      onClick={() => {
                        dispatch(fetchCurrentPage("WithoutLine"));
                        setisMenuOpen(false);
                      }}
                      to="/multi-signature-wallet"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={BadgeIcon} alt="Badge" />
                      </div>
                      Multi Signature Wallet
                    </Link>
                  </li>
                  <li className="head-sub-item">
                    <Link
                      onClick={() =>{
                        dispatch(fetchCurrentPage("WithLine"));
                        setisMenuOpen(false)}}
                      to="/Tokenization"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={TokenIcon} alt="Token" />
                      </div>
                      Tokenization
                    </Link>
                  </li>
                  {/* <li className="head-sub-item">
                    <Link
                      onClick={() => setisMenuOpen(false)}
                      to="/Securities"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={ShieldIcon} alt="Shield" />
                      </div>
                      Securities
                    </Link>
                  </li> */}
                  <li className="head-sub-item">
                    <Link
                      onClick={() => {
                        setisMenuOpen(false)
                        dispatch(fetchCurrentPage("WithLine"));
                      }}
                      to="/blue-block-network"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={CogIcon} alt="Gear" />
                      </div>
                      Blue Block Network
                    </Link>
                  </li>
                  <li className="head-sub-item">
                    <Link
                      onClick={() => setisMenuOpen(false)}
                      to="kyc-aml"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={HandShakeIcon} alt="Handshake" />
                      </div>
                      KYC and AML Policy Engine
                    </Link>
                  </li>
                  <li className="head-sub-item">
                    <Link
                      onClick={() => setisMenuOpen(false)}
                      to="/securix"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={HandShakeIcon} alt="Handshake" />
                      </div>
                      Securix
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="head-menu-item custom-dropdown">
                <div className="head-menu-link">Company</div>
                <ul className="head-sub-menu pc">
                  <div className="newHeaderT">
                    Find out how to unlock new revenue with{" "}
                    <span>digital assets.</span>
                  </div>
                  <div className="newHeaderB">
                    <div className="newHeaderB-T">
                      {" "}
                      <Link
                        onClick={() => {
                          dispatch(fetchCurrentPage("WithoutLine"));
                          setisMenuOpen(false);
                        }}
                        to="/news"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={BadgeIcon} alt="Badge" />
                          </div>
                          Blogs
                        </div>
                        <div className="newHeaderItem-B">
                        Stay on top of current market conditions, industry developments, and investment strategies through our frequent and informative real estate investment blog updates.
                        </div>
                      </Link>
                      <svg
                        className="sepLine"
                        width="2"
                        height="147"
                        viewBox="0 0 2 147"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 0L0.999994 147" stroke="#0F3951" />
                      </svg>
                      <Link
                        onClick={() => {
                          dispatch(fetchCurrentPage("WithoutLine"));
                          setisMenuOpen(false);
                        }}
                        to="/who"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={TokenIcon} alt="Badge" />
                          </div>
                          Who
                        </div>
                        <div className="newHeaderItem-B">
                        We are a leader in the field of commercial real estate investment, utilizing research-backed data science and platform technologies to optimize returns in the digital economy.
                        </div>
                      </Link>
                      <svg
                        className="sepLine"
                        width="2"
                        height="147"
                        viewBox="0 0 2 147"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M1 0L0.999994 147" stroke="#0F3951" />
                      </svg>
                      <Link
                        onClick={() => {
                          dispatch(fetchCurrentPage("WithoutLine"));
                          setisMenuOpen(false);
                        }}
                        to="/Careers"
                        className="newHeaderItem"
                      >
                        <div className="newHeaderItem-T">
                          <div className="imgheadCon">
                            <img src={TokenIcon} alt="Badge" />
                          </div>
                          Careers
                        </div>
                        <div className="newHeaderItem-B">
                        Be a part of our dynamic and innovative team, driving success in modern real estate investment.
update the following
                        </div>
                      </Link>
                    </div>
                  </div>
                </ul>
                <ul className="head-sub-menu mob">
                  <li className="head-sub-item">
                    <Link
                      onClick={() => {
                        dispatch(fetchCurrentPage("WithoutLine"));
                        setisMenuOpen(false);
                      }}
                      to="/news"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={BadgeIcon} alt="Badge" />
                      </div>
                      Blogs
                    </Link>
                  </li>
                  <li className="head-sub-item">
                    <Link
                      onClick={() => {
                        dispatch(fetchCurrentPage("WithoutLine"));
                        setisMenuOpen(false);
                      }}
                      to="/who"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={BadgeIcon} alt="Badge" />
                      </div>
                      Who
                    </Link>
                  </li>
                  <li className="head-sub-item">
                    <NavLink
                      onClick={() => {
                        dispatch(fetchCurrentPage("WithoutLine"));
                        setisMenuOpen(false);
                      }}
                      to="/Careers"
                      className="head-sub-link"
                    >
                      <div className="head-sub-img">
                        <img src={TokenIcon} alt="Token" />
                      </div>
                      Careers
                    </NavLink>
                  </li>
                </ul>
              </li>
              <div className="d-sm-none head-menu-item">
                <button
                  onClick={() =>
                    window.open(
                      "https://cogniertechnology.com/contact",
                      "_blank"
                    )
                  }
                  className="rounded-btn"
                >
                  Get In Touch
                </button>
              </div>
            </nav>

            <button
              onClick={() =>
                window.open("https://cogniertechnology.com/contact", "_blank")
              }
              className="rounded-btn d-none d-sm-flex"
            >
              Get In Touch
            </button>

            <button
              className={`hamburger-btn d-lg-none ${
                isMenuOpen ? 'ham-active' : 'ham-not-active'
              }`}
              onClick={() => setisMenuOpen(!isMenuOpen)}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </Container>
      </header>
      {/* NEW HEADER END */}
    </>
  );
}
