import React from 'react'
import Header from './../Header'
import rocketIMG from './rocket.png'
const SecOneWhoNew = () => {
  return (
    <div className="SecOneWhoNew">
      <div className="monImGRoc">
        <img src={rocketIMG} alt="" />
      </div>
      <div className="scroolInD">
        Scroll{' '}
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 1L6 6L1 0.999999"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <Header active="2" />
      <div className="SecOneWhoNew-L">
        <div className="SecOneWhoNewTCON-L">
          <h1>Vision</h1>
          <svg
            width="219"
            height="4"
            viewBox="0 0 219 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.99095"
              y1="2.9549"
              x2="217.991"
              y2="1.00895"
              stroke="url(#paint0_radial_5307_14647)"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <defs>
              <radialGradient
                id="paint0_radial_5307_14647"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(107.342 3.00583) rotate(-0.516165) scale(111.663 861)"
              >
                <stop stopColor="#219EBC" />
                <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>
          <p>
            Our vision in a world of business monotone is to coalesce
            leading-edge, dependable innovations in technology with
            extraordinary investment opportunities in private markets 
          </p>
        </div>
      </div>
      <div className="SecOneWhoNew-M">
        <svg
          className="backCsvg"
          width="846"
          height="714"
          viewBox="0 0 846 714"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            opacity="0.1"
            cx="283.912"
            cy="235.584"
            r="217.589"
            transform="rotate(-15 283.912 235.584)"
            stroke="white"
            strokeWidth="2"
          />
          <path
            opacity="0.1"
            d="M565.704 160.078C603.575 301.416 519.801 446.666 378.592 484.503C237.383 522.34 92.2076 438.437 54.336 297.098C16.4644 155.76 100.238 10.5103 241.448 -27.3266C382.657 -65.1636 527.832 18.7392 565.704 160.078Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            opacity="0.1"
            d="M642.396 139.528C687.379 307.404 587.857 479.93 420.11 524.878C252.363 569.825 79.9118 470.174 34.9296 302.298C-10.0525 134.423 89.4693 -38.1035 257.216 -83.0511C424.963 -127.999 597.414 -28.3475 642.396 139.528Z"
            stroke="white"
            strokeWidth="2"
          />
          <path
            opacity="0.1"
            d="M830.438 181.143C890.804 406.431 757.314 637.942 532.283 698.238C307.253 758.535 75.8919 624.786 15.5261 399.498C-44.8396 174.21 88.65 -57.3006 313.681 -117.597C538.711 -177.894 770.072 -44.1449 830.438 181.143Z"
            stroke="white"
            strokeWidth="2"
          />
        </svg>

        <img src={rocketIMG} alt="" />
      </div>
      <div className="SecOneWhoNew-R">
        <div className="SecOneWhoNewTCON-R">
          <h1>Mission</h1>
          <svg
            width="219"
            height="4"
            viewBox="0 0 219 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.99095"
              y1="2.9549"
              x2="217.991"
              y2="1.00895"
              stroke="url(#paint0_radial_5307_14647)"
              strokeWidth="2"
              strokeLinecap="square"
            />
            <defs>
              <radialGradient
                id="paint0_radial_5307_14647"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(107.342 3.00583) rotate(-0.516165) scale(111.663 861)"
              >
                <stop stopColor="#219EBC" />
                <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
              </radialGradient>
            </defs>
          </svg>
          <p>
            Our real estate investment partners utilize our research-led data
            science approach and platform technologies that align with their
            core commitment to reap higher returns in the digital economy
          </p>
        </div>
      </div>
    </div>
  )
}

export default SecOneWhoNew
