import React, { useEffect, useRef, useState } from 'react'
import './Canimation.scss'
import Ci from './../assets/Cani/C.png'
import Co from './../assets/Cani/one.png'
import CT from './../assets/Cani/two.png'
import CTh from './../assets/Cani/Three.png'

import CF from './../assets/Cani/four.png'
import { TweenMax, TimelineLite, Power3, Power0 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'
gsap.registerPlugin(ScrollTrigger)
const Canimation = () => {
  useEffect(() => {
    gsap.from('.CaniMimgCon .CaniMimg ', {
      duration: 1.5,
      y: '100',
      opacity: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.CaniMimgCon',
        // markers: true,
        start: 'top 70%',
        end: 'bottom 10%',
        toggleActions: 'play none none reverse',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })


    // for first ele
    gsap.to('.CanimationMain .LineAniCOn.top1R', {
      duration: 0.5,
      opacity: 1,
      delay: 1,
      ease: Power0,
      scrollTrigger: {
        trigger: '.CanimationMain .LineAniCOn.top1R',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })
    gsap.to('.CanimationMain .LineAniCOn.top1R .cAline', {
        duration: 0.5,
        opacity: 1,
        width:"69px",
        delay: 2,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.top1R',
          // markers: true,
          start: 'top 60%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })
      
    gsap.to('.CanimationMain .LineAniCOn.top1R .cAline .cAline-tip', {
        duration: 0.5,
        opacity: 1,
        delay: 3,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.top1R',
          // markers: true,
          start: 'top 60%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })
      
    gsap.to('.CanimationMain .LineAniCOn.top1R .cAline .cAline-tip .Co', {
        duration: 0.5,
        opacity: 1,
        right: "-320px",
        delay: 4,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.top1R',
          // markers: true,
          start: 'top 60%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })

      
    // // for second ele
    gsap.to('.CanimationMain .LineAniCOn.top1L', {
        duration: 0.5,
        opacity: 1,
        delay: 1,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.top1L',
          // markers: true,
          start: 'top 60%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })
      
    gsap.to('.CanimationMain .LineAniCOn.top1L .cAline2', {
        duration: 0.5,
        opacity: 1,
        width:"244px",
        delay: 2,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.top1L',
          // markers: true,
          start: 'top 60%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })
      
    gsap.to('.CanimationMain .LineAniCOn.top1L .cAline2 .cAline-tip', {
        duration: 0.5,
        opacity: 1,
        delay: 3,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.top1L',
          // markers: true,
          start: 'top 60%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })
      
    gsap.to('.CanimationMain .LineAniCOn.top1L .cAline2 .cAline-tip .Co2', {
        duration: 0.5,
        opacity: 1,
        left: "-310px",
        delay: 4,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.top1L',
          // markers: true,
          start: 'top 60%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })











      //for 3rd ele

      gsap.to('.CanimationMain .LineAniCOn.BtmL', {
        duration: 0.5,
        opacity: 1,
        delay: 1,
        ease: Power0,
        scrollTrigger: {
          trigger: '.CanimationMain .LineAniCOn.BtmL',
        //   markers: true,
          start: 'top 70%',
          end: 'bottom 10%',
          // toggleActions: 'play none none reverse',
          toggleActions: 'restart complete reverse reset',
          //options: play, pause, resume, reset, restart, complete, reverse,none
        },
      })
      gsap.to('.CanimationMain .LineAniCOn.BtmL .cAline2', {
          duration: 0.5,
          opacity: 1,
          width:"244px",
          delay: 2,
          ease: Power0,
          scrollTrigger: {
            trigger: '.CanimationMain .LineAniCOn.BtmL',
            // markers: true,
            start: 'top 70%',
            end: 'bottom 10%',
            // toggleActions: 'play none none reverse',
            toggleActions: 'restart complete reverse reset',
            //options: play, pause, resume, reset, restart, complete, reverse,none
          },
        })
        
      gsap.to('.CanimationMain .LineAniCOn.BtmL .cAline2 .cAline-tip', {
          duration: 0.5,
          opacity: 1,
          delay: 3,
          ease: Power0,
          scrollTrigger: {
            trigger: '.CanimationMain .LineAniCOn.BtmL',
            // markers: true,
            start: 'top 70%',
            end: 'bottom 10%',
            // toggleActions: 'play none none reverse',
            toggleActions: 'restart complete reverse reset',
            //options: play, pause, resume, reset, restart, complete, reverse,none
          },
        })
        
      gsap.to('.CanimationMain .LineAniCOn.BtmL .cAline2 .cAline-tip .Co3', {
          duration: 0.5,
          opacity: 1,
          left: "-320px",
          delay: 4,
          ease: Power0,
          scrollTrigger: {
            trigger: '.CanimationMain .LineAniCOn.BtmL',
            // markers: true,
            start: 'top 70%',
            end: 'bottom 10%',
            // toggleActions: 'play none none reverse',
            toggleActions: 'restart complete reverse reset',
            //options: play, pause, resume, reset, restart, complete, reverse,none
          },
        })
  






// for forth ele

gsap.to('.CanimationMain .LineAniCOn.BtmR', {
    duration: 0.5,
    opacity: 1,
    delay: 1,
    ease: Power0,
    scrollTrigger: {
      trigger: '.CanimationMain .LineAniCOn.BtmR',
    //   markers: true,
      start: 'top 75%',
      end: 'bottom 10%',
      // toggleActions: 'play none none reverse',
      toggleActions: 'restart complete reverse reset',
      //options: play, pause, resume, reset, restart, complete, reverse,none
    },
  })
  gsap.to('.CanimationMain .LineAniCOn.BtmR .cAline', {
      duration: 0.5,
      opacity: 1,
      width:"69px",
      delay: 2,
      ease: Power0,
      scrollTrigger: {
        trigger: '.CanimationMain .LineAniCOn.BtmR',
        // markers: true,
        start: 'top 75%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })
    
  gsap.to('.CanimationMain .LineAniCOn.BtmR .cAline .cAline-tip', {
      duration: 0.5,
      opacity: 1,
      delay: 3,
      ease: Power0,
      scrollTrigger: {
        trigger: '.CanimationMain .LineAniCOn.top1R',
        // markers: true,
        start: 'top 75%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })
    
  gsap.to('.CanimationMain .LineAniCOn.BtmR .cAline .cAline-tip .Co4', {
      duration: 0.5,
      opacity: 1,
      right: "-320px",
      delay: 4.5,
      ease: Power0,
      scrollTrigger: {
        trigger: '.CanimationMain .LineAniCOn.top1R',
        // markers: true,
        start: 'top 75%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })






















  })
  return (
    <div className="CanimationMain">
      <div className="CaniMimgCon">
        <img className="CaniMimg" src={Ci} alt="" />
        <div className="top1R LineAniCOn">
          <div className="cAline">
            <div className="cAline-tip">
              <div className="l1"></div>
              <div className="l2"></div>
              <img className="Co" src={Co} alt="" />
            </div>
          </div>
        </div>

        <div className="top1L LineAniCOn">
          <div className="cAline2">
            <div className="cAline-tip">
              <div className="l3"></div>
              <div className="l4"></div>
              <img className="Co2" src={CT} alt="" />
            </div>
          </div>
        </div>

        <div className="BtmL LineAniCOn">
          <div className="cAline2">
            <div className="cAline-tip">
              <div className="l3"></div>
              <div className="l4"></div>
              <img className="Co3" src={CTh} alt="" />
            </div>
          </div>
        </div>

        <div className="BtmR LineAniCOn">
          <div className="cAline">
            <div className="cAline-tip">
              <div className="l1"></div>
              <div className="l2"></div>
              <img className="Co4" src={CF} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Canimation
