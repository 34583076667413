import React from 'react'
import './Team.scss'
import c1 from './../../assets/Founders/midD.png'
import c2 from './../../assets/Founders/leftD.png'
import c3 from './../../assets/Founders/rightD.png'
import c1I from './../../assets/Team/tl.png'
import c3I from './../../assets/Team/Karthik.png'
import c2I from './../../assets/Team/joe.png'

const Team = () => {
  return (
    <div className="teamMain">
      <div   data-aos="fade-up" className="teamMainTop">
        <h3>Entreprenuers, Innovators and Engineers</h3>
        <div className="umhcL"></div>
        <h4>
          Our team brings a wealth of expertise, diversity and experience in the
          real estate sector and transforms our vision of revolutionizing real
          estate investment experience into a reality
        </h4>
      </div>
      <div    className="teamMainBtm">
        <div className="teamNameCon">
          <div className="TopLane">
            {/* CARD START */}
            <div data-aos="fade-right" className="TCard">
              <div className="TCardIN">
                <img className="Mimg" src={c1I} alt="" />
                <div className="TCcon">
                  <h2>Thomas Lah</h2>
                  <h3>
                    Mr. Lah founded Cognier Technology Group to built software platform businesses
                    that develop and use blockchain and artificial intelligence
                    in real estate to promote economic equity, ecological
                    responsibility and diversity and inclusion for the
                    prosperity of all.
                  </h3>
                  <div className="sLink">
                    <a href="https://www.linkedin.com/in/thomas-p-lah/">
                      LinkedIn
                    </a>
                  </div>
                </div>
                <img className="Dimg" src={c1} alt="" />
              </div>
              <div className="bgBlur"></div>
            </div>
            {/* CARD END */}

            {/* CARD START */}
            <div data-aos="fade-up" className="TCard">
              <div className="TCardIN">
                <img className="Mimg" src={c2I} alt="" />
                <div className="TCcon">
                  <h2>Joe Copsey</h2>
                  <h3>
                    As Cognier Technology Group’s Chief Strategy Officer, Joe orchestrates all the
                    “things” (Initiatives). With more than 20 years of sales and
                    operations experience, he is both our culture agent and
                    steward obsessed with creating efficiencies and great
                    experiences for our clients and business partners.
                  </h3>
                  <div className="sLink">
                    <a href="https://www.linkedin.com/in/joecopsey/">
                      LinkedIn
                    </a>
                  </div>
                </div>
                <img className="Dimg" src={c3} alt="" />
              </div>
              <div className="bgBlur"></div>
            </div>
            {/* CARD END */}

            {/* CARD START */}
            <div data-aos="fade-left" className="TCard L-TCard">
              <div className="TCardIN">
                <img className="Mimg" src={c3I} alt="" />
                <div className="TCcon">
                  <h2>Karthik Krishnan</h2>
                  <h3>
                    Karthik Krishnan is the co-founder and chief financial
                    officer of Cognier Technology Group, Inc. Having joined in
                    2013, Karthik has played in integral role in supporting the
                    operational, corporate governance, and fiscal
                    responsibilities of the company.
                  </h3>
                  <div className="sLink">
                    <a href="https://www.linkedin.com/in/kkrishnan93/">
                      LinkedIn
                    </a>
                  </div>
                </div>
                <img
                  style={{ width: '140px' }}
                  className="Dimg"
                  src={c2}
                  alt=""
                />
              </div>
              <div className="bgBlur"></div>
            </div>
            {/* CARD END */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
