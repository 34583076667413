import React, { useEffect } from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import './SecThreeAP.scss'
import DNA from './../../assets/ApproachPage/DNAN.png'
import DNAS from './../../assets/ApproachPage/DNANS.png'

const SecThreeAP = () => {
  return (
    <div className="sectionThreeAP">
      <div className="sectionThreeAPCon">
        <div className="DnaIMGCON">
          <img className="dnaImg" src={DNA} alt="" />
          <img className="dnaSImg" src={DNAS} alt="" />
        </div>
        <div data-aos="fade-up" className="tconS3ap">
          <h3>OUR DNA</h3>
          <h2>Bridging phenomenal ideas and Actions</h2>
          <p>
            We are technophiles and data-geeks at heart who fuses industry
            experience, and technical prowess within a risk-regulated framework
            to garner additional value and consistent returns
          </p>
        </div>
      </div>
    </div>
  )
}

export default SecThreeAP
