import React, { useEffect, useState, useCallback } from "react";
import HeaderBlank from './../components/HeaderBlank';
import "./TermsAndConditionPage.scss";
const TermsAndConditionPage = () => {
  const [y, setY] = useState(window.scrollY);
  const [scrollD, setScrollD] = useState();

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY < 290) {
          setScrollD();
        }
        console.log("scrolling up : " + window.scrollY);
      } else if (y < window.scrollY) {
        console.log("scrolling down : " + window.scrollY);

        if (window.scrollY > 300) {
          setScrollD(true);
        }
      }
      setY(window.scrollY);
    },
    [y]
  );
useEffect(() => {
  window.scrollTo(0, 0);
},[])
  useEffect(() => {
   
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className="TermsAndConditionPageMain">
      <HeaderBlank />
      <div className="TermsAndConditionPageMainWrapper">
        <div className="leftTAC">
          <div className={"leftTACWrapper " + (scrollD && " active ")}>
            <a href="/">
              <h4>
                Cognier {">"} Terms {"&"} Condition
              </h4>
            </a>
            <h3>Terms {"&"} Condition</h3>
            <ul>
              <li>
                <a href="#s1">
                  This Privacy Statement is effective as of January 2021.
                </a>
              </li>
              <li>
                <a href="#s2">
                  Access and use of websites or other online services
                </a>
              </li>
              <li>
                <a href="#s3">
                  Responding to your request for information, order, or support
                </a>
              </li>
              <li>
                <a href="#s4">Your use of Cognier Technology Group Cloud services</a>
              </li>
              <li>
                <a href="#s5">
                  Contacting employees of our clients, prospects, partners and
                  suppliers
                </a>
              </li>
              <li>
                <a href="#s6">Visitor information</a>
              </li>
              <li>
                <a href="#s7">Marketing</a>
              </li>
              <li>
                <a href="#s8">Sharing of Personal Information</a>
              </li>
              <li>
                <a href="#s9">International Transfers</a>
              </li>
              <li>
                <a href="#s10">Information Security and Accuracy</a>
              </li>
              <li>
                <a href="#s11">Retention Period</a>
              </li>
              <li>
                <a href="#s12">contact us</a>
              </li>
              <li>
                <a href="#s13">Your Rights</a>
              </li>
              <li>
                <a href="#s14">Right to Lodge a Complaint</a>
              </li>
              <li>
                <a href="#s15">Changes to our Privacy Statements</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="rightTAC">
          <div className="TacRComp" id="s1">
            <h3>This Privacy Statement is effective as of January 2021.</h3>
            <span>
              At Cognier Technology Group we recognize the importance of protecting your personal
              information and are committed to processing it responsibly and in
              compliance with applicable data protection laws in all countries
              in which Cognier Technology Group operates. This Privacy Statement describes Cognier Technology Group
              general privacy practices that apply to the personal information
              we collect, use and share about consumers and other individuals
              within our clients, business partners, suppliers, and other
              organizations with which Cognier Technology Group has or contemplates a business
              relationship. This Privacy Statement is supplemented by the Cognier Technology Group
              Online Privacy Statement, which provides more information in the
              online context, including recruitment. We may provide additional
              or more specific information on the collection or use of personal
              information on websites or related to a specific product or
              service.
            </span>
          </div>
          <div className="TacRComp" id="s2">
            <h3>Access and use of websites or other online services</h3>
            <span>
              When entering one of our websites, or using an online service, we
              will record information necessary to provide you with access, for
              the operation of the website and for us to comply with security
              and legal requirements in relation to operating our sites, such as
              passwords, IP address and browser settings. We also collect
              information about your activities during your visit in order to
              personalize your website experiences, such as recording your
              preferences and settings and to collect statistics to help us
              improve and further develop our websites, products, and services.
            </span>
          </div>
          <div className="TacRComp" id="s3">
            <h3>
              Responding to your request for information, order, or support
            </h3>
            <span>
              When you contact us (online or offline) in connection with a
              request for information, to order a product or service, to provide
              you with support, or to participate in a forum or other social
              computing tool, we collect information necessary to fulfill your
              request, to grant you access to the product or service, to provide
              you with support and to be able to contact you. For instance, we
              collect your name and contact information, details about your
              request and your agreement with us and the fulfillment, delivery
              and invoicing of your order and we may include client satisfaction
              survey information. We retain such information for administrative
              purposes, defending our rights, and in connection with our
              relationship with you.
            </span>
            <br />
            <br />
            <span>
              When you provide your name and contact information to register in
              connection with such a request, the registration may serve to
              identify you when you visit our websites. For ordering of most
              services and products, we require you to have registered at Cognier Technology Group
              connect. Registration may also allow you to customize and control
              your privacy settings.
            </span>
          </div>
          <div className="TacRComp" id="s4">
            <h3>Your use of Cognier Technology Group Cloud services</h3>
            <span>
              We collect information about your use of AI Decision Cloud
              services to enable product features to operate, improve your user
              experience, tailor our interactions with you, inform our clients
              on the overall use of the services, provide support and improve
              and develop our products and services. For details regarding the
              technologies we employ, the personal information we collect, as
              well as how to control or block tracking or to delete cookies,
              please refer to the Cognier Technology Group Online Privacy Statement.
            </span>
          </div>
          <div className="TacRComp" id="s5">
            <h3>
              Contacting employees of our clients, prospects, partners and
              suppliers
            </h3>
            <span>
              In our relationship with clients or prospects, partners and
              suppliers, they also provide us with business contact information
              (such as name, business contact details, position or title of
              their employees, contractors, advisors and authorized users) for
              purposes such as contract management, fulfillment, delivery of
              products and services, provision of support, invoicing and
              management of the services or the relationship.
            </span>
          </div>
          <div className="TacRComp" id="s6">
            <h3>Visitor information</h3>
            <span>
              We register individuals visiting our sites and locations (name,
              identification, and business contact information) and use camera
              supervision for reasons of security and safety of persons and
              belongings, as well as for regulatory purposes.
            </span>
          </div>
          <div className="TacRComp" id="s7">
            <h3>Marketing</h3>
            <span>
              Most information we collect about you comes from our direct
              interactions with you. When you register for an event we may
              collect information (online or offline) in relation to the event
              organization, and during an event, such as participation in
              sessions and survey results. We combine the personal information
              we collect to develop aggregate analysis and business intelligence
              for conducting our business and for marketing purposes. You can
              choose to receive information by email, telephone or postal mail
              about our products and services, or sign-up for subscriptions.
              When visiting our websites or using our services we may provide
              you with personalized information.
            </span>
            <br />
            <br />
            <span>
              Where we reference that we use your personal information in
              connection with a request, order, transaction or agreement (or
              preparing for the same), or to provide you with services that you
              requested (such as a website), we do this because it is necessary
              for the performance of an agreement with you.
            </span>
            <br /> <br />
            <span>
              Where we reference that we use your personal information in
              relation to marketing, improvement or development of our products
              or services, for reasons of safety and security, or regulatory
              requirements other than in connection with your agreement or
              request, we do this on the basis of our or a third party’s
              legitimate interests, or with your consent.
            </span>
          </div>
          <div className="TacRComp" id="s8">
            <h3>Sharing of Personal Information</h3>
            <span>
              we may share information about you with our subsidiaries worldwide
              and transfer it to countries in the world where we do business in
              accordance with this Privacy Statement.
            </span>
            <br />
            <br />
            <span>
              Between Cognier Technology Group controlled subsidiaries we only grant access to
              personal information on a need-to-know basis, necessary for the
              purposes for which such access is granted. In some cases, Cognier Technology Group uses
              suppliers located in various countries to collect, use, analyze,
              and otherwise process personal information on its behalf.
            </span>
            <br />
            <br />
            <span>
              Where appropriate, Cognier Technology Group may also share your personal information
              with selected partners to help us provide you, or the company you
              work for, products or services, or to fulfill your requests, or
              with your consent. When selecting our suppliers and partners, we
              take into account their data handling processes.
            </span>
            <br />
            <br />
            <span>
              If Cognier Technology Group decides to sell, buy, merge or otherwise reorganize
              businesses in some countries, such a transaction may involve the
              disclosure of personal information to prospective or actual
              purchasers, or the receipt of such information from sellers. It is
              Cognier Technology Group's's practice to require appropriate protection for personal
              information in these types of transactions.
            </span>
            <br />
            <br />
            <span>
              Please be aware that in certain circumstances, personal
              information may be subject to disclosure to government agencies
              pursuant to a judicial proceeding, court order, or legal process.
              We may also share your personal information to protect the rights
              or property of Cognier Technology Group, our business partners, suppliers or clients,
              and others when we have reasonable grounds to believe that such
              rights or property have been or could be affected.
            </span>
          </div>
          <div className="TacRComp" id="s9">
            <h3>International Transfers</h3>
            <span>
              The footprint of Cognier Technology Group involves a large number of transfers of
              personal information between different subsidiaries, as well as to
              third parties located in the countries where we do business. Some
              countries have implemented transfer restrictions for personal
              information, in connection with which Cognier Technology Group takes various measures,
              including:
            </span>
            <br />
            <br />
            <span>
              Where required, Cognier Technology Group implements Standard Contractual Clauses or
              similar contractual clauses in other jurisdictions. This includes
              transfers to suppliers or other third parties.
            </span>
          </div>
          <div className="TacRComp" id="s10">
            <h3>Information Security and Accuracy</h3>
            <span>
              We intend to protect your personal information and to maintain its
              accuracy. Cognier Technology Group implements reasonable physical, administrative and
              technical safeguards to help us protect your personal information
              from unauthorized access, use, and disclosure. For example, we
              encrypt certain sensitive personal information such as credit card
              information when we transmit such information over the Internet.
              We also require that our suppliers protect such information from
              unauthorized access, use, and disclosure.
            </span>
          </div>
          <div className="TacRComp" id="s11">
            <h3>Retention Period</h3>
            <span>
              We will not retain personal information longer than necessary to
              fulfill the purposes for which it is processed, including the
              security of our processing complying with legal and regulatory
              obligations (e.g. audit, accounting, and statutory retention
              terms), handling disputes, and for the establishment, exercise or
              defense of legal claims in the countries where we do business.
            </span>
            <br />
            <br />
            <span>
              Because the circumstances may vary depending on the context and
              the services, the information provided in or provided in a
              specific notice may provide more detailed information on
              applicable retention terms.
            </span>
          </div>
          <div className="TacRComp" id="s12">
            <h3>Contact Us</h3>
            <span>
              If you have a question related to this Privacy Statement, please
              contact us by using this form. Your message will be forwarded to
              the appropriate member of Cognier Technology Group's Data Privacy Team, such as Data
              Protection Officers or members of their teams. For the purposes of
              the EU General Data Protection Regulation (GDPR), the controller
              of your personal information is Cognier Technology Group, Inc
              Where Cognier Technology Group, Inc, or a subsidiary it controls (not established in
              the European Economic Area (EEA))
            </span>
          </div>
          <div className="TacRComp">
            <h3> Cognier Technology Group Inc.. is not the controller in situations such as:</h3>
            <span>
              Personal information processed in connection with a contractual
              relationship, or with entering into a contractual relationship
              with a specific Cognier Technology Group subsidiary. In this case, the controller of
              personal information is the Cognier Technology Group contracting company, which is the
              legal entity with which you or your employer have, or will have,
              the contract.
            </span>
            <br />
            <br />
            <span>
              Personal information collected on the physical site or location of
              a Cognier Technology Group subsidiary. In this case that subsidiary is the controller
              of the personal information.
            </span>
          </div>
          <div className="TacRComp" id="s13">
            <h3>Your Rights</h3>
            <span>
              Personal information processed in connection with a contractual
              relationship, or with entering into a contractual relationship
              with a specific Cognier Technology Group subsidiary. In this case, the controller of
              personal information is the Cognier Technology Group contracting company, which is the
              legal entity with which you or your employer have, or will have,
              the contract.
            </span>
            <br />
            <br />
            <span>
              Personal information collected on the physical site or location of
              a Cognier Technology Group subsidiary. In this case that subsidiary is the controller
              of the personal information.
            </span>
          </div>
          <div className="TacRComp" id="s14">
            <h3>Right to Lodge a Complaint</h3>
            <span>
              In the event you consider our processing of your personal
              information not to be compliant with the applicable data
              protection laws, you can lodge a complaint: Directly with Cognier Technology Group by
              using this form.
            </span>
          </div>
          <div className="TacRComp" id="s15">
            <h3>Changes to our Privacy Statements</h3>
            <span>
              From time to time we may update this Privacy Statement, as well as
              any other specific privacy statement. When making changes to this
              Privacy Statement, we will add a new date at the top of this
              Privacy Statement.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionPage;
