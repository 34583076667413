import React, { useEffect, useState } from 'react'
import CardNLSmall from './../SectionNewsListing/CardsNL/CardNLSmall'
import axios from 'axios'
import './SecTwoND.scss'
const SecTwoND = () => {
  const [allNewsL, setAllNewsL] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getData() {
      const res = await axios.get(
        `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&_embed`,
      )

      console.log(res)
      let counter = 0
      setAllNewsL(
        res.data.map((name, i) => {
          if (counter < 3) {
            counter++
            return (
              <CardNLSmall
                trigger={setLoading}
                index={i}
                img={name?.better_featured_image?.source_url}
                name={name.title.rendered}
                content={name.excerpt.rendered}
                recomendedRead={true}
              />
            )
          }
          setLoading(false)
        }),
      )
    }
    getData()
  }, [])

  return (
    <div className="SecTwoNDMain">
      {loading ? <>""</> : <h2 className="titleRR">Recommended Read</h2>}
      <div onClick={() => setLoading(true)} className="SecTwoNDMainCon">
        {allNewsL}
      </div>
    </div>
  )
}

export default SecTwoND
