import React, { useRef, useState, useEffect } from "react";
import "./SecOneContact.scss";
import Header from "./../Header";
import axios from "axios";
import Footer from "./../SectionsLP/Footer";
const SecOneContact = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    const config = {
      headers: {
        "X-Api-Key": "DJJA0xkf.U5rjiiwJgRRVpD9FFUWkBGIHse2A0uxH",
      },
    };

    const body = new FormData();
    body.append("name", fullName);
    body.append("company", company);
    body.append("email", email);
    body.append("contact_number", phoneNo);
    body.append("message", message);

    const url = `https://api.cogniertechnology.com/api/contacts/`;

    axios
      .post(url, body, config)
      .then((response) => {
        console.log(response);
        alert("successfully submitted contact");
      })
      .catch((error) => {
        alert("Fail to submit");
        console.log(error.response);
      });
  }
  return (
    <div className="SecOneContactMain ContactPage">
      <Header />
      {/* <div className="SecOneContactMainWrapper">
        <div className="leftContact">
          <div className="leftContactWrapper">
            <h3>Cognier{">"} Contact </h3>
            <h2>Collaborations Form</h2>
          </div>
        </div>
        <div className="rightContact">
          <form onSubmit={handleSubmit}>
            <div className="formItemCon">
              <span>Full Name</span>
              <input
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="formItemCon">
              <span>Company (optional)</span>
              <input
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </div>
            <div className="formItemCon">
              <span>Email</span>
              <input
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="formItemCon">
              <span>PhoneNo</span>
              <input
                required
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </div>
            <div className="formItemCon messageCon">
              <span>Message</span>
              <textarea
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div> */}
      <div className="mt100">
     
      </div>
      {/* <div className="mt100"></div>
      <div className="mt100"></div> */}
      
      <Footer mode="landing nobg" />
    </div>
  );
};

export default SecOneContact;
