import React from 'react'
import './NewLandingPage.scss'
import bulbInbox from './../../assets/newAssets/bulbInbox.png'
import Img1 from "../../assets/HomeHeroNew.png"
import { MultiText } from '../../components/Utils'
import blurImg from './../Digitrust/blur.png'

const BenchmarkSection = () => {
  return (
    <div className="Tokenized BenchmarkSection-Main" id="BenchmarkSection-Main">
      <div className="BenchmarkSection-L">
        <img className="blrImgCon" src={blurImg} alt="" />

        <img className='bulbimage' src={Img1} alt="" />
      </div>
      <div className="BenchmarkSection-R">
        <MultiText
          h1=" The Lifecycle of"
          span="Tokenized Securities"
          h1Size="48px"
          boxSize="450px"
        />
        <p>
          Enabling qualification by allowing asset owners to digitally and compliantly issue, allocate and manage asset tokens while providing an improved and highly transferable security service for investors.
        </p>
        <button
          onClick={() =>
            window.open(
              'https://cogniertechnology.com/contact',
              '_blank',
            )
          }
          className="MainGetStartedBTN"
        >
          Get in Touch
        </button>
      </div>

      <div className="AccelerateOpportunitiesCon">
      <img className="blrImgConAA" src={blurImg} alt="" />
        <MultiText
          h1=" We deliver cost efficient and"
          span="accelerated assurances"
          h1Size="28px"
          boxSize="372px"
        />
        <p>
          Cognier Technology allows companies operating in private markets to benefit from using blockchain technology while maintaining compliance and control , we enable our clients to increase efficiency , enforce global compliance , automate processes and ultimately gain a competitive leverage.
        </p>
      </div>
    </div>
  )
}

export default BenchmarkSection
