import React from "react";
import Footer from "../components/SectionsLP/Footer";
import Header from "../components/Header";
// import pageImg from "./../assets/PAGESIMG/TokenPage.png";
import TEsecOne from '../newPages/TokenEnginePage/TEsecOne';
import TEsecTwo from '../newPages/TokenEnginePage/TEsecTwo';
import TEsecFeatures from "../newPages/TokenEnginePage/TEsecFeatures";
import TOne from './../newPages/Tokenization/TOne';
import TTwo from './../newPages/Tokenization/TTwo';

const Tokenization = () => {
  return (
    <div className="Tokenization">
      <Header active="1" />
      <TOne/>
      <TTwo/>
      {/* <img src={pageImg} alt="" className="w-100" /> */}
     
      <Footer />
    </div>
  );
};

export default Tokenization;
