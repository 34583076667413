import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import Header from './../Header'
import './NewsList.scss'
import axios from 'axios'
import doodle from './../../assets/NewsListing/doodle.png'
import { useSelector, useDispatch } from 'react-redux'
import { nds } from './../actions'
import { Link, useHistory } from 'react-router-dom'
import CardNLBig from './CardsNL/CardNLBig'
import CardNLSmall from './CardsNL/CardNLSmall'
import Loader from '../Loader/Loader'
import moment from 'moment'
// import * as ReactBootstrap from "react-bootstrap";
import Post from './../Post'
import Pagination from './../Pagination'

import { Location, Shop } from 'iconsax-react'

const NewsList = () => {
  const ndR = useSelector((state) => state.ndR)
  const dispatch = useDispatch()
  const history = useHistory()
  const [allNewsL, setAllNewsL] = useState([])
  const [allNewsM, setAllNewsM] = useState([])
  const [allNewsR, setAllNewsR] = useState([])
  const [allNewsLTAB, setAllNewsLTAB] = useState([])
  const [allNewsRTAB, setAllNewsRTAB] = useState([])
  const [allNewsMob, setAllNewsMob] = useState([])

  const [loading, setLoading] = useState(false)

  const [posts, setPosts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [postPerPage, setPostPerPage] = useState(20)
  const [mode, setMode] = useState('1')
  const [allTags, setAllTags] = useState([])
  const [fliteringBlogs, setFliteringBlogs] = useState([])
  //change page
  const [postOnCur, setPostOnCur] = useState([])
  const paginate = (pageNumber) => setCurrentPage(pageNumber)
  const [searchMode, setSearchMode] = useState(1)
  const [searchT, setSearchT] = useState('')
  const [searchFilter, setSearchFilter] = useState()
  const [filterdBlogs, setFilterdBlogs] = useState([])
  useEffect(() => {
    window.scrollTo(0, 0)
    async function getData() {
      const res = await axios.get(
        `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&_embed`,
      )
      console.log(res.data)
      setPosts(res.data)
      setLoading(true)
      let counterl = -1
      let counterM = -1
      let counterR = -1
      let stateM = 1
      let stateR = 3
      let counterlT = 1
      let counterrT = 0
      let stateRT = 2

      //for pagination m
      const indexOfLastPost = 1 * postPerPage
      const indexOfFirstPost = indexOfLastPost - postPerPage
      const currentPosts = posts.slice(0, 10)
      console.log(currentPosts)
      let counterrTT = 0
      let pcounterpagi = 0
      console.log('==================sss==================')
      console.log(res.data)
      console.log('====================================')
      setFliteringBlogs(res.data)
      setPostOnCur(
        res.data.map((name, i) => {
          counterrTT++
          let datePub = name.date
          let datePubFINAL = moment(datePub).fromNow()
          const tagsDATA = []
          let imgT = name._embedded['wp:featuredmedia']?.[0]?.source_url
          let dmtagd = name._embedded['wp:term'].map((tags, i) => {
            // console.log('====================================')
            let subtag = tags.map((subtag, i) => {
              tagsDATA.push(subtag.name)
              // console.log(subtag.name)
            })
          })

          //check if image is present
          let img = name.featured_media
          let splicedLink = name.link.replace(
            'https://business.lilypads.com/',
            '',
          )

          if (counterrTT <= 10) {
            if (img === 0 || img === null) {
              return (
                <BLOGINDI
                  link={splicedLink}
                  id={name.id}
                  index={i}
                  img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                  title={name.title.rendered}
                  content={name.excerpt.rendered}
                  pub={datePubFINAL}
                  author={name._embedded.author[0].name}
                  tags={tagsDATA}
                />
              )
            }
            return (
              <BLOGINDI
                link={splicedLink}
                id={name.id}
                index={i}
                // img={name.better_featured_image.source_url}
                img={imgT}
                title={name.title.rendered}
                content={name.excerpt.rendered}
                pub={datePubFINAL}
                author={name._embedded.author[0].name}
                tags={tagsDATA}
              />
            )
          }
        }),
      )

      setAllNewsMob(
        res.data.map((name, i) => {
          let imgT
          if (name?._embedded['wp:featuredmedia']?.[0]?.source_url) {
            imgT = name?._embedded['wp:featuredmedia']?.[0]?.source_url
          }

          let img = name.featured_media

          if (img === 0 || img === null) {
            imgT =
              'https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg'
          }

          counterrT++
          if (counterrT % 2 == 0) {
            return (
              <BLOGINDI
                id={name.id}
                // link={splicedLink}
                index={i}
                img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                title={name.title.rendered}
                content={name.excerpt.rendered}
                // pub={datePubFINAL}
                author={name._embedded.author[0].name}
                // tags={tagsDATA}
              />
            )
          } else {
            return (
              <BLOGINDI
                id={name.id}
                // link={splicedLink}
                index={i}
                img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                title={name.title.rendered}
                content={name.excerpt.rendered}
                // pub={datePubFINAL}
                author={name._embedded.author[0].name}
                // tags={tagsDATA}
              />
            )
          }
        }),
      )
    }
    getData()
    axios
      .get('https://business.lilypads.com/wp-json/wp/v2/categories')
      .then((res) => {
        console.log(res)
        let totalItems = 0

        let tData = []
        const atags = res.data.map((tag, i) => {
          tData.push(tag.name)
        })
        setAllTags(tData)
        let mapr = res.data.map((cat, i) => {
          totalItems = cat.count + totalItems
        })
        console.log(totalItems)
        // match res by id then set the data
      })
      .catch(function (error) {
        console.log(error)

        // alert('Failed toget Details')
      })
  }, [])

  useEffect(() => {
    const url = `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&_embed`
    async function handleSearchD() {
      // console.log('search started for ' + searchT)
      // const res = await axios.get(url)
      // console.log(fliteringBlogs)

      let result = fliteringBlogs.filter((user) => {
        return (
          user.title.rendered.toLowerCase().indexOf(searchT.toLowerCase()) >= 0
        )
      })

      if (mode === 1) {
        setFilterdBlogs(
          result.map((name, i) => {
            //check if image is present
            const tagsDATA = []
            let datePub = name.date
            let imgT
            try {
              imgT = name?._embedded['wp:featuredmedia'][0]?.source_url
            } catch (err) {
              imgT =
                'https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg'
            }

            // let imgT = "as"
            let datePubFINAL = moment(datePub).fromNow()
            let dmtagd = name._embedded['wp:term'].map((tags, i) => {
              // console.log('====================================')
              let subtag = tags.map((subtag, i) => {
                tagsDATA.push(subtag.name)
                // console.log(subtag.name)
              })
              // if (!tagsDATA.includes(searchT)) {
              //   return
              // }
            })

            let img = name.featured_media
            let splicedLink = name.link.replace(
              'https://business.lilypads.com/',
              '',
            )
            if (img === 0 || img === null) {
              return (
                <BLOGINDI
                  id={name.id}
                  link={splicedLink}
                  index={i}
                  img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                  title={name.title.rendered}
                  content={name.excerpt.rendered}
                  pub={datePubFINAL}
                  author={name._embedded.author[0].name}
                  tags={tagsDATA}
                />
              )
            }
            return (
              <BLOGINDI
                id={name.id}
                link={splicedLink}
                index={i}
                // img={name.better_featured_image.source_url}
                img={imgT}
                title={name.title.rendered}
                content={name.excerpt.rendered}
                pub={datePubFINAL}
                author={name._embedded.author[0].name}
                tags={tagsDATA}
              />
            )
          }),
        )
      }

      if (mode === 2) {
        setFilterdBlogs(
          fliteringBlogs.map((name, i) => {
            //check if image is present
            const tagsDATA = []
            let datePub = name.date
            let imgT =
              'https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg'
            // if (!name?._embedded['wp:featuredmedia'][0] === null) {
            // }
            // check if img present
            //get length of wp featured media
            // let imgLength = name?._embedded['wp:featuredmedia'].length
            // if (imgLength > 0) {
            if (name?._embedded['wp:featuredmedia']) {
              imgT = name?._embedded['wp:featuredmedia'][0]?.source_url
            }

            // imgT = name?._embedded['wp:featuredmedia'][0]?.source_url
            let datePubFINAL = moment(datePub).fromNow()
            let dmtagd = name._embedded['wp:term'].map((tags, i) => {
              // console.log('====================================')
              let subtag = tags.map((subtag, i) => {
                tagsDATA.push(subtag.name)
                // console.log(subtag.name)
              })
              // if (!tagsDATA.includes(searchT)) {
              //   return
              // }
            })

            if (tagsDATA.includes(searchT)) {
              let img = name.featured_media
              let splicedLink = name.link.replace(
                'https://business.lilypads.com/',
                '',
              )
              if (img === 0 || img === null) {
                return (
                  <BLOGINDI
                    id={name.id}
                    link={splicedLink}
                    index={i}
                    img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                    title={name.title.rendered}
                    content={name.excerpt.rendered}
                    pub={datePubFINAL}
                    author={name._embedded.author[0].name}
                    tags={tagsDATA}
                  />
                )
              }
              return (
                <BLOGINDI
                  id={name.id}
                  link={splicedLink}
                  index={i}
                  // img={name.better_featured_image.source_url}
                  img={imgT}
                  // img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"

                  title={name.title.rendered}
                  content={name.excerpt.rendered}
                  pub={datePubFINAL}
                  author={name._embedded.author[0].name}
                  tags={tagsDATA}
                />
              )
            }
          }),
        )
      }

      console.log(result)
      console.log(mode)
    }
    handleSearchD()
  }, [searchT])
  //Get current post

  // const indexOfLastPost = currentPage * postPerPage
  // const indexOfFirstPost = indexOfLastPost - postPerPage
  // const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)

  useEffect(() => {
    // console.log(data.selected + 1)
  }, [])

  const handlePageClick = (data) => {
    let cd = parseInt(data.selected)
    // console.log(data.selected + 1 *10)
    // console.log(cd *10);

    const indexOfLastPost = (cd + 1) * postPerPage
    const indexOfFirstPost = indexOfLastPost - postPerPage
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost)
    console.log(indexOfFirstPost + ' ' + indexOfLastPost)
    let counterrTT = 0
    setPostOnCur(
      currentPosts.map((name, i) => {
        let datePub = name.date
        let datePubFINAL = moment(datePub).fromNow()
        const tagsDATA = []
        let imgT = name._embedded['wp:featuredmedia']?.[0]?.source_url
        let dmtagd = name._embedded['wp:term'].map((tags, i) => {
          // console.log('====================================')
          let subtag = tags.map((subtag, i) => {
            tagsDATA.push(subtag.name)
            // console.log(subtag.name)
          })
        })

        //check if image is present
        let img = name.featured_media
        let splicedLink = name.link.replace(
          'https://business.lilypads.com/',
          '',
        )
        counterrTT++
        if (counterrTT % 2 == 0) {
          if (img === 0 || img === null) {
            return (
              <BLOGINDI
                link={splicedLink}
                id={name.id}
                index={i}
                img="https://img.wallpapersafari.com/desktop/1024/576/69/20/FgHujO.jpg"
                title={name.title.rendered}
                content={name.excerpt.rendered}
                pub={datePubFINAL}
                author={name._embedded.author[0].name}
                tags={tagsDATA}
              />
            )
          }
          return (
            <BLOGINDI
              link={splicedLink}
              id={name.id}
              index={i}
              // img={name.better_featured_image.source_url}
              img={imgT}
              title={name.title.rendered}
              content={name.excerpt.rendered}
              pub={datePubFINAL}
              author={name._embedded.author[0].name}
              tags={tagsDATA}
            />
          )
        }
      }),
    )
  }
  const pageCount = Math.ceil(posts.length / postPerPage)
  return (
    <div className="newsList">
      <Header active="6" />
      <div className="newsListWrapper">
        {loading ? (
          <>
            <div className="OneRow">
              <div data-aos="fade-up" className="searchBOX nlw">
                {/* <span>Search</span> */}
                <div className="inputHolder">
                  <input
                    placeholder="Search for Job title or Skill"
                    value={searchT}
                    onChange={(e) => {
                      // setSearchMode(1)
                      setMode(1)
                      setSearchT(e.target.value)
                    }}
                  />
                  {/* <img src={sImg} alt="" /> */}
                  <div className="SearchBtn">
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.2635 20.5271C12.5407 20.5266 14.7523 19.7643 16.5461 18.3615L22.1859 24.0013L24 22.1872L18.3602 16.5474C19.7637 14.7534 20.5265 12.5413 20.5271 10.2635C20.5271 4.60448 15.9226 0 10.2635 0C4.60448 0 0 4.60448 0 10.2635C0 15.9226 4.60448 20.5271 10.2635 20.5271ZM10.2635 2.56588C14.5088 2.56588 17.9612 6.01828 17.9612 10.2635C17.9612 14.5088 14.5088 17.9612 10.2635 17.9612C6.01828 17.9612 2.56588 14.5088 2.56588 10.2635C2.56588 6.01828 6.01828 2.56588 10.2635 2.56588Z"
                        fill="#FFFFFF"
                      />
                      <path
                        d="M12.0738 8.4496C12.5601 8.93712 12.8282 9.58116 12.8282 10.2637H15.3941C15.3952 9.5894 15.2627 8.92159 15.0042 8.29884C14.7456 7.67609 14.3662 7.11075 13.8879 6.63552C11.9455 4.69571 8.57781 4.69571 6.63672 6.63552L8.44823 8.45217C9.42327 7.4797 11.1039 7.48226 12.0738 8.4496Z"
                        fill="#FFFFFF"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="blogBtmWRAP">
                {searchT === '' ? (
                  <>
                    <div className="leftCon wpagni">
                      <div className="allblogWrapperMain">{postOnCur}</div>
                      <ReactPaginate
                        onPageChange={handlePageClick}
                        containerClassName={'paginationOwn'}
                        previousLinkClassName={'pprevlcc'}
                        //  pageRangeDisplayed={5}
                        // renderOnZeroPageCount={null}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        breakLabel="..."
                        nextLabel=" >>"
                        previousLabel="<<"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="leftCon wpagni">
                      <div className="allblogWrapperMain">{filterdBlogs}</div>
                    </div>
                  </>
                )}
                <div className="rightFilter">
                  <div className="filter-card">
                    <p className="d-flex align-items-center mb-4">
                      <svg
                        style={{ marginRight: '20px' }}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_5405_4250)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3 17.6552H21C21.4774 17.6552 21.9352 17.4699 22.2728 17.1401C22.6104 16.8103 22.8 16.363 22.8 15.8966V4.75862C22.8 4.29221 22.6104 3.84488 22.2728 3.51508C21.9352 3.18527 21.4774 3 21 3H3C2.52261 3 2.06476 3.18527 1.7272 3.51508C1.38963 3.84488 1.2 4.29221 1.2 4.75862V15.8966C1.2 16.363 1.38963 16.8103 1.7272 17.1401C2.06476 17.4699 2.52261 17.6552 3 17.6552ZM3 4.75862H21V15.8966H3V4.75862ZM0 18.2414V20H24V18.2414H0Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_5405_4250">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>

                      <span className="f-archivo">Location</span>
                    </p>
                    <div className="NewsFilters">
                     
                      {allTags.map((tag, i) => {
                        return (
                          <label
                            onClick={() => {
                              setMode(2)
                              setSearchT(tag)
                            }}
                            htmlFor={tag}
                            className="contact-chip"
                            
                          >
                            <input type="checkbox" name="relate" id={tag} />
                            <span>{tag}</span>
                          </label>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  )
}

export default NewsList

{
  /* <div className="ThreeRow">
          <div className="leftCon">{allNewsL}</div>
          <div className="midCon">{allNewsM}</div>
          <div className="rightCon">{allNewsR}</div>
        </div>
        <div className="TwoRow">
          <div className="leftCon">{allNewsLTAB}</div>
          <div className="rightCon">{allNewsRTAB}</div>
        </div>
        <div className="OneRow">
          <div className="leftCon">{allNewsMob}</div>
        </div> */
}

function BLOGINDI({ title, img, content, index, pub, author, tags, id, link }) {
  const history = useHistory()
  const dispatch = useDispatch()
  return (
    <div
      onClick={() => {
        dispatch(nds(index))
        // history.push('/NewsDetails')
        console.log(link)
        // navigate('/blog')
        history.push('/NewsDetails')
        // navigate(`/${link}`)
      }}
      className="solo-BLOGCON"
    >
      <img src={img} alt="" />
      <div className="BLOG-Tags">
        {tags.map((tag, i) => {
          return <p>{tag}</p>
        })}

        {/* <p className="font-lexend">Real estate{tags}</p>
        <p className="font-lexend">Investment</p>
        <p className="font-lexend">Commercial</p> */}
      </div>
      <p className="tp">{title}</p>

      <div
        className="BLOG-content"
        dangerouslySetInnerHTML={{
          __html: `${content}`,
        }}
      ></div>
      <div className="Blog-BOTM">
        <p className="lp">{author} </p>
        <p className="rp">{pub} </p>
      </div>
    </div>
  )
}
