import { useState } from "react";
import { Tab, Nav, Container } from "react-bootstrap";
import "./Solutions.css";

export default function Solutions() {
  const [activeSolTab, setActiveSolTab] = useState("first");

  const tabHandler = (tab) => {
    setActiveSolTab(tab);
  };

  return (
    <section className="solutions-section section-spacing-top section-spacing-bottom">
      <Container />
      <div className="solutions-inner">
        <div className="sols-intro">
          <h3 className="intro-title f-archivo">
            <span className="text-prim-blue">White-Label</span> Solutions
          </h3>
          <p className="lined-subtitle">
            Providing an enterprise-grade infrastructure and toolset to manage
            assets, improve liquidity, automate workflows, and generate cash
            flow.
          </p>
        </div>

        <div className="sols-tab-wrap">
          <Tab.Container defaultActiveKey={"first"} activeKey={activeSolTab}>
            <div className="sols-tab-inner">
              <div className="sols-tab-content">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="sol-number">01</div>
                    <div className="sol-card-wrap">
                      <div className="sol-card">
                        <h4 className="text-prim-blue f-archivo ">
                          Multi Signature Wallet
                        </h4>

                        <p>
                          Maximize the profitability of your digital assets and indulge in a seamless and innovative experience with our state-of-the-art multi signature wallet solution. Our cutting-edge technology ensures safeguarding of your valuable assets along with boosting the user experience to unprecedented heights.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="sol-number">02</div>
                    <div className="sol-card-wrap">
                      <div className="sol-card">
                        <h4 className="text-prim-blue f-archivo ">
                        Tokenization
                        </h4>

                        <p>
                        Unlock the full potential of your security tokens with our groundbreaking tokenization platform built with a multi-layered security system and a robust workflow authorization policy engine.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <div className="sol-number">03</div>
                    <div className="sol-card-wrap">
                      <div className="sol-card">
                        <h4 className="text-prim-blue f-archivo ">Securix</h4>

                        <p>
                        Enterprise grade security solutions that protect your assets and investments from potential security breaches and unwanted manual interventions by utilizing latest cryptographic encryption standards, state-of-the-art hardware isolations, and PBFT consensus algorithms.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <div className="sol-number">04</div>
                    <div className="sol-card-wrap">
                      <div className="sol-card">
                        <h4 className="text-prim-blue f-archivo ">
                        Blue Block Network
                        </h4>

                        <p>
                        Exclusive network for investors, financial actors, and owner operators for automated settlement workflow and hassle-free transactions. Revolutionize your financial operations with our all-in-one platform, designed to settle, rebalance, and pay instantly eliminating the need of multiple platforms.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <div className="sol-number">05</div>
                    <div className="sol-card-wrap">
                      <div className="sol-card">
                        <h4 className="text-prim-blue f-archivo ">
                        KYC and AML Policy Engine
                        </h4>

                        <p>
                        Empower your business with our fully customizable and configurable access control policy engine, designed to protect your funds and assets against insider threats while simplifying your operations and governance.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    <div className="sol-number">06</div>
                    <div className="sol-card-wrap">
                      <div className="sol-card">
                        <h4 className="text-prim-blue f-archivo ">
                          Token Engine
                        </h4>

                        <p>
                          Deploy, mint, burn, and transfer Trust Tokens
                          (security tokens) while effectively managing the daily
                          token operations with our highly secured and robust
                          tokenization engine.
                        </p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
              <div className="sols-tab-nav">
                <Nav variant="pills" className="flex-md-column">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onMouseEnter={() => tabHandler("first")}
                      className="f-archivo"
                    >
                       Multi Signature Wallet
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onMouseEnter={() => tabHandler("second")}
                      className="f-archivo"
                    >
                      Tokenization
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="third"
                      onMouseEnter={() => tabHandler("third")}
                      className="f-archivo"
                    >
                      Securix
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fourth"
                      onMouseEnter={() => tabHandler("fourth")}
                      className="f-archivo"
                    >
                      Blue Block Network
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fifth"
                      onMouseEnter={() => tabHandler("fifth")}
                      className="f-archivo"
                    >
                      KYC and AML Policy Engine
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link
                      eventKey="sixth"
                      onMouseEnter={() => tabHandler("sixth")}
                      className="f-archivo"
                    >
                      Token Engine
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
}
