import React, { useState, useEffect } from 'react'
import './NSecOneAP.scss'
import Header from './../../Header'
import logoL from './../../../assets/sec5Lilypads.svg'
import rb from './../../../assets/rb.png'
import tb from './../../../assets/tb.png'
import rArrow from './../../../assets/LP/rArrow.svg'
import si1 from './../../../assets/ApproachPage/new/HOME.png'
import si2 from './../../../assets/ApproachPage/new/HOME2.png'
import vn from './../../../assets/ApproachPage/new/vn.png'
import vnt from './../../../assets/ApproachPage/new/vnt.png'
import vnm from './../../../assets/ApproachPage/new/vnm.png'
import { TweenMax, TimelineLite, Power3, Power0 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'
gsap.registerPlugin(ScrollTrigger)

const NSecOneAP = () => {
  const [posi, setPosi] = useState(false)

  function leftA(e) {
    e.preventDefault()

    setPosi(false)
  }
  function rightA(e) {
    e.preventDefault()
    setPosi(true)
  }

  let tl = new TimelineLite({ delay: 0.4 })
  useEffect(() => {
    window.scrollTo(0, 0)
    tl.from(
      '.MainSI1   ',
      1.5,
      { scale: 1.2, opacity: 0, ease: Power3.easeOut },
      'Start',
    )
      .from(
        '.NSecOneAPMainWL .Limg',
        1.5,
        { y: '100px', ease: Power3.easeOut },
        0.1,
      )
      .from(
        '.NSecOneAPMainWL h3',
        1.5,
        { y: '50px', ease: Power3.easeOut },
        0.5,
      )
      .from(
        '.NSecOneAPMainWL h2',
        1.5,
        { y: '190px', ease: Power3.easeOut },
        0.5,
      )
      .from(
        '.NSecOneAPMainWL .topP',
        1.5,
        { y: '150px', ease: Power3.easeOut },
        0.7,
      )
      .from(' .rbCon .p1', 1.5, { y: '150px', ease: Power3.easeOut }, 0.7)
      .from(' .rbCon .p2', 1.5, { y: '150px', ease: Power3.easeOut }, 0.9)
      .from(' .rbCon .p3', 1.5, { y: '150px', ease: Power3.easeOut }, 1.1)
      .from(' .rbCon .p4', 1.5, { y: '150px', ease: Power3.easeOut }, 1.3)
      .from(
        ' .aBCon button',
        1.5,
        { opacity: 0, y: '150px', ease: Power3.easeOut },
        1.4,
      )
  }, [])

  return (
    <div className="NSecOneAPMain">
      <Header active="4" />

      <div className="NSecOneAPMainW">
        <div className="NSecOneAPMainWL">
          <div style={{ overflow: 'hidden' }} className="ofhcon">
            <img className="Limg" src={logoL} alt="" />
          </div>
          <div style={{ overflow: 'hidden' }} className="ofhcon">
            <h3>Meet Lilypads by Cognier Technology Group</h3>
          </div>
          <div style={{ overflow: 'hidden' }} className="ofhcon">
            <h2>
              Unprecedented era of <span>real estate</span>{' '}
            </h2>
          </div>
          <div style={{ overflow: 'hidden' }} className="ofhcon">
            <p className="topP">
              Our innovative business platform provides real estate opportunities that
              were previously difficult to access for the individual investor.
            </p>
          </div>
          <div className="rbCon tm">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <img className="p1" src={rb} alt="" />
            </div>
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <p className="p1">Become a Data-Driven Investor</p>
            </div>
          </div>
          <div className="rbCon">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <img className="p2" src={rb} alt="" />
            </div>
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <p className="p2">
                Lilypads can help you make smarter decisions.
              </p>
            </div>
          </div>
          <div className="rbCon">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <img className="p3" src={rb} alt="" />
            </div>
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <p className="p3">Manager Due Diligence</p>
            </div>
          </div>
          <div className="rbCon">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <img className="p4" src={rb} alt="" />
            </div>
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <p className="p4">Optimize Asset Allocation</p>
            </div>
          </div>
          <div style={{ overflow: 'hidden' }} className="aBCon">
            <button onClick={()=> window.open("https://cogniertechnology.com/contact", "_blank")} className="MainGetStartedBTN notransiton">
              Request Demo
            </button>
            <button  onClick={()=> window.open("https://lilypads.com/", "_blank")} className="MainGetStartedBTN bt2 notransiton">
              Visit Lilypads
            </button>
          </div>
        </div>
        <div className="NSecOneAPMainWR">
          <img className="tb" src={tb} alt="" />

          <div className="rMcon">
            <div className={'MainS  ' + (posi && ' active ')}>
              <img className="MainSI1" src={si1} alt="" />
              <img className="MainSI2" src={si2} alt="" />
            </div>
            <div className="btmC">
              <div className="BtnCons6n">
                <img onClick={leftA} className="aLimg" src={rArrow} alt="" />
                <img onClick={rightA} className="aRimg" src={rArrow} alt="" />
              </div>
              <div className={'PosiScrollI  ' + (posi && ' active ')}>
                <div className="PosiScrollIinside"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobIMGB">
          <img src={si1} alt="" />
        </div>
      </div>
      <img data-aos="fade-up" className="vnI pc" src={vn} alt="" />
      <img data-aos="fade-up" className="vnI tab" src={vnt} alt="" />
      <img data-aos="fade-up" className="vnI mob" src={vnm} alt="" />
    </div>
  )
}

export default NSecOneAP
