import React, { useState, createRef } from 'react'

import './SecFiveWho.scss'
import ReactMapGL, { Marker } from 'react-map-gl'
import mapMarker from './../../assets/mapMarker.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const SecFiveWho = () => {
  let [viewport, setViewport] = useState({
    latitude: 39.19485773888919,
    longitude: -76.79227931507276,
    zoom: 14,
    width: window.innerWidth,
    height: '50vh',
  })

  return (
    // <div ref={divRef} className="SectionFiveWho">
    <div className="SectionFiveWho">
      <iframe
        className="snazzyMapF"
        src="https://snazzymaps.com/embed/340332"
        frameborder="0"
      ></iframe>
      <div className="contentBox">
        <h3>Address</h3>
        <h4>6751 Columbia Gateway Dr., 3rd floor, Columbia, MD 21046</h4>
      </div>
    </div>
  )
}

export default SecFiveWho
