import React from 'react'
import SecOneApplication from '../components/Application/SecOneApplication'
import Footer from '../components/SectionsLP/Footer';

const ApplicationForm = () => {
    return (
        <div className="ApplicationFormMain">
            
            <SecOneApplication/>
            {/* <Footer/> */}
        </div>
    )
}

export default ApplicationForm
