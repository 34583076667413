import React, { useRef, useState, useEffect } from "react";
import "./NSecFourAP.scss";
import c1 from "./../../../assets/ApproachPage/new/secFour/c1.png";
import c2 from "./../../../assets/ApproachPage/new/secFour/c2.png";
import c3 from "./../../../assets/ApproachPage/new/secFour/c3.png";
import c4 from "./../../../assets/ApproachPage/new/secFour/c4.png";
import mi from "./../../../assets/ApproachPage/new/secFour/mi.png";
import bg from "./../../../assets/ApproachPage/new/secFour/bg.png";
import bgm from "./../../../assets/ApproachPage/new/secFour/bgm.png";
import axios from "axios";
import { Button, Popover, Typography } from "react-bootstrap";
const NSecFourAP = (props) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [Notificationmsg, setNotificationmsg] = useState("");
  
  const [SelectShow, setSelectShow] = useState(false)

  const [Selected, setSelected] = useState("")

  const handleSelectOption = (e) => {
    setSelected(e.currentTarget.textContent)
    setSelectShow(false)
  }

  function handleSubmit(e) {
    e.preventDefault();
    const config = {
      headers: {
        "X-Api-Key": "DJJA0xkf.U5rjiiwJgRRVpD9FFUWkBGIHse2A0uxH",
      },
    };

    const body = new FormData();
    body.append("name", fullName);
    body.append("company", company);
    body.append("email", email);
    body.append("contact_number", phoneNo);
    body.append("message", message);
    body.append("business_sector", Selected);

    const url = `https://api.cogniertechnology.com/api/contacts/`;

    axios
      .post(url, body, config)
      .then((response) => {
        setFullName("");
        setEmail("");
        setPhoneNo("");
        setCompany("");
        setMessage("");
        setSelected("")
        setNotificationmsg("Form is successfully submitted");
        setTimeout(() => {
          setNotificationmsg("");
        }, 3000);
        // alert("successfully submitted contact");
      })
      .catch((error) => {
        alert("Fail to submit");
        console.log(error.response);
      });
  }



  return (
    <div className="footer-top-new">
      <div className="footer-top-new-l">
        <h1>Solutions Tailored To Your Needs</h1>
        <h2>
          Build a customized multi-asset marketplace by utilizing our Whitelabel
          products and solutions that will help your business reach a new level
          by automating workflow, improving liquidity, and increasing
          efficiency.
        </h2>
        <h3>
          Visit Lilypads{" "}
          <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_5378_14130)">
              <path
                d="M16.5657 7.93661L7 17.4998M17 12.7182L16.5672 7.93661L11.7836 7.50195"
                stroke="#219EBC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_5378_14130"
                x="-4"
                y="-3.5"
                width="40"
                height="40"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.129412 0 0 0 0 0.619608 0 0 0 0 0.737255 0 0 0 0.12 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_5378_14130"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_5378_14130"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        </h3>
      </div>
      <div className="footer-top-new-r">
        <div className="SecOneContactMainWrapper">
          <div className="rightContact">
            <form onSubmit={handleSubmit}>
              <div className="formItemCon">
                <span>Full Name</span>
                <input
                  required
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>

             

              <div className="formItemCon">
                <span>Company (optional)</span>
                <input
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>


              
           
              <div className="formItemCon">
                <span>Email</span>
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="formItemCon">
                <span>PhoneNo</span>
                <input
                  required
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                />
              </div>

              <div className="formItemCon ">
                <span>Business Sector</span>
                <div onClick={() => setSelectShow(!SelectShow)} className="SelectButton">{Selected ? Selected : "Select"}</div>
                {
                  SelectShow && 
                  <div className="List">
                    <div onClick={handleSelectOption} className="item" >Alternative Asset Management</div>
                    {/* <div  onClick={handleSelectOption} className="item" >Crypto Hedge Fund</div> */}
                    <div  onClick={handleSelectOption} className="item" >Traditional Asset Management</div>
                    <div  onClick={handleSelectOption} className="item" >Exchange</div>
                    <div  onClick={handleSelectOption} className="item" >Payment</div>
                    <div  onClick={handleSelectOption} className="item" >Bank</div>
                    <div  onClick={handleSelectOption} className="item" >Custodian</div>
                    <div  onClick={handleSelectOption} className="item" >Challenger Bank</div>
                    <div  onClick={handleSelectOption} className="item" >BaaS</div>
                    <div  onClick={handleSelectOption} className="item" >OTC Desk</div>
                    <div  onClick={handleSelectOption} className="item" >Prop Trader</div>
                    <div  onClick={handleSelectOption} className="item" >Market Maker/liquidity Provider</div>
                    {/* <div  onClick={handleSelectOption} className="item" >NFT</div> */}
                    {/* <div  onClick={handleSelectOption} className="item" >Gaming</div> */}
                    <div  onClick={handleSelectOption} className="item" >Landing Desk</div>
                    <div  onClick={handleSelectOption} className="item" >Fund Manager</div>
                    <div  onClick={handleSelectOption} className="item" >Fintech</div>
                    <div  onClick={handleSelectOption} className="item" >Other</div>
                    {/* <div  onClick={handleSelectOption} className="item" >Desk</div>
                    <div  onClick={handleSelectOption} className="item" >Web3</div> */}
                  </div>
                }
                
              </div>
            
              
              <div className="formItemCon messageCon">
                <span>Message</span>
                <textarea
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
             
              <div className="SubmitButton">
              <button type="submit">Submit</button>
              </div>
          
            </form>
            <div>{Notificationmsg}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NSecFourAP;
