import React from "react";
import "./SecEight.scss";
import Sec8blur from "./../../assets/Sec8Blur.svg";
import { FiArrowRight } from "react-icons/fi";
import { TextField, makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  try: {
    color: "white",
  },
});

export default function SecEight() {
  const classes = useStyles();
  return (
    <div className="section8">
      <div className="top">
        <div className="appointmentWrapper">
          <div className="innertop">
            <h2>Schedule an Appointment</h2>
            <div className="getStartedCon">
              <span>Submit</span>
              <FiArrowRight className="fiar" />
            </div>
          </div>
          <div className="innerbottom">
            <span className="mspan">
              Hey,my name is
           
              <div className="tiform">
                <input type="text" name="name" required autocomplete="off" />
                <label for="name" className="label-name">
                  <span className="content-name">Type Here</span>
                </label>
              </div>
              and I'm looking for{"  "}
              {/* <input type="text" placeholder="Select Dropdown" /> */}
              <div className="tiform">
                <input type="text" name="name" required autocomplete="off" />
                <label for="name" className="label-name">
                  <span className="content-name">Select Dropdown</span>
                </label>
              </div>
            </span>

            <br />
            <span className="mspan">
              Get in touch with me at{" "}
              {/* <input type="text" placeholder="Enter your Email" /> */}
              <div className="tiform">
                <input type="text" name="name" required autocomplete="off" />
                <label for="name" className="label-name">
                  <span className="content-name">Enter Email</span>
                </label>
              </div>
            </span>

            <br />
            <span className="spanCondi">
              <input type="checkbox" />I agree to the terms and condition
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
