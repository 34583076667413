import SecOneAP from './../components/SectionApproach/SecOneAP'
import SecTwoAP from './../components/SectionApproach/SecTwoAP'
import SecThreeAP from './../components/SectionApproach/SecThreeAP'
import SecFourAP from './../components/SectionApproach/SecFourAP'
import SecEight from './../components/SectionsLP/SecEight'
import Footer from './../components/SectionsLP/Footer'
import { useEffect } from 'react'
import SecSeven from './../components/SectionsLP/SecSeven/SecSeven'
import NSecOneAP from './../components/SectionApproach/new/NSecOneAP'
import NSecTwoAP from './../components/SectionApproach/new/NSecTwoAP';
import NSecThreeAP from './../components/SectionApproach/new/NSecThreeAP';
import NSecFourAP from './../components/SectionWhat/newWhat/NSecFourAP';

const ApproachPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <NSecOneAP />
      <NSecTwoAP />
      {/* <NSecThreeAP /> */}
      {/* <NSecFourAP /> */}

      {/* <SecOneAP/> */}
      {/* <SecTwoAP/> */}
      {/* <SecThreeAP/> */}
      {/* <SecFourAP/> */}
      {/* <SecEight/> */}
      {/* <SecSeven/> */}
      <Footer/>
    </div>
  )
}

export default ApproachPage
