export const current = (data) => {
    return {
        type: "CURRENT",
        payload: data,
    };
};

const initianstate = {
    current_page: "WithoutLine",
}

const currentPageReducer = (state = initianstate, action) => {
    switch (action.type) {
        case "CURRENT":
            return {
                current_page: action.payload
            }
        default:
            return state;
    }
}

export const fetchCurrentPage = (data) => async (dispatch, getState) => {

        dispatch(current(data))

}

export default currentPageReducer