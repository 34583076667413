import React from "react";
import { MultiText } from "../../components/Utils";
import phoneL from "./../../assets/newAssets/phoneL.png";
import phoneR from "./../../assets/newAssets/phoneR.png";

const OurProductMobile = () => {
  return (
    <div className="OurProductMobileCon">
      <div className="head">Our Product</div>
      <div className="image">
        <img className="main" src={phoneR} />
        <img className="side" src={phoneL} />
      </div>
      <div className="det">
        <svg
          width="95"
          height="57"
          viewBox="0 0 95 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M94.3614 42.9504C89.0692 49.821 81.4209 54.7283 72.4484 56.1819C63.2231 57.6766 54.2857 55.2467 47.1719 50.0802C52.464 43.2096 60.1144 38.3023 69.0848 36.8487C78.3081 35.3563 87.2476 37.784 94.3614 42.9504Z"
            fill="#322D76"
          />
          <path
            d="M94.3614 42.9504C89.0692 49.821 81.4209 54.7283 72.4484 56.1819C63.2231 57.6766 54.2857 55.2467 47.1719 50.0802C52.464 43.2096 60.1144 38.3023 69.0848 36.8487C78.3081 35.3563 87.2476 37.784 94.3614 42.9504Z"
          //   stroke="white"
          />
          <path
            d="M0 42.8268C6.93286 37.8201 15.5928 35.3989 24.5899 36.684C33.8398 38.0059 41.7514 43.0039 47.1731 50.0818C40.2402 55.0885 31.5782 57.5097 22.5832 56.2246C13.3373 54.9005 5.42375 49.9026 0 42.8268Z"
            fill="#322D76"
          />
          <path d="M0 42.8268C6.93286 37.8201 15.5928 35.3989 24.5899 36.684C33.8398 38.0059 41.7514 43.0039 47.1731 50.0818C40.2402 55.0885 31.5782 57.5097 22.5832 56.2246C13.3373 54.9005 5.42375 49.9026 0 42.8268Z" />
          <g opacity="0.8">
            <path
              d="M78.5343 12.3594C78.7851 21.1955 76.028 30.1525 70.1252 37.399C64.0559 44.8484 55.7702 49.1272 47.1719 50.0819C46.9211 41.2457 49.6782 32.2887 55.581 25.0423C61.6483 17.5928 69.934 13.3119 78.5343 12.3594Z"
              fill="#A6244B"
            />
            <path d="M78.5343 12.3594C78.7851 21.1955 76.028 30.1525 70.1252 37.399C64.0559 44.8484 55.7702 49.1272 47.1719 50.0819C46.9211 41.2457 49.6782 32.2887 55.581 25.0423C61.6483 17.5928 69.934 13.3119 78.5343 12.3594Z" />
          </g>
          <g opacity="0.8">
            <path
              d="M16.3477 11.8672C24.7013 12.9277 32.7197 17.1265 38.6595 24.3405C44.7659 31.7576 47.5559 41.0019 47.1694 50.0778C38.8158 49.0173 30.7953 44.8163 24.8576 37.6044C18.7512 30.1895 15.9612 20.943 16.3477 11.8672Z"
              fill="#A6244B"
            />
            <path d="M16.3477 11.8672C24.7013 12.9277 32.7197 17.1265 38.6595 24.3405C44.7659 31.7576 47.5559 41.0019 47.1694 50.0778C38.8158 49.0173 30.7953 44.8163 24.8576 37.6044C18.7512 30.1895 15.9612 20.943 16.3477 11.8672Z" />
          </g>
          <path
            d="M47.6538 0C53.3119 6.54231 56.7619 15.2294 56.7619 24.7675C56.7619 34.5733 53.1145 43.4829 47.1706 50.0814C41.5125 43.5391 38.0625 34.8498 38.0625 25.3139C38.0625 15.5102 41.7099 6.59846 47.6538 0Z"
            fill="#765EA8"
          />
          <path d="M47.6538 0C53.3119 6.54231 56.7619 15.2294 56.7619 24.7675C56.7619 34.5733 53.1145 43.4829 47.1706 50.0814C41.5125 43.5391 38.0625 34.8498 38.0625 25.3139C38.0625 15.5102 41.7099 6.59846 47.6538 0Z" />
        </svg>
        <h1>Lilypads</h1>
        <p>
          Lilypads provides a SaaS based compliance infrastructure for management
          and rapid settlement by eliminating complexity, cost and liquidity from
          commercial realestaste Investment transaction
        </p>
      </div>
      <a href="https://lilypads.com/" target="_blank" className=" link websvg">

        Visit Website
        <span>
          <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.172 6.53574L6.808 1.36346L8.222 0L16 7.5L8.222 15L6.808 13.6365L12.172 8.46426H0V6.53574H12.172Z"
              fill="#219EBC"
            />
          </svg>
        </span>

      </a>
    </div>
  );
};

export default OurProductMobile;
