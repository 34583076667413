import React from "react";
import { MultiText } from "../../components/Utils";
import phoneL from "./../../assets/newAssets/phoneL.png";
import phoneR from "./../../assets/newAssets/phoneR.png";

const OurProduct = () => {
  return (
    <div className="OurProduct-main">
      
      <div className="OurProduct-L">
        <div className="spanT">
          <MultiText h1=" Our " span="Product" h1Size="48px" boxSize="150px" />
        </div>
        <svg
          width="560"
          height="429"
          viewBox="0 0 560 429"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M368.072 279.444C437.574 268.198 504.944 286.414 558.627 325.243C518.648 376.948 460.985 413.857 393.375 424.811C323.858 436.074 256.502 417.841 202.82 379.012C242.798 327.307 300.477 290.398 368.072 279.444Z"
            fill="#002133"
            stroke="url(#paint0_linear_5268_3510)"
          />
          <path
            d="M16.0272 425.121C-53.648 415.144 -113.314 377.55 -154.289 324.298C-101.976 286.673 -36.7163 268.505 31.0764 278.188C100.783 288.149 160.433 325.743 201.393 379.011C149.08 416.636 83.8044 434.805 16.0272 425.121Z"
            fill="#002133"
            stroke="url(#paint1_linear_5268_3510)"
          />
          <path
            opacity="0.8"
            d="M440.034 93.5483L440.018 93.0058L439.479 93.0655C374.245 100.29 311.401 132.762 265.388 189.257C220.623 244.212 199.717 312.136 201.619 379.139L201.634 379.681L202.174 379.621C267.392 372.38 330.235 339.925 376.264 283.43L375.877 283.114L376.264 283.43C421.029 228.475 441.936 160.551 440.034 93.5483Z"
            fill="#002133"
            stroke="url(#paint2_linear_5268_3510)"
          />
          <path
            opacity="0.8"
            d="M33.5727 284.367C-12.4476 228.484 -33.5432 158.832 -30.7563 90.4167C32.1609 98.5409 92.5277 130.238 137.283 184.594C183.303 240.492 204.399 310.129 201.612 378.543C138.695 370.419 78.3123 338.706 33.5728 284.367L33.1868 284.684L33.5727 284.367Z"
            fill="#002133"
            stroke="url(#paint3_linear_5268_3510)"
          />
          <path
            d="M133.664 191.628C133.664 117.747 161.073 50.5744 205.763 0.75641C248.298 50.1431 274.22 115.618 274.22 187.491C274.22 261.389 246.811 328.545 202.121 378.363C159.586 328.976 133.664 263.485 133.664 191.628Z"
            fill="#002133"
            stroke="url(#paint4_linear_5268_3510)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_5268_3510"
              x1="202.109"
              y1="275.66"
              x2="222.34"
              y2="464.671"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_5268_3510"
              x1="-155"
              y1="275.117"
              x2="-134.728"
              y2="464.289"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_5268_3510"
              x1="202"
              y1="93.5625"
              x2="300.353"
              y2="420.97"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_5268_3510"
              x1="-31.5"
              y1="89.8516"
              x2="70.5493"
              y2="419.867"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_5268_3510"
              x1="133.164"
              y1="0"
              x2="352.25"
              y2="327.209"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#219EBC" />
              <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>

        <img src={phoneL} className="phoneL" alt="" />
      </div>
      
      <div className="OurProduct-M"></div>
      <div className="OurProduct-R">
        
        <div className="mobSpanT">
          <MultiText h1=" Our " span="Product" h1Size="48px" boxSize="450px" />
        </div>

        <img src={phoneR} className="phoneR" alt="" />
        <div className="OurProduct-R-web">
          <a href="https://lilypads.com/" target="_blank" className="websvg">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
                stroke="#219EBC"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 11H20"
                stroke="#219EBC"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5 1C12.6886 3.60144 13.9324 6.97743 14 10.5C13.9324 14.0226 12.6886 17.3986 10.5 20C8.31138 17.3986 7.06759 14.0226 7 10.5C7.06759 6.97743 8.31138 3.60144 10.5 1V1Z"
                stroke="#219EBC"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </a>
          <svg
            width="95"
            height="57"
            viewBox="0 0 95 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M94.3614 42.9504C89.0692 49.821 81.4209 54.7283 72.4484 56.1819C63.2231 57.6766 54.2857 55.2467 47.1719 50.0802C52.464 43.2096 60.1144 38.3023 69.0848 36.8487C78.3081 35.3563 87.2476 37.784 94.3614 42.9504Z"
              fill="#322D76"
            />
            <path
              d="M94.3614 42.9504C89.0692 49.821 81.4209 54.7283 72.4484 56.1819C63.2231 57.6766 54.2857 55.2467 47.1719 50.0802C52.464 43.2096 60.1144 38.3023 69.0848 36.8487C78.3081 35.3563 87.2476 37.784 94.3614 42.9504Z"
              //   stroke="white"
            />
            <path
              d="M0 42.8268C6.93286 37.8201 15.5928 35.3989 24.5899 36.684C33.8398 38.0059 41.7514 43.0039 47.1731 50.0818C40.2402 55.0885 31.5782 57.5097 22.5832 56.2246C13.3373 54.9005 5.42375 49.9026 0 42.8268Z"
              fill="#322D76"
            />
            <path d="M0 42.8268C6.93286 37.8201 15.5928 35.3989 24.5899 36.684C33.8398 38.0059 41.7514 43.0039 47.1731 50.0818C40.2402 55.0885 31.5782 57.5097 22.5832 56.2246C13.3373 54.9005 5.42375 49.9026 0 42.8268Z" />
            <g opacity="0.8">
              <path
                d="M78.5343 12.3594C78.7851 21.1955 76.028 30.1525 70.1252 37.399C64.0559 44.8484 55.7702 49.1272 47.1719 50.0819C46.9211 41.2457 49.6782 32.2887 55.581 25.0423C61.6483 17.5928 69.934 13.3119 78.5343 12.3594Z"
                fill="#A6244B"
              />
              <path d="M78.5343 12.3594C78.7851 21.1955 76.028 30.1525 70.1252 37.399C64.0559 44.8484 55.7702 49.1272 47.1719 50.0819C46.9211 41.2457 49.6782 32.2887 55.581 25.0423C61.6483 17.5928 69.934 13.3119 78.5343 12.3594Z" />
            </g>
            <g opacity="0.8">
              <path
                d="M16.3477 11.8672C24.7013 12.9277 32.7197 17.1265 38.6595 24.3405C44.7659 31.7576 47.5559 41.0019 47.1694 50.0778C38.8158 49.0173 30.7953 44.8163 24.8576 37.6044C18.7512 30.1895 15.9612 20.943 16.3477 11.8672Z"
                fill="#A6244B"
              />
              <path d="M16.3477 11.8672C24.7013 12.9277 32.7197 17.1265 38.6595 24.3405C44.7659 31.7576 47.5559 41.0019 47.1694 50.0778C38.8158 49.0173 30.7953 44.8163 24.8576 37.6044C18.7512 30.1895 15.9612 20.943 16.3477 11.8672Z" />
            </g>
            <path
              d="M47.6538 0C53.3119 6.54231 56.7619 15.2294 56.7619 24.7675C56.7619 34.5733 53.1145 43.4829 47.1706 50.0814C41.5125 43.5391 38.0625 34.8498 38.0625 25.3139C38.0625 15.5102 41.7099 6.59846 47.6538 0Z"
              fill="#765EA8"
            />
            <path d="M47.6538 0C53.3119 6.54231 56.7619 15.2294 56.7619 24.7675C56.7619 34.5733 53.1145 43.4829 47.1706 50.0814C41.5125 43.5391 38.0625 34.8498 38.0625 25.3139C38.0625 15.5102 41.7099 6.59846 47.6538 0Z" />
          </svg>
          <h1>Lilypads</h1>
          <p>
          Lilypads provides a SaaS based compliance infrastructure 
          for management and rapid settlement by eliminating 
          complexity, cost and liquidity from commercial 
          realestaste Investment transaction
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurProduct;
