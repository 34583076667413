import { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper";
import { useSwiper, Swiper, SwiperSlide } from "swiper/react";
import { Accordion, Container, Row, Col, Tab, Nav } from "react-bootstrap";
import ExchangeIcon from "./../../assets/images/icons/exchange.svg";
import HedgeIcon from "./../../assets/images/icons/coins.svg";
import ChairIcon from "./../../assets/images/icons/chair.svg";
import TradersIcon from "./../../assets/images/icons/trades.svg";
import BankIcon from "./../../assets/images/icons/banks.svg";
import PersonIcon from "./../../assets/images/icons/person.svg";
import BrandLogo from "./../../assets/images/icons/cognier-logo.svg";
import CogIcon from "./../../assets/images/icons/settings.svg";
import CheckIcon from "./../../assets/images/icons/check.svg";
import HandShakeIcon from "./../../assets/images/icons/handshake.svg";
import ShieldIcon from "./../../assets/images/icons/shield.svg";
import BadgeIcon from "./../../assets/images/icons/badge.svg";
import TokenIcon from "./../../assets/images/icons/token.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./SlidersComp.scss";
// import saa from './../../../../../../../../assets/saa';

const SlidersComp = () => {
  const [activeSolTab, setActiveSolTab] = useState("first");
  const [conOffset, setConOffset] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    setContainer();
    window.addEventListener("resize", function () {
      setContainer();
    });
  }, []);

  const CardPrevBtn = ({ children }) => {
    const swiper = useSwiper();
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slidePrev()}>
        {children}
      </button>
    );
  };

  const CardNextBtn = ({ children }) => {
    const swiper = useSwiper();
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slideNext()}>
        {children}
      </button>
    );
  };

  const setContainer = () => {
    const rect = containerRef.current.getBoundingClientRect();
    setConOffset(rect.left);
  };

  const tabHandler = (tab) => {
    setActiveSolTab(tab);
  };

  return (
    <div className="App">
      <header className="custom-header">
        <Container>
          <div className="custom-header-inner">
            <div className="brand-logo">
              <a href="#">
                <img src={BrandLogo} />
              </a>
            </div>

            <nav className="head-menu">
              <li className="head-menu-item">
                <a href="#" className="head-menu-link">
                  Home
                </a>
              </li>
              <li className="head-menu-item custom-dropdown">
                <div className="head-menu-link">Products</div>
                <ul className="head-sub-menu">
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={BadgeIcon} alt="Badge" />
                      </div>
                      Digitrust
                    </a>
                  </li>
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={TokenIcon} alt="Token" />
                      </div>
                      Trust Tokens
                    </a>
                  </li>
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={ShieldIcon} alt="Shield" />
                      </div>
                      Securities
                    </a>
                  </li>
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={CogIcon} alt="Gear" />
                      </div>
                      Token Engine
                    </a>
                  </li>
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={HandShakeIcon} alt="Handshake" />
                      </div>
                      Deal rooms
                    </a>
                  </li>
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={CheckIcon} alt="Checkmark" />
                      </div>
                      White Label Solution
                    </a>
                  </li>
                </ul>
              </li>
              <li className="head-menu-item custom-dropdown">
                <div className="head-menu-link">Company</div>
                <ul className="head-sub-menu">
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={BadgeIcon} alt="Badge" />
                      </div>
                      Blogs
                    </a>
                  </li>
                  <li className="head-sub-item">
                    <a href="#" className="head-sub-link">
                      <div className="head-sub-img">
                        <img src={TokenIcon} alt="Token" />
                      </div>
                      Careers
                    </a>
                  </li>
                </ul>
              </li>
            </nav>

            <button className="rounded-btn">Get In Touch</button>
          </div>
        </Container>
      </header>

      <div className="card-slider">
        <Swiper
          slidesPerView={5.5}
          spaceBetween={36}
          centeredSlides={true}
          slideToClickedSlide={true}
          loop={true}
        >
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={PersonIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    OTC/Brokerage
                  </h6>
                  <p className="card-slide-desc">
                    Optimizing settlement operations by providing customized
                    user permissions and asset transfer policies
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={BankIcon} alt="" />
                </div>

                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">Banks</h6>
                  <p className="card-slide-desc">
                    Assisting financial institutions in growing their businesses
                    by providing them with next-gen products and services that
                    facilitate tokenization of assets and provide an exceptional
                    user experience
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={ExchangeIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">Exchanges</h6>
                  <p className="card-slide-desc">
                    Boosting trading activities by running exchanges using
                    Cognier's DigiTrust Wallet. Built using ERC 1155 protocols,
                    our wallet offers flexibility and security, facilitating
                    token support and minimizing operational costs
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={HedgeIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    Hedge Funds
                  </h6>
                  <p className="card-slide-desc">
                    Safeguarding investor funds from cyber threats, internal
                    conflicts, and human errors by providing one of the most
                    secure platforms that facilitate easy and instant
                    settlements.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={ChairIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    Lending Desk
                  </h6>
                  <p className="card-slide-desc">
                    Allowing institutions to deploy capital at a scale against
                    Trust Tokens stored securely in our wallet, thus increasing
                    operational speed and expanding token support.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={TradersIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    Prop Traders
                  </h6>
                  <p className="card-slide-desc">
                    Facilitating exchanges, counterparties, and trading venues
                    under one roof to increase efficiency in operations and
                    drive balance sheet optimization
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={PersonIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    OTC/Brokerage
                  </h6>
                  <p className="card-slide-desc">
                    Optimizing settlement operations by providing customized
                    user permissions and asset transfer policies
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={BankIcon} alt="" />
                </div>

                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">Banks</h6>
                  <p className="card-slide-desc">
                    Assisting financial institutions in growing their businesses
                    by providing them with next-gen products and services that
                    facilitate tokenization of assets and provide an exceptional
                    user experience
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={ExchangeIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">Exchanges</h6>
                  <p className="card-slide-desc">
                    Boosting trading activities by running exchanges using
                    Cognier's DigiTrust Wallet. Built using ERC 1155 protocols,
                    our wallet offers flexibility and security, facilitating
                    token support and minimizing operational costs
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={HedgeIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    Hedge Funds
                  </h6>
                  <p className="card-slide-desc">
                    Safeguarding investor funds from cyber threats, internal
                    conflicts, and human errors by providing one of the most
                    secure platforms that facilitate easy and instant
                    settlements.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={ChairIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    Lending Desk
                  </h6>
                  <p className="card-slide-desc">
                    Allowing institutions to deploy capital at a scale against
                    Trust Tokens stored securely in our wallet, thus increasing
                    operational speed and expanding token support.
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="card-slide-wrap">
            <div className="card-slide-outline">
              <div className="card-slide">
                <div className="card-slide-img">
                  <img src={TradersIcon} alt="" />
                </div>
                <div className="card-slide-content">
                  <h6 className="card-slide-title text-prim-blue">
                    Prop Traders
                  </h6>
                  <p className="card-slide-desc">
                    Facilitating exchanges, counterparties, and trading venues
                    under one roof to increase efficiency in operations and
                    drive balance sheet optimization
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <div className="custom-card-nav">
            <CardPrevBtn>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.02344 9.99865L13.1484 5.87365L11.9701 4.69531L6.66677 9.99865L11.9701 15.302L13.1484 14.1236L9.02344 9.99865Z"
                  fill="currentcolor"
                />
              </svg>
            </CardPrevBtn>
            <CardNextBtn>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9766 9.99865L6.85156 5.87365L8.0299 4.69531L13.3332 9.99865L8.0299 15.302L6.85156 14.1236L10.9766 9.99865Z"
                  fill="currentcolor"
                />
              </svg>
            </CardNextBtn>
          </div>
        </Swiper>
      </div>

      <section className="features-section py-5">
        <Container ref={containerRef}>
          <Row>
            <Col>
              <div className="feat-intro">
                <h3 className="intro-title">Features</h3>
                <p className="lined-subtitle">
                  Our one-of-a-kind wallet built on Lilypads private blockchain
                  platform helps you to effortlessly invest in tokenized
                  commercial real estate assets.
                </p>
              </div>
            </Col>

            <Col>
              <div className="feat-acc-wrap">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header as={"h3"}>
                      <div className="acc-num me-4"> 01 </div> Multiple wallet
                      integrations
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">
                        Link external wallets with our ERC 1155 supported
                        Digitrust wallet to facilitate quick and easy transfer
                        of funds along with swapping of crypto-tokens with Trust
                        tokens.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header as={"h3"}>
                      <div className="acc-num me-4"> 02 </div> Automated
                      payments
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">
                        Link external wallets with our ERC 1155 supported
                        Digitrust wallet to facilitate quick and easy transfer
                        of funds along with swapping of crypto-tokens with Trust
                        tokens.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header as={"h3"}>
                      <div className="acc-num me-4"> 03 </div> Wallet Duality
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">
                        Link external wallets with our ERC 1155 supported
                        Digitrust wallet to facilitate quick and easy transfer
                        of funds along with swapping of crypto-tokens with Trust
                        tokens.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header as={"h3"}>
                      <div className="acc-num me-4"> 04 </div> Secured
                      investment
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="mb-0">
                        Link external wallets with our ERC 1155 supported
                        Digitrust wallet to facilitate quick and easy transfer
                        of funds along with swapping of crypto-tokens with Trust
                        tokens.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section
        className="solutions-section"
        style={{
          paddingLeft: `${conOffset}px`,
        }}
      >
        <div className="solutions-inner">
          <div className="sols-intro">
            <h3 className="intro-title">
              <span className="text-prim-blue">White-Label</span> Solutions
            </h3>
            <p className="lined-subtitle">
              Our one-of-a-kind wallet built on Lilypads private blockchain
              platform helps you to effortlessly invest in tokenized commercial
              real estate assets.
            </p>
          </div>

          <div className="sols-tab-wrap">
            <Tab.Container defaultActiveKey={"first"} activeKey={activeSolTab}>
              <div className="sols-tab-inner">
                <div className="sols-tab-content">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="sol-number">01</div>
                      <div className="sol-card-wrap">
                        <div className="sol-card">
                          <h4 className="text-prim-blue">Wallet Service</h4>

                          <p>
                            Increase efficiency in operations by leveraging our
                            highly secure wallet built following ERC-1155
                            protocols. Our one-of-a-kind digital wallet
                            facilitates multiple wallet integration along with
                            staking options to protect assets from market
                            volatility.
                          </p>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="sol-number">02</div>
                      <div className="sol-card-wrap">
                        <div className="sol-card">
                          <h4 className="text-prim-blue">
                            Securities & Settlements
                          </h4>

                          <p>
                            Increase efficiency in operations by leveraging our
                            highly secure wallet built following ERC-1155
                            protocols. Our one-of-a-kind digital wallet
                            facilitates multiple wallet integration along with
                            staking options to protect assets from market
                            volatility.
                          </p>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="sol-number">03</div>
                      <div className="sol-card-wrap">
                        <div className="sol-card">
                          <h4 className="text-prim-blue">Deal Room</h4>

                          <p>
                            Increase efficiency in operations by leveraging our
                            highly secure wallet built following ERC-1155
                            protocols. Our one-of-a-kind digital wallet
                            facilitates multiple wallet integration along with
                            staking options to protect assets from market
                            volatility.
                          </p>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      <div className="sol-number">04</div>
                      <div className="sol-card-wrap">
                        <div className="sol-card">
                          <h4 className="text-prim-blue">
                            Permissioned Blockchain Network
                          </h4>

                          <p>
                            Increase efficiency in operations by leveraging our
                            highly secure wallet built following ERC-1155
                            protocols. Our one-of-a-kind digital wallet
                            facilitates multiple wallet integration along with
                            staking options to protect assets from market
                            volatility.
                          </p>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      <div className="sol-number">05</div>
                      <div className="sol-card-wrap">
                        <div className="sol-card">
                          <h4 className="text-prim-blue">
                            Portfolio Asset Management
                          </h4>

                          <p>
                            Increase efficiency in operations by leveraging our
                            highly secure wallet built following ERC-1155
                            protocols. Our one-of-a-kind digital wallet
                            facilitates multiple wallet integration along with
                            staking options to protect assets from market
                            volatility.
                          </p>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
                <div className="sols-tab-nav">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="first"
                        onMouseEnter={() => tabHandler("first")}
                      >
                        Wallet Service
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="second"
                        onMouseEnter={() => tabHandler("second")}
                      >
                        Securities & Settlements
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        onMouseEnter={() => tabHandler("third")}
                      >
                        Deal Room
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fourth"
                        onMouseEnter={() => tabHandler("fourth")}
                      >
                        Permissioned Blockchain Network
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="fifth"
                        onMouseEnter={() => tabHandler("fifth")}
                      >
                        Portfolio Asset Management
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </section>

      <section className="strategy-section">
        <Container>
          <Row>
            <Col xl={3}>
              <div className="strat-intro">
                <h3>
                  Our <span className="text-prim-blue"> Strategies</span>
                </h3>
                <p>
                  Cognier Technology Group embodies unparalleled strategies that
                  act as a blueprint for securing steady yields
                </p>
              </div>
            </Col>
            <Col xl={9}>
              <div className="strat-slider">
                <div className="strat-card">
                  <div className="strat-title">
                    <div className="strat-img">
                      <svg
                        width="40"
                        height="37"
                        viewBox="0 0 40 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M36 18C36.0002 17.1312 35.9288 16.2638 35.7867 15.4067L30.6667 13.58L33 8.66669C31.9816 7.25634 30.7437 6.01841 29.3333 5.00003L24.42 7.33336L22.5933 2.21337C20.8762 1.92888 19.1239 1.92888 17.4067 2.21337L15.58 7.33336L10.6667 5.00003C9.25635 6.01841 8.01842 7.25634 7.00003 8.66669L9.33336 13.58L4.21337 15.4067C3.92888 17.1239 3.92888 18.8761 4.21337 20.5933L9.33336 22.42L7.00003 27.3333C8.01842 28.7437 9.25635 29.9816 10.6667 31L15.58 28.6666L17.4067 33.7866C19.1239 34.0711 20.8762 34.0711 22.5933 33.7866L24.42 28.6666L29.3333 31C30.7437 29.9816 31.9816 28.7437 33 27.3333L30.6667 22.42L35.7867 20.5933C35.9288 19.7362 36.0002 18.8688 36 18V18Z"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M20 23C22.7614 23 25 20.7614 25 18C25 15.2386 22.7614 13 20 13C17.2386 13 15 15.2386 15 18C15 20.7614 17.2386 23 20 23Z"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </div>
                    <h4 className="text-prim-blue">Ad Manager</h4>
                  </div>

                  <div className="strat-content">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>

                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC,
                    </p>
                  </div>
                </div>
                <div className="strat-card">
                  <div className="strat-title">
                    <div className="strat-img">
                      <svg
                        width="40"
                        height="37"
                        viewBox="0 0 40 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M22.8561 14.3902V2.12317C22.8561 0.80326 22.2544 0.618782 21.5122 1.71817L9.3514 19.7309C8.60797 20.8321 9.083 21.7215 10.4097 21.7215H19.1439V33.9886C19.1439 35.3085 19.7456 35.493 20.4878 34.3936L32.6486 16.3808C33.392 15.2796 32.917 14.3902 31.5903 14.3902H22.8561Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <h4 className="text-prim-blue">
                      Ad Monitoring and Boosting
                    </h4>
                  </div>

                  <div className="strat-content">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>

                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC,
                    </p>
                  </div>
                </div>
                <div className="strat-card">
                  <div className="strat-title">
                    <div className="strat-img">
                      <svg
                        width="40"
                        height="37"
                        viewBox="0 0 40 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M18.2363 17.5015C18.4939 17.7795 18.6353 18.1426 18.6353 18.5182V29.5866C18.6353 30.2527 19.4458 30.5907 19.9255 30.1224L23.0388 26.5841C23.4554 26.0883 23.6851 25.8428 23.6851 25.352V18.5207C23.6851 18.1451 23.8291 17.782 24.0841 17.504L33.0172 7.89063C33.6863 7.16944 33.1713 6 32.1815 6H10.1389C9.14911 6 8.6315 7.16693 9.30313 7.89063L18.2363 17.5015Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <h4 className="text-prim-blue">
                      Lead Generation & Marketing
                    </h4>
                  </div>

                  <div className="strat-content">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>

                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text. It has roots in a piece of classical Latin
                      literature from 45 BC,
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SlidersComp;
