import React from 'react'
import './NSecFourWhat.scss'
import c1 from './../../../assets/what/new/c1.png'
import c2 from './../../../assets/what/new/c2.png'
import c3 from './../../../assets/what/new/c3.png'
// data-aos-duration="3000"
const NSecFourWhat = () => {
  return (
    <div className="NSecFourWhatMain">
      <div   data-aos="fade-up"
      className="NSecFourWhatMainT">
        <h2>THE KEY IDEALS OF OUR INSTITUTION </h2>
      </div>
      <div className="NSecFourWhatMainM">
        {/* CARD START */}
        <div data-aos="fade-right" className="NSecFourWhatCard">
          <img src={c1} alt="" />
          <h3>Pioniergeist</h3>
          <p>
            As a growing business with a workshop mentality that ignites the world with its entreprenurial spirit and a sustainable business platform
          </p>
        </div>
        {/* CARD END */}
        <div className="NSFLine"></div>

        {/* CARD START */}
        <div data-aos="fade-up" className="NSecFourWhatCard">
          <img src={c2} alt="" />
          <h3>Herzblut</h3>
          <p>
            Passion and enthusiasm that fuel our combined efforts to pursue
            innovation and unique solutions everyday
          </p>
        </div>
        {/* CARD END */}
        <div className="NSFLine lh"></div>
        {/* CARD START */}
        <div data-aos="fade-left" className="NSecFourWhatCard ">
          <img src={c3} alt="" />
          <h3>Einheit</h3>
          <p>
            We remain rooted while reaching for the sky with our teamwork that is emblematic of unity in diversity
          </p>
        </div>
        {/* CARD END */}
      </div>
      <div data-aos="zoom-in" className="NSecFourWhatMainB">
        <button onClick={()=> window.open("https://cogniertechnology.com/contact", "_blank")} className="MainGetStartedBTN">Get in Touch</button>
      </div>
    </div>
  )
}

export default NSecFourWhat
