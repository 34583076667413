import SecOneWho from "./../components/SectionWho/SecOneWho";
import SecTwoWho from "./../components/SectionWho/SecTwoWho";
import SecThreeWho from "./../components/SectionWho/SecThreeWho";
import Footer from "./../components/SectionsLP/Footer";
import SecFiveWho from "./../components/SectionWho/SecFiveWho";
import { useEffect } from "react";
import Team from "./../components/Team/Team";
import SecOneWhoNew from "./../components/SectionWho/SecOneWhoNew";

const WhoPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      {/* <SecOneWho /> */}
      <SecOneWhoNew />

      <SecTwoWho />
      <SecThreeWho />

      <Team />
      <SecFiveWho />

      <Footer footerGap={true} />
    </div>
  );
};

export default WhoPage;
