import React from 'react'
import oneI from './4.png'
import twoI from './5.png'
import threeI from './6.png'
import blurI from './blur.png'
import backI from './back.png'

const DigiTrustTaxes = () => {
  return (
    <div className="DigiTrustTaxes-main">
        <img src={blurI} alt="" className="Blur1" />
        <img src={blurI} alt="" className="Blur2" />
        <img src={backI} alt="" className="backI" />
      <div className="DigiTrustTaxes-T">
        <h1>Tax Benefits</h1>
      </div>
      <div className="DigiTrustTaxes-B">
        <TaxComp
          h1="Easy Tax Filing"
          h2="Automate, simplify, and safeguard your cryptocurrency tax payments and capital gains on NFTs using our white label platform"
          // img={oneI}
          img={twoI}
        />
        <TaxComp
          h1="Specific Identification"
          h2="Cognier Technology provides you with supporting documentation for your filing using specific identification accounting"
          // img={twoI}
          img={threeI}
        />
        <TaxComp
          h1="Mirroring Real Assets"
          h2="Tax rules and benefits applicable to real estate property/assets apply to virtual representations (NFTs) of those properties, according to the IRS "
          img={oneI}
        />
      </div>
    </div>
  )
}

export default DigiTrustTaxes

function TaxComp({ h1, h2, img }) {
  return (
    <div className="TaxComp">
      <div className="taxCircle">
        <img src={img} alt="" />
      </div>

      <h2>{h1}</h2>
      <h3>{h2}</h3>
    </div>
  )
}
