import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import "./SecOneJD.scss";

import Header from './../Header';
import { Link , useHistory} from 'react-router-dom';
const SecOneJD = () => {
  const [allJobs, setAllJobs] = useState("");
  const [jobName, setJobName] = useState("");
  const [jobExp, setJobExp] = useState("");
  const [jobSummary, setJobSummary] = useState("");
  const jdR = useSelector((state) => state.jdR);
  const history = useHistory();
  // const [count, setCount] = useState(`${jdR}`);




  useEffect(() => {
  
    window.scrollTo(0, 0);
    const config = {
      headers: {
        "X-Api-Key": "VbrBlQq1.02f1dcYZaguPQNqg6vEyHWolN9NghIvl",
      },
    };
    const url = `https://api.cogniertechnology.com/api/jobs`;
    const urlTwo = `https://api.cogniertechnology.com/api/jobs/2`;

    async function getData() {
      const res = await axios.get(url, config);
      console.log("count value "+jdR);
      console.log(res);
      console.log(`${jdR-1}`);
      console.log(res.data[1].job_name);
    //   setJobName(res.data[`${count}`].job_name);
      setJobName(res.data[`${jdR-1}`].job_name);
      setJobExp(res.data[`${jdR-1}`].experience);
      setJobSummary(res.data[`${jdR-1}`].job_detail);
    }

    getData();
  
  }, []);

 


  return (
    <div className="SectionOneJD">
        <Header active="5"/>
      <div className="SectionOneJDWrapper">
     
        <div className="top">
            <Link to="/Careers" >Cognier {">"} Careers</Link>
          <h3>{jobName}</h3>
          <div className="inner">
            <div className="left">
              <h5>LOCATION</h5>
              <h6>Maryland, USA</h6>
            </div>
            <div className="mid">
              <h3></h3>
              <h5>EXPERIENCE</h5>
              <h6>{jobExp}</h6>
            </div>
            <div className="innerBottom">
              <button onClick={() =>{
                console.log(jdR);
                history.push("/Application");
            
              }}><p>Apply Now</p></button>
            </div>
          </div>
        </div>
        <div className="bottom">
          {/* <h2> {jobSummary}</h2> */}
         <div dangerouslySetInnerHTML={{ __html: jobSummary  }}>

         </div>
        </div>
      </div>
    </div>
  );
};

export default SecOneJD;
