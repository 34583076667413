
import Footer from './../components/SectionsLP/Footer';
import { useEffect } from 'react';

import NSecFourWhat from './../components/SectionWhat/newWhat/NSecFourWhat';
import NSecOneWhat from './../components/SectionWhat/newWhat/NSecOneWhat';
import NSecFiveWhat from './../components/SectionWhat/newWhat/NSecFiveWhat';
import NSecThreeWhat from './../components/SectionWhat/newWhat/NSecThreeWhat';

const WhatPage = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <div> 
            <NSecOneWhat/>
            <NSecThreeWhat/>
            <NSecFourWhat/>
            <NSecFiveWhat/>
            <Footer/>
        </div>
    )
}

export default WhatPage
