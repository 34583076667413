import { useSwiper, Swiper, SwiperSlide } from "swiper/react";
import ExchangeIcon from "./../../assets/images/icons/exchange.svg";
import HedgeIcon from "./../../assets/images/icons/coins.svg";
import ChairIcon from "./../../assets/images/icons/chair.svg";
import TradersIcon from "./../../assets/images/icons/trades.svg";
import BankIcon from "./../../assets/images/icons/banks.svg";
import PersonIcon from "./../../assets/images/icons/person.svg";
import "./CardSlider.css";

export default function CardSlider() {
  const CardPrevBtn = ({ children }) => {
    const swiper = useSwiper();
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slidePrev()}>
        {children}
      </button>
    );
  };

  const CardNextBtn = ({ children }) => {
    const swiper = useSwiper();
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slideNext()}>
        {children}
      </button>
    );
  };

  return (
    <div className="LandingCardSlider card-slider container-fluid section-spacing-top section-spacing-bottom">
      <div className="text-center mb-5">
        <h3 className="f-archivo intro-title">
          <span className="text-prim-blue">Our </span> Customers
        </h3>
        <p style={{ maxWidth: "641px" }} className="mx-auto intro-sub">
          Our one-of-a-kind wallet built on Lilypads private blockchain platform
          helps you to effortlessly invest in tokenized commercial real estate
          assets.
        </p>
      </div>

      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        slideToClickedSlide={true}
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          375: {
            slidesPerView: 1.5,
          },
          414: {
            slidesPerView: 1.5,
          },
          768: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 2.5,
          },
          1200: {
            spaceBetween: 36,
            slidesPerView: 3,
          },
          1440: {
            slidesPerView: 5,
          },
        }}
        loop={true}
      >
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={PersonIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  OTC/Brokerage
                </h6>
                <p className="card-slide-desc">
                  Optimizing settlement operations by providing customized user
                  permissions and asset transfer policies
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={BankIcon} alt="" />
              </div>

              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Banks
                </h6>
                <p className="card-slide-desc">
                  Assisting financial institutions in growing their businesses
                  by providing them with next-gen products and services that
                  facilitate tokenization of assets and provide an exceptional
                  user experience
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={ExchangeIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Exchanges
                </h6>
                <p className="card-slide-desc">
                  Boosting trading activities by running exchanges using
                  Cognier's DigiTrust Wallet. Built using ERC 1155 protocols,
                  our wallet offers flexibility and security, facilitating token
                  support and minimizing operational costs
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={HedgeIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Hedge Funds
                </h6>
                <p className="card-slide-desc">
                  Safeguarding investor funds from cyber threats, internal
                  conflicts, and human errors by providing one of the most
                  secure platforms that facilitate easy and instant settlements.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={ChairIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Lending Desk
                </h6>
                <p className="card-slide-desc">
                  Allowing institutions to deploy capital at a scale against
                  Trust Tokens stored securely in our wallet, thus increasing
                  operational speed and expanding token support.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={TradersIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Prop Traders
                </h6>
                <p className="card-slide-desc">
                  Facilitating exchanges, counterparties, and trading venues
                  under one roof to increase efficiency in operations and drive
                  balance sheet optimization
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={PersonIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  OTC/Brokerage
                </h6>
                <p className="card-slide-desc">
                  Optimizing settlement operations by providing customized user
                  permissions and asset transfer policies
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={BankIcon} alt="" />
              </div>

              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Banks
                </h6>
                <p className="card-slide-desc">
                  Assisting financial institutions in growing their businesses
                  by providing them with next-gen products and services that
                  facilitate tokenization of assets and provide an exceptional
                  user experience
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={ExchangeIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Exchanges
                </h6>
                <p className="card-slide-desc">
                  Boosting trading activities by running exchanges using
                  Cognier's DigiTrust Wallet. Built using ERC 1155 protocols,
                  our wallet offers flexibility and security, facilitating token
                  support and minimizing operational costs
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={HedgeIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Hedge Funds
                </h6>
                <p className="card-slide-desc">
                  Safeguarding investor funds from cyber threats, internal
                  conflicts, and human errors by providing one of the most
                  secure platforms that facilitate easy and instant settlements.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={ChairIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Lending Desk
                </h6>
                <p className="card-slide-desc">
                  Allowing institutions to deploy capital at a scale against
                  Trust Tokens stored securely in our wallet, thus increasing
                  operational speed and expanding token support.
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="card-slide-wrap">
          <div className="card-slide-outline">
            <div className="card-slide">
              <div className="card-slide-img">
                <img src={TradersIcon} alt="" />
              </div>
              <div className="card-slide-content">
                <h6 className="card-slide-title text-prim-blue f-archivo">
                  Prop Traders
                </h6>
                <p className="card-slide-desc">
                  Facilitating exchanges, counterparties, and trading venues
                  under one roof to increase efficiency in operations and drive
                  balance sheet optimization
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <div className="custom-card-nav">
          <CardPrevBtn>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.02344 9.99865L13.1484 5.87365L11.9701 4.69531L6.66677 9.99865L11.9701 15.302L13.1484 14.1236L9.02344 9.99865Z"
                fill="currentcolor"
              />
            </svg>
          </CardPrevBtn>
          <CardNextBtn>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9766 9.99865L6.85156 5.87365L8.0299 4.69531L13.3332 9.99865L8.0299 15.302L6.85156 14.1236L10.9766 9.99865Z"
                fill="currentcolor"
              />
            </svg>
          </CardNextBtn>
        </div>
      </Swiper>
    </div>
  );
}
