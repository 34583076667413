import React from 'react'
import '../TokenEnginePage/TE.scss'
import TokenEngineI from './../../assets/TokenImg.png'
import TokenEngineINew from './../../assets/TokenizationNeww.png'
import { MultiText } from '../../components/Utils'

const TOne = () => {
  return (
    <div className="TEsecOne">
      <img className='TOneImg' src={TokenEngineINew} alt="" />
      <div className="spacer"></div>
      <MultiText
        mode="rev"
        h1="Simplify Token Issuance and
        Asset Management"
        h1Size="48px"
        boxSize="867px"
      />
      <svg
      className='dividers'
        width="190"
        height="4"
        viewBox="0 0 190 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1.99095"
          y1="2.69025"
          x2="188.419"
          y2="1.01072"
          stroke="url(#paint0_radial_5111_8288)"
          strokeWidth="2"
          strokeLinecap="square"
        />
        <defs>
          <radialGradient
            id="paint0_radial_5111_8288"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(92.9161 2.87114) rotate(-0.516165) scale(96.5159 861)"
          >
            <stop stopColor="#219EBC" />
            <stop offset="1" stopColor="#219EBC" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>

      <p>
      Convert and Deploy your security tokens with our tokenization platform built on a multi-layered security and workflow authorization policy engine
        </p>

      <div className="scrollInd">
        Scroll{' '}
        <svg
          width="12"
          height="7"
          viewBox="0 0 12 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11 1L6 6L1 0.999999"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  )
}

export default TOne
