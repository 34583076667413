import React from "react";
import Footer from "./../components/SectionsLP/Footer";
import Header from "./../components/Header";
import pageImg from "./../assets/PAGESIMG/whitelabelsolution1.png";
import pageImgTwo from "./../assets/PAGESIMG/whitelabelsolution2.png";
import VerticalTabs from "./../components/Sliders/VerticalTabs";
import WLTrustHero from "../newPages/WhiteLabel/WLTrustHero";
import Tokenize1 from "./../assets/t1.png";
import Tokenize2 from "./../assets/t2.png";
import Tokenize3 from "./../assets/t3.png";
import Feature1 from "./../assets/trust-token/wl-1.png";
import Feature2 from "./../assets/trust-token/wl-2.png";
import Feature3 from "./../assets/trust-token/wl-3.png";
import { MultiText } from "../components/Utils";
import FeatureConnecter1 from "./../assets/trust-token/feature-connect-1.svg";
import FeatureConnecter2 from "./../assets/trust-token/feature-connect-2.svg";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import SecurexTrustHero from './../newPages/WhiteLabel/SecurexTrustHero';
import imgkey from "../assets/securexkey.png"

const Securex = () => {
  const CardPrevBtn = ({ children }) => {
    const swiper = useSwiper();
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slidePrev()}>
        {children}
      </button>
    );
  };

  const CardNextBtn = ({ children }) => {
    const swiper = useSwiper();
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slideNext()}>
        {children}
      </button>
    );
  };

  console.log(window.innerWidth);

  return (
    <div className="whitelabel securex">
      <Header active="1" />
      {/* <img src={pageImg} alt="" className="w-100" /> */}
      <SecurexTrustHero/>
      <div className="SecureKey">
        <img src={imgkey} />
      </div>
      <div className="HowDigitrust-Main TrustToken-Main">
        <div className="HowDigitrust-T">
          <div className="Tcontainer">
            <h1>Enterprise grade scalable security solutions</h1>
            <h2 style={{ width: "60%" }}>
              Leverage future-proof technology solutions to tackle operational
              and security challenges
            </h2>
          </div>
        </div>
        <div className="Feature Feature-Desktop">
          <div className="Feature-col">
            <div className="Feature-img Feature-img1">
              <img src={Feature1} alt="" />
              <img src={FeatureConnecter1} alt="" className="connector1" />
            </div>
            <div className="Feature-col-inner">
              <h3>Eliminate Intermediaries</h3>
              <p>
                Smart Contracts let you collect and disburse payments without
                intermediaries by engaging in direct transactions with owners
                and financial actors
              </p>
            </div>
          </div>
          <div className="Feature-col sec">
            <div className="Feature-img Feature-img2">
              <img src={Feature2} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>instant settlements </h3>
              <p>
                Get rid of traditional time-consuming deals and contracts and
                enjoy lightning fast automated settlements using smart contracts
              </p>
            </div>
          </div>
          <div className="Feature-col">
            <div className="Feature-img Feature-img3">
              <img src={Feature3} alt="" />
              <img src={FeatureConnecter2} alt="" className="connector2" />
            </div>
            <div className="Feature-col-inner">
              <h3>Anti-fraud technology</h3>
              <p>
                Eliminate the risk of internal frauds and conflicts in workflows
                by setting up deals in smart contracts while maintaining an
                immutable transactional process
              </p>
            </div>
          </div>
        </div>
        <Swiper
          loop={true}
          slidesPerView={window.innerWidth < 330 ? 1 : 1.3}
          spaceBetween={15}
          centeredSlides={true}
          className="Feature Feature-mob"
        >
          <SwiperSlide className="Feature-col">
            <div className="Feature-img">
              <img src={Feature1} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>Eliminate Intermediaries</h3>
              <p>
                Smart Contracts let you collect and disburse payments without
                intermediaries by engaging in direct transactions with owners
                and financial actors
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="Feature-col">
            <div className="Feature-img">
              <img src={Feature2} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>instant settlements </h3>
              <p>
                Get rid of traditional time-consuming deals and contracts and
                enjoy lightning fast automated settlements using smart contracts
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="Feature-col">
            <div className="Feature-img">
              <img src={Feature3} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>Anti-fraud technology</h3>
              <p>
                Eliminate the risk of internal frauds and conflicts in workflows
                by setting up deals in smart contracts while maintaining an
                immutable transactional process
              </p>
            </div>
          </SwiperSlide>

          <div className="custom-card-nav d-flex justify-content-between">
            <CardPrevBtn>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.02344 9.99865L13.1484 5.87365L11.9701 4.69531L6.66677 9.99865L11.9701 15.302L13.1484 14.1236L9.02344 9.99865Z"
                  fill="currentcolor"
                />
              </svg>
            </CardPrevBtn>
            <CardNextBtn>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9766 9.99865L6.85156 5.87365L8.0299 4.69531L13.3332 9.99865L8.0299 15.302L6.85156 14.1236L10.9766 9.99865Z"
                  fill="currentcolor"
                />
              </svg>
            </CardNextBtn>
          </div>
        </Swiper>
      </div>
      {/* <VerticalTabs /> */}
      {/* <img src={pageImgTwo} alt="" className="w-100" /> */}
      <div className="tokenize">
        <div className="tokenize-inner">
          <div className="HowDigitrust-T">
            <div className="Tcontainer">
            <h1>Multifacted Securix platform</h1>

            </div>
          </div>
          <div className="tokenize-row">
            <div className="tokenize-col">
              <img style={{top: "0px"}} src={Tokenize1} alt="" />
              <div className="tokenize-col-inner modifiedInner">
                <h3 style={{ marginBottom: "20px" }}>Multi-Cloud Deployment</h3>
                <p>
                Opt for SaaS, Hybrid and On-Premise solutions and implementation environment
                </p>
              </div>
            </div>
            <div className="tokenize-col sec">
              <img style={{top: "0px"}} src={Tokenize2} alt="" />
              <div className="tokenize-col-inner modifiedInner">
                <h3 style={{ marginBottom: "20px" }}>Deposit Address Management</h3>
                <p>
                Our Asset Transfer network completely eliminates the risk of static deposit addresses by automating authentication and rotation for the same
                </p>
              </div>
            </div>
            <div className="tokenize-col third">
              <img style={{top: "0px"}} src={Tokenize3} alt="" />
              <div className="tokenize-col-inner modifiedInner">
                <h3 style={{ marginBottom: "20px" }}>
                Workflow Authorization
                </h3>
                <p>
                We provide organizations with private channels and access control lists to minimize data leaks and public exposure
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Securex;
