import React from 'react'
import './Utils.scss'

function MultiText({ h1, span, h1Size, boxSize, mode }) {
  return (
    <>
      {mode === 'rev' ? (
        <>
          {' '}
          <div
            style={{
              width: boxSize,
              textAlign: 'center',
            }}
            className="multitextCon"
          >
            <h1
              style={{
                fontSize: h1Size,
              }}
            >
              <span style={{ marginLeft: 0 }}>{span}</span>
              {h1}
            </h1>
          </div>
        </>
      ) : (
        <>
          {' '}
          <div
            style={{
              width: boxSize,
            }}
            className="multitextCon"
          >
            <h1
              style={{
                fontSize: h1Size,
              }}
            >
              {h1}
              <span>{span}</span>
            </h1>
          </div>
        </>
      )}
    </>
  )
}

export { MultiText }
