import React from 'react'
import "./AllCard.scss"
const ClientCard = (props) => {
  return (
    <div className="clientCard">
      <div className="clientCardW">
        <div className="clientCardT">
          <div className="imgCon">
            <img className="clientCardI" src={props.i1} alt="" />
          </div>
          <div className="topTCon">
            <h2>{props.title}</h2>
            <h3>{props.subHead}</h3>
          </div>
        </div>
        <h4>
          {props.content}
         
        </h4>
      </div>
    </div>
  )
}

export default ClientCard
