import React from 'react'
import tck from './../../../assets/ApproachPage/new/tck.png'
import c1 from './../../../assets/ApproachPage/new/c1.png'
import c2 from './../../../assets/ApproachPage/new/c2.png'
import c3 from './../../../assets/ApproachPage/new/c3.png'
import mi1 from './../../../assets/ApproachPage/new/mi1.png'
import mi1m from './../../../assets/ApproachPage/new/mi1m.png'
import mi2 from './../../../assets/ApproachPage/new/mi2.png'
import mi2m from './../../../assets/ApproachPage/new/mi2m.png'
const NSecThreeApBtm = () => {
    return (
        <div className="NSecThreeAPM">
       
        {/* TWO */}
  
        <div className="NSecThreeAPMC sTwo">
          <div data-aos="fade-right" className="NSecThreeAPMCR">
            <img   className="dImg" src={mi2} alt="" />
          </div>
          <div data-aos="fade-left" className="NSecThreeAPMCL">
            <h2>Become an<span> Owner Operator</span>  on Lilypad</h2>
            <div className="listCon">
              <img src={tck} alt="" />
              <p>
              Asset digitization and tokenized ownership are made possible by smart contracts               </p>
            </div>
            <div className="listCon">
              <img src={tck} alt="" />
              <p>Shares of tokenized assets listed on Lilypads securities platform depending on the prices and share volume set by Owner Operators</p>
            </div>
            <div className="listCon">
              <img src={tck} alt="" />
              <p>
              We allow investors to participate in fractional investments by purchasing shares listed on Lilypads securities platform using Trust Tokens 
              </p>
            </div>
            <div className="listCon">
              <img src={tck} alt="" />
              <p>
              Returns generated from selling shares are directly deposited into your Digitrust wallet without requiring any intermediaries
              </p>
            </div>
            
            <button onClick={()=> window.open("https://cogniertechnology.com/Contact", "_blank")} className="MainGetStartedBTNNB btnHideM">
            Start as an Owner Operator now{' '}
              <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i>
            </button>
            <img style={{ marginLeft: '-5vw',marginBottom:"30px"}}  className="mobImg" src={mi2m} alt="" />
            <button style={{ marginLeft: '5vw'}}className="MainGetStartedBTN mobbtn fwb">   Start as an Owner Operator now  <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i></button>
  
          </div>
        </div>
       
      
      </div>
    )
}

export default NSecThreeApBtm
