import React, { useState, useEffect } from "react";
import "./Header.scss";
import Logo from "./../assets/Logo.svg";
import { Link } from "react-router-dom";
export default function HeaderBlank(props) {
  const [direc, setDirec] = useState("up");
  useEffect(() => {
    if (props.direction == "up") {
      setDirec("up");
      console.log(direc);
    }
    else if (props.direction == "down") {
      setDirec("down");
      console.log(direc);
    }
  });

  return (
    <div
      className={
        "HeaderMain blankMain " +
        (props.direction == "up" && "  up ") +
        (props.direction == "down" && "  down ")
      }
    >
      <div className="left">
        <a href="/">
          <img src={Logo} alt="" />
        </a>
      </div>
      <div className="mid"></div>
      <div className="right">
        <a href="/Contact">
          <button>Contact Us</button>
        </a>
      </div>
    </div>
  );
}
