import React, { useEffect, useRef, useState } from 'react'
import './NSecOneWhat.scss'
import Header from './../../Header'
import bg1 from './../../../assets/what/new/bg1.png'
import bgc from './../../../assets/what/new/bgc.png'
import wbr from './../../../assets/what/new/wbr.png'
import wbrm from './../../../assets/what/new/wbrm.png'
import { TweenMax, TimelineLite, Power3, Power0 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import WhatCtgCard from './../../allCards/WhatCtgCard'
import rArrow from './../../../assets/LP/rArrow.svg'
gsap.registerPlugin(ScrollTrigger)
const NSecOneWhat = () => {
  const [posi, setPosi] = useState(2)
  function leftA(e) {
    e.preventDefault()
    setPosi(posi == 1 ? 4 : posi - 1)
  }
  function rightA(e) {
    e.preventDefault()
    setPosi(posi == 4 ? 1 : posi + 1)
  }
  let tl = new TimelineLite({ delay: 0.2 })

  useEffect(() => {
    // window.scrollTo(0, 0)
    tl.from(
      '.NSecOneWhatT h1',
      1.5,
      { y: '120%', ease: Power3.easeOut },
      'Start',
    )
      .from(
        '.NSecOneWhatT p',
        1.5,
        { y: '120%', opacity: 0, ease: Power3.easeOut },
        0.8,
      )
      .from('.bgWM', 1.5, { scale: 1.2, opacity: 0, ease: Power3.easeOut }, 0.5)
      .from(
        '.tcon h4 , .tcon i',
        1.5,
        { x: '-120%', opacity: 0, ease: Power3.easeOut },
        1,
      )

    tl.from(
      '.bannerMain-rgt',
      3,
      { y: 15, opacity: 0, ease: Power3.easeOut, delay: 0.2 },
      'Start',
    )
    gsap.from('.NSecOneWhatB', {
      duration: 1.5,
      y: '100',
      opacity: 0,
      ease: 'ease-in',
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 70%',
        end: 'bottom 10%',
        toggleActions: 'play none none reverse',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })

    // for window

    gsap.to('.cAimgCOnLWin1', {
      duration: 0.5,
      width: '200px',
      delay: 1,
      ease: Power0,
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })
    gsap.to('.cAimgCOnLWin2', {
      duration: 0.5,
      width: '167px',
      delay: 1.5,
      ease: Power0,
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })
    gsap.to('.cAimgCOnLWin3', {
      duration: 0.5,
      width: '210px',
      delay: 2,
      ease: Power0,
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })

    //for desk
    gsap.to('.cAimgCOnL1', {
      duration: 0.5,
      width: '133px',
      delay: 1,
      ease: Power0,
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        // toggleActions: 'play none none reverse',
        toggleActions: 'restart complete reverse reset',
        //options: play, pause, resume, reset, restart, complete, reverse,none
      },
    })
    gsap.to('.cAimgCOnL2', {
      duration: 0.5,
      width: '255px',
      delay: 1.5,
      ease: Power0,
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        toggleActions: 'restart complete reverse reset',
      },
    })

    gsap.to('.cAimgCOnL3', {
      duration: 0.5,
      width: '275px',
      delay: 2.0,
      ease: Power0,
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        toggleActions: 'restart complete reverse reset',
      },
    })
    gsap.to('.cAimgCOnL4', {
      duration: 0.5,
      width: '145px',
      delay: 2.5,
      ease: Power0,
      scrollTrigger: {
        trigger: '.NSecOneWhatB',
        // markers: true,
        start: 'top 60%',
        end: 'bottom 10%',
        toggleActions: 'restart complete reverse reset',
      },
    })
  }, [])

  return (
    <div className="NSecOneWhatMain">
      {/* <img className="bgc" src={bgc} alt="" /> */}
      <Header active="3" />
      <div  className="NSecOneWhatT">
        <div className="bgWMC">
          <div className="internalHC"></div>
          <div className="imageCOnBgWM">
            
            <div className="imageCOnBgWMWrapper">
            <div className="RippleHolder">
              <div className="rippleCon">
                <div className="rippleCB">
                  <div className="s1 rCircle"></div>
                  <div className="s2 rCircle "></div>
                  <div className="s3 rCircle "></div>
                  <div className="s4 rCircle "></div>
                  {/* <span className="s5"></span> */}
                  {/* <span className="s6"></span>
              <span className="s7"></span>
              <span className="s8"></span> */}
                </div>
              </div>
            </div>
            <img className="bgWM" src={bg1} alt="" />
            </div>
          </div>
        </div>
        <div style={{ overflow: 'hidden' }} className="ofhcon">
          <h1>Ushering in Web3 Revolution in Real Estate </h1>
        </div>
        <div style={{ overflow: 'hidden' }} className="ofhcon">
          <p>
          Cognier helps you democratise and modernize your real estate business with the convenience of the latest Blockchain and Web3 trends. From automating your deal setup with Smart Contracts, streamlining your crypto imports using Multi wallet setups, and buying and selling of Lilypads Trust Tokens using Lilypads Securities to swapping crypto tokens into Tokens/USD or maximizing Deal security with assymetric key encryption- Cognier truly opens the gate to endless opportunities for you.
          </p>
        </div>

        <div style={{ overflow: 'hidden' }} className="tcon">
          <h4>Scroll </h4>
          <i className="fas fa-chevron-down"></i>
        </div>
      </div>
      <div className="NSecOneWhatB">
        <div className="NSecOneWhatBWrapper">
          <div className="customAccordianCon">
            <div className="tText">
              <h1>Our core fundamentals</h1>
              <p>
                Our approach towards building platform businesses and achieving
                value generating growth is deeply rooted in technological
                innovations of data science and extensive due-diligence
              </p>
            </div>
            <div className="aMain">
              {/* Scroll S */}
              <div
                className={
                  'acordianCardMScroll ' +
                  (posi === 1 && ' one ') +
                  (posi === 2 && ' two ') +
                  (posi === 3 && ' three ') +
                  (posi === 4 && ' four ')
                }
              >
                <div className="inScroll"></div>
              </div>
              {/* Right S */}
              <div className="acordianCardRCON ">
                {/* CARD */}
                <div
                  onClick={() => {
                    setPosi(1)
                  }}
                  className={'acordianCardR ' + (posi === 1 && ' active ')}
                >
                  <div className="acordianNum">
                    <h4>01</h4>
                  </div>
                  <div className="aTcon">
                    <h3>Research Scientists</h3>
                    <div className="pCon">
                      <p>
                        We are well equipped with scientific expertise as we
                        leverage data science, machine learning and other
                        cutting-edge technologies to empower our operations
                      </p>
                    </div>
                  </div>
                </div>
                {/* CARD */}
                <div
                  onClick={() => {
                    setPosi(2)
                  }}
                  className={'acordianCardR ' + (posi === 2 && ' active ')}
                >
                  <div className="acordianNum">
                    <h4>02</h4>
                  </div>
                  <div className="aTcon">
                    <h3>Cognitive Insights</h3>
                    <div className="pCon">
                      <p>
                        We utilize non-traditional, unique data-driven
                        approaches powered by advanced analytics to derive
                        quick, actionable insights for accurate decision-making
                      </p>
                    </div>
                  </div>
                </div>

                {/* CARD */}
                <div
                  onClick={() => {
                    setPosi(3)
                  }}
                  className={'acordianCardR ' + (posi === 3 && ' active ')}
                >
                  <div className="acordianNum">
                    <h4>03</h4>
                  </div>
                  <div className="aTcon">
                    <h3>Due Diligence Team</h3>
                    <div className="pCon">
                      <p>
                        Our in-house due diligence team makes use of advanced
                        scientific approaches to plan the integration, identify
                        unknown risks and verify the viability of investments
                      </p>
                    </div>
                  </div>
                </div>

                {/* CARD */}
                <div
                  onClick={() => {
                    setPosi(4)
                  }}
                  className={'acordianCardR ' + (posi === 4 && ' active ')}
                >
                  <div className="acordianNum">
                    <h4>04</h4>
                  </div>
                  <div className="aTcon">
                    <h3>Investors</h3>
                    <div className="pCon">
                      <p>
                        We leverage the potential of leading platform
                        technologies to uncover opportunities and provide our
                        partners with competitive advantage in the digital
                        landscape
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cAimgCOn">
            <div className="cAimgCOnLWin1"></div>
            <div className="cAimgCOnLWin2"></div>
            <div className="cAimgCOnLWin3"></div>
            <div className="cAimgCOnL1"></div>
            <div className="cAimgCOnL2"></div>
            <div className="cAimgCOnL3"></div>
            <div className="cAimgCOnL4"></div>
            <img className="wbr" src={wbr} alt="" />
          </div>
          <img className="wbrm" src={wbrm} alt="" />
          <div
            className={
              'mobCoreFundamanetals ' +
              (posi === 1 && ' one ') +
              (posi === 2 && ' two ') +
              (posi === 3 && ' three ') +
              (posi === 4 && ' four ')
            }
          >
            {/* CS */}
            <div className="mcfC">
              <div className="mcfCL">
                <div className="mcfLINE"></div>
              </div>
              <div className="mcfCR">
                <div className="numCo">01</div>
                <div className="headT">Research Scientists</div>
                <div className="paraT">
                  We are well equipped with scientific expertise as we leverage
                  data science, machine learning and other cutting-edge
                  technologies to empower our operations
                </div>
              </div>
            </div>
            {/* CE */}

            {/* CS */}
            <div className="mcfC">
              <div className="mcfCL">
                <div className="mcfLINE"></div>
              </div>
              <div className="mcfCR">
                <div className="numCo">02</div>
                <div className="headT">Cognitive Insights</div>
                <div className="paraT">
                  We utilize non-traditional, unique data-driven approaches
                  powered by advanced analytics to derive quick, actionable
                  insights for accurate decision-making
                </div>
              </div>
            </div>
            {/* CE */}

            {/* CS */}
            <div className="mcfC">
              <div className="mcfCL">
                <div className="mcfLINE"></div>
              </div>
              <div className="mcfCR">
                <div className="numCo">03</div>
                <div className="headT">Due Diligence Team</div>
                <div className="paraT">
                  Our in-house due diligence team makes use of advanced
                  scientific approaches to plan the integration, identify
                  unknown risks and verify the viability of investments
                </div>
              </div>
            </div>
            {/* CE */}
            {/* CS */}
            <div className="mcfC">
              <div className="mcfCL">
                <div className="mcfLINE"></div>
              </div>
              <div className="mcfCR">
                <div className="numCo">04</div>
                <div className="headT">Investors</div>
                <div className="paraT">
                  We leverage the potential of leading platform technologies to
                  uncover opportunities and provide our partners with
                  competitive advantage in the digital landscape
                </div>
              </div>
            </div>
            {/* CE */}
          </div>
          <div
            className={
              'bmCFCon mobC ' +
              (posi === 1 && ' one ') +
              (posi === 2 && ' two ') +
              (posi === 3 && ' three ') +
              (posi === 4 && ' four ')
            }
          >
            <div className="posiS">
              <div className="posiSi"></div>
            </div>
            <div className="BtnCons6n">
              <img onClick={leftA} className="aLimg" src={rArrow} alt="" />
              <img onClick={rightA} className="aRimg" src={rArrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NSecOneWhat

// <div className="acordianCardL">
// <h4>01</h4>
// </div>
// <div className="acordianCardM"></div>
