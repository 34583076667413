import React from 'react'
import Footer from './../components/SectionsLP/Footer'
import Header from './../components/Header'
import pageImg from './../assets/PAGESIMG/Digitrust1.png'
import pageImgTwo from './../assets/PAGESIMG/Digitrust2.png'
import Features from './../components/Features/Features'
import HowDigitrust from '../newPages/Digitrust/HowDigitrust'
import DigiTrustHero from './../newPages/Digitrust/DigiTrustHero'
import DigiTrustTaxes from './../newPages/Digitrust/DigiTrustTaxes'

const MultiSignatureWallet = () => {
  return (
    <div className='MultiSignatureWallet'>
      <Header active="1" />
      {/* <img src={pageImg} alt="" className="w-100" /> */}
      <DigiTrustHero />
      <Features />
      <HowDigitrust />
      {/* <img src={pageImgTwo} alt="" className="w-100" /> */}
      <DigiTrustTaxes />
      <Footer />
    </div>
  )
}

export default MultiSignatureWallet
