import React, { useState, useEffect } from 'react'
import './NSecFiveWhat.scss'
import ClientCard from './../../allCards/ClientCard'
import c1 from './../../../assets/LP/clients/1.png'
import c2 from './../../../assets/LP/clients/2.png'
import c3 from './../../../assets/LP/clients/b1.png'
import c4 from './../../../assets/LP/clients/b2.png'
import rArrow from './../../../assets/LP/rArrow.svg'
import ResizeObserver, { useResizeDetector } from 'react-resize-detector'
const NSecFiveWhat = () => {
  const [posi, setPosi] = useState(1)
  let maxS = 2
  const { width, height, ref } = useResizeDetector()
  useEffect(() => {
    if (width < 1190) {
      maxS = 2
    }

    if (width <= 701) {
      maxS = 2
    }
  })
  function leftA(e) {
    e.preventDefault()
    // setPosi(posi == 1 ? 4 : posi - 1)
    setPosi(posi == 1 ? maxS : posi - 1)
  }
  function rightA(e) {
    e.preventDefault()
    // setPosi(posi == 4 ? 1 : posi + 1)
    setPosi(posi == maxS ? 1 : posi + 1)
  }

  return (
    <div ref={ref} className="NSecFiveWhatMain">
      <div className="NSecFiveWhatWrapper">
        <div  data-aos="fade-up" className="NSecFiveWhatT">
          <h1>Our Partners</h1>
          <p>
            Our platform connects the world of commercial real estate by mapping data and insights from our data-backed partners to create value added approach and help in reaping strong investment returns.
          </p>
        </div>
      </div>

      <div className="CSlider">
        <div
       
          className={
            'CSliderT  ' +
            (posi == 1 && ' one ') +
            (posi == 2 && ' two ') +
            (posi == 3 && ' three ') +
            (posi == 4 && ' four ') +
            (posi == 5 && ' five ') +
            (posi == 6 && ' six ')
          }
        >
          {/* <ClientCard
            i1={c1}
            title="Avalon Communities"
            subHead="Guy Hawkins"
          />
          <ClientCard i1={c2} title="Simon Malls" subHead="Jacob Jones" /> */}
          <ClientCard
                i1={c4}
                title="First American "
                subHead=""
                content="First American Title Insurance Company facilitates and streamlines real estate transactions by providing comprehensive title insurance protection and professional settlement services."
              />

              <ClientCard
                i1={c3}
                title="Trepp"
                subHead=""
                content=" Trepp is a leading provider of information, analytics, and services to the Structured Finance, Commercial Real Estate, and Banking markets."
              />
        </div>
        <div data-aos="zoom-in" className="CSliderB">
          <div className="BtnCons6n">
            <img onClick={leftA} className="aLimg" src={rArrow} alt="" />
            <img onClick={rightA} className="aRimg" src={rArrow} alt="" />
          </div>
          <div
            className={
              'PosiScrollI  ' +
              (posi == 1 && ' one ') +
              (posi == 2 && ' two ') +
              (posi == 3 && ' three ') +
              (posi == 4 && ' four ') +
              (posi == 5 && ' five ') +
              (posi == 6 && ' six ')
            }
          >
            <div className="PosiScrollIinside"></div>
          </div>
          <button className="MainGetStartedBTNNB hiB">
            Show All{' '}
            <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default NSecFiveWhat
