import React, { useState, useEffect, useRef } from 'react'

import './SecThreeWho.scss'
import people from './../../assets/who/cards/people.png'
import Investor from './../../assets/who/cards/Investor.png'
import Y2013 from './../../assets/who/timeline/2013.svg'
import Y2016 from './../../assets/who/timeline/2016.svg'
import Y2018 from './../../assets/who/timeline/2018.svg'
import Y2021 from './../../assets/who/timeline/2021.svg'
import Y2013i from './../../assets/who/timeline/timelineOne.png'
import Y2016i1 from './../../assets/who/timeline/timelineThree.svg'
import Y2016i2 from './../../assets/who/timeline/timelineTwo.svg'
import rArrow from './../../assets/LP/rArrow.svg'
const SecThreeWho = () => {
  const [slider, setSlider] = useState(2)

  function rightA(e) {
    e.preventDefault()
    setSlider(slider == 4 ? 1 : slider + 1)
  }

  function leftA(e) {
    e.preventDefault()
    setSlider(slider == 1 ? 4 : slider - 1)
  }
  return (
    <div className="sectionThreeWho">
      <div className="sectionThreeWhoWrapper">
        <div data-aos="fade-up" className="sectionThreeWhoWrapperTop">
          <h1>We have taken several major strides</h1>
          <p>
            From creating our Maryland base office to managing a large network
            of commercial real estate companies and businesses, we have come a
            long way
          </p>
        </div>
      </div>
      {/* FOR MOBILE */}
      <div
        className={
          'TimeLineSliderNEWMOB ' +
          (slider === 1 && ' posiOne ') +
          (slider === 2 && ' posiTwo ') +
          (slider === 3 && ' posiThree ') +
          (slider === 4 && ' posiFour ')
        }
      >
        {/* MCARD START */}
        <div className={'tsCMobM ' + (slider === 1 && ' activeM ')}>
          <div className="mainMcMob"></div>

          <div className="inactiveC Rig"></div>
          <div className="mCMob">
            <div className="miCmob"></div>
          </div>
          <div className="mainMlMob"></div>
          <div className="tsCMobMtopS">
            <img src={Y2013} alt="" />
          </div>
          <div className="tsCMobMbtmS">
            <div className="txtCon">
              <p style={{ marginTop: '30px' }} className="p1m">
                Cognier Technology Group Founded by Thomas Lah Opens
              </p>
              <img
                style={{ marginTop: '-20px' }}
                className="i2m"
                src={Y2013i}
                alt="Y2013i"
              />
              <p style={{ marginTop: '-20px' }} className="p2m">
                Maryland base office
              </p>
            </div>
          </div>
        </div>
        {/* MCARD END */}

        {/* MCARD START */}
        <div className={'tsCMobM ' + (slider === 2 && ' activeM ')}>
          <div className="mainMcMob"></div>
          <div className="inactiveC Lef"></div>
          <div className="inactiveC Rig"></div>
          <div className="mCMob">
            <div className="miCmob"></div>
          </div>
          <div className="mainMlMob"></div>
          <div className="tsCMobMtopS">
            <img src={Y2016} alt="" />
          </div>
          <div className="tsCMobMbtmS">
            <div className="txtCon">
              <img
                className="i1m"
                style={{ marginTop: '20px' }}
                src={Y2016i2}
                alt="Y2013i"
              />
              <p style={{ marginTop: '-10px' }} className="p1m">
                Close its first institution fund
              </p>
              <img
                style={{ marginTop: '-10px' }}
                className="i2m"
                src={Y2016i1}
                alt="Y2013i"
              />
              <p style={{ marginTop: '-20px' }} className="p2m">
                Flagship product started development for commercial Real-Estate
              </p>
            </div>
          </div>
        </div>
        {/* MCARD END */}

        {/* MCARD START */}
        <div className={'tsCMobM ' + (slider === 3 && ' activeM ')}>
          <div className="mainMcMob"></div>
          <div className="inactiveC Lef"></div>
          <div className="inactiveC Rig"></div>
          <div className="mCMob">
            <div className="miCmob"></div>
          </div>
          <div className="mainMlMob"></div>
          <div className="tsCMobMtopS">
            <img src={Y2018} alt="" />
          </div>
          <div className="tsCMobMbtmS">
            <div className="txtCon">
              <p style={{ marginTop: '30px' }} className="p1m">
                Develop intensivetimelineFournetwork of (Commercial Real Estate) companies to
                assist portfolio companies growth{' '}
              </p>
              <p style={{ marginTop: '0px' }} className="p2m">
                Test began on Flagship product through (Commercial Real Estate) network
              </p>
            </div>
          </div>
        </div>
        {/* MCARD END */}

        {/* MCARD START */}
        <div className={'tsCMobM ' + (slider === 4 && ' activeM ')}>
          <div className="mainMcMob"></div>
          <div className="inactiveC Lef"></div>

          <div className="mCMob">
            <div className="miCmob"></div>
          </div>
          <div className="mainMlMob"></div>
          <div className="tsCMobMtopS">
            <img src={Y2021} alt="" />
          </div>
          <div className="tsCMobMbtmS">
            <div className="txtCon">
              <p style={{ marginTop: '30px' }} className="p1m">
                Develop intensive timeline Four network of (Commercial Real Estate) companies to
                assist portfolio companies growth
              </p>
            </div>
          </div>
        </div>
        {/* MCARD END */}
      </div>
      <div
    
        className={
          'mobBtnCONS ' +
          (slider === 1 && ' posiOne ') +
          (slider === 2 && ' posiTwo ') +
          (slider === 3 && ' posiThree ') +
          (slider === 4 && ' posiFour ')
        }
      >
        <div className="posiSM">
          <div className="posiSMI"></div>
        </div>
        <div className="BtnCons">
          <img onClick={leftA} className="aLimg" src={rArrow} alt="" />
          <img onClick={rightA} className="aRimg" src={rArrow} alt="" />
        </div>
      </div>
      <div className="TimeLineSliderNEW">
        <div className="BtnCons">
          <img onClick={leftA} className="aLimg" src={rArrow} alt="" />
          <img onClick={rightA} className="aRimg" src={rArrow} alt="" />
        </div>
        <div className="MAINmidLine"></div>

        {/* cardStart */}
        <div
          onClick={() => {
            setSlider(1)
          }}
          className={'TLSCard Y2013  ' + (slider === 1 && ' active ')}
        >
          <div className="midLine">
            <div className="midCircleO">
              <div className="midCircleI"></div>
            </div>
            <div className="MAINmidCircle"></div>
          </div>
          <div className="imgCon">
            <img src={Y2013} alt="Y2013" />
          </div>
          <div className="txtCon">
            <p style={{ marginTop: '30px' }} className="p1">
              Cognier Technology Group Founded by Thomas Lah Opens
            </p>
            <img
              style={{ marginTop: '-20px' }}
              className="i2"
              src={Y2013i}
              alt="Y2013i"
            />
            <p style={{ marginTop: '-20px' }} className="p2">
              Maryland base office
            </p>
          </div>
        </div>
        {/* cardEnd */}

        {/* cardStart */}
        <div
          onClick={() => {
            setSlider(2)
          }}
          className={'TLSCard Y2016  ' + (slider === 2 && ' active ')}
        >
          <div className="midLine">
            <div className="midCircleO">
              <div className="midCircleI"></div>
            </div>
            <div className="MAINmidCircle"></div>
          </div>
          <div className="imgCon">
            <img src={Y2016} alt="Y2016" />
          </div>
          <div className="txtCon">
            <img className="i1" src={Y2016i1} alt="" />
            <p className="p1">Close its first institution fund</p>
            <img className="i2" src={Y2016i2} alt="" />
            <p className="p2">
              Flagship product started development for commercial Real-Estate
            </p>
          </div>
        </div>
        {/* cardEnd */}
        {/* cardStart */}
        <div
          onClick={() => {
            setSlider(3)
          }}
          className={'TLSCard Y2018  ' + (slider === 3 && ' active ')}
        >
          <div className="midLine">
            <div className="midCircleO">
              <div className="midCircleI"></div>
            </div>
            <div className="MAINmidCircle"></div>
          </div>
          <div className="imgCon">
            <img src={Y2018} alt="Y2018" />
          </div>
          <div className="txtCon">
            <p style={{ marginTop: '30px' }} className="p1">
              Develop intensive network of (Commercial Real Estate) companies to assist
              portfolio companies growth{' '}
            </p>
            <p style={{ marginTop: '0px' }} className="p2">
              Test began on Flagship product through (Commercial Real Estate) network
            </p>
          </div>
        </div>
        {/* cardEnd */}

        {/* cardStart */}
        <div
          onClick={() => {
            setSlider(4)
          }}
          className={'TLSCard Y2021  ' + (slider === 4 && ' active ')}
        >
          <div className="midLine">
            <div className="midCircleO">
              <div className="midCircleI"></div>
            </div>
            <div className="MAINmidCircle"></div>
          </div>
          <div className="imgCon">
            <img src={Y2021} alt="Y2021" />
          </div>
          <div className="txtCon">
            <p style={{ marginTop: '30px' }} className="p1">
            Beta launch of Flagship product
            Scalability of internal and external resources{' '}
            </p>
          </div>
        </div>
        {/* cardEnd */}
      </div>
    </div>
  )
}

export default SecThreeWho
