import React, { useEffect, useState, useCallback } from 'react'
import './App.scss'
import MainRouter from './components/MainRouter'
import STT from './assets/STT.png'
import {
  VerticalScrollDistanceConsumer,
  VerticalScrollDistanceProvider,
} from '@react-utilities/vertical-scroll-distance-detector'
import { useSelector } from 'react-redux';
function App() {
  // const [y, setY] = useState(window.scrollY)
  const [scrollD, setScrollD] = useState()
  // const handleNavigationn = useCallback(
  //   (e) => {
  //     const window = e.currentTarget
  //     if (y > window.scrollY) {
  //       if (window.scrollY < 390) {
  //         setScrollD()
  //       }

  //       console.log('scrolling up : ' + window.scrollY)
  //     } else if (y < window.scrollY) {
  //       console.log('scrolling downnnnn : ' + window.scrollY)

  //       if (window.scrollY > 400) {
  //         setScrollD(true)
  //       }
  //     }
  //     setY(window.scrollY)
  //   },
  //   [y],
  // )

  // useEffect(() => {
  //   setY(window.scrollY)
  //   window.addEventListener('scroll', handleNavigationn)

  //   return () => {
  //     window.removeEventListener('scroll', handleNavigationn)
  //   }
  // }, [handleNavigationn])
  const current = useSelector((state) => state.current.current_page)
  return (
    <div className={`App ${current}`}>
      <div className="mainBody">
        <div className={'STTOPCON ' + (scrollD && ' active ')}>
          <VerticalScrollDistanceProvider>
            <VerticalScrollDistanceConsumer>
              {({ verticalScrollDistance }) => (
                <div className={'scroll-tracker ' +  (`${verticalScrollDistance}`>400?"active":"nA")  }>
                  <img
                    onClick={() => {
                      window.scrollTo(0, 0)
                    }}
                    src={STT}
                    alt=""
                  />
                  {/* {verticalScrollDistance} */}
                </div>
              )}
            </VerticalScrollDistanceConsumer>
          </VerticalScrollDistanceProvider>
        </div>
        <MainRouter />
      </div>
    </div>
  )
}

export default App
