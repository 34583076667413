import React from 'react'
import Footer from './../components/SectionsLP/Footer'
import Header from './../components/Header'
import pageImg from './../assets/PAGESIMG/whitelabelsolution1.png'
import pageImgTwo from './../assets/PAGESIMG/whitelabelsolution2.png'
import VerticalTabs from './../components/Sliders/VerticalTabs'
import WLTrustHero from '../newPages/WhiteLabel/WLTrustHero'
import Tokenize1 from './../assets/white-label/1.png'
import Tokenize2 from './../assets/white-label/2.png'
import Tokenize3 from './../assets/white-label/3.png'
import Feature1 from './../assets/trust-token/wl-1.png'
import Feature2 from './../assets/trust-token/wl-2.png'
import Feature3 from './../assets/trust-token/wl-3.png'
import { MultiText } from '../components/Utils'
import FeatureConnecter1 from './../assets/trust-token/feature-connect-1.svg'
import FeatureConnecter2 from './../assets/trust-token/feature-connect-2.svg'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
const WhiteLablePage = () => {
  const CardPrevBtn = ({ children }) => {
    const swiper = useSwiper()
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slidePrev()}>
        {children}
      </button>
    )
  }

  const CardNextBtn = ({ children }) => {
    const swiper = useSwiper()
    return (
      <button className="swipe-card-btn" onClick={() => swiper.slideNext()}>
        {children}
      </button>
    )
  }

  console.log(window.innerWidth);

  return (
    <div className="whitelabel">
      <Header active="1" />
      {/* <img src={pageImg} alt="" className="w-100" /> */}
      <WLTrustHero />
      <div className="HowDigitrust-Main TrustToken-Main">
        <div className="HowDigitrust-T">
          <div className="Tcontainer">
            <h1>Whitelabel Products</h1>
            <h2>
              Automate workflow, improve liquidity, manage assets and tokens,
              and increase returns by leveraging our exceptional tools and
              products.
            </h2>
          </div>
        </div>
        <div className="Feature Feature-Desktop">
          <div className="Feature-col">
            <div className="Feature-img Feature-img1">
              <img src={Feature1} alt="" />
              <img src={FeatureConnecter1} alt="" className="connector1" />
            </div>
            <div className="Feature-col-inner">
              <h3>Wallet-as-a-Service</h3>
              <p>
                Enhance operational efficiency by leveraging our highly secure
                ERC-1155 wallet. DigiTrust Wallet provides you with a complete
                overview of your portfolio performance. It also facilitates
                multiple wallet integration along with both Hot Wallet and Cold
                Wallet features to protect assets from market volatility and
                provide returns in the long term.
              </p>
            </div>
          </div>
          <div className="Feature-col sec">
            <div className="Feature-img Feature-img2">
              <img src={Feature2} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>Permissioned Distributed Ledger Network </h3>
              <p>
                Increase legitimacy and security in your deals and transactions
                with our Permissioned Blockchain Network platform built on
                private quorum network. Our Permissioned DeFi market ensures
                that the identity of all participants is KYC/KYB certified and
                AML compliant. Thus, allowing institutions, investors, and
                participants to access and move funds effortlessly.
              </p>
            </div>
          </div>
          <div className="Feature-col">
            <div className="Feature-img Feature-img3">
              <img src={Feature3} alt="" />
              <img src={FeatureConnecter2} alt="" className="connector2" />
            </div>
            <div className="Feature-col-inner">
              <h3>Tokenized Assets</h3>
              <p>
                Cognier Technology Group allows you to create Trust Tokens
                (unique tokens/NFTs) backed by real assets that are listed as
                shares for investors to trade. Our token engine oversees the
                minting, transferring, and burning of Trust Tokens by leveraging
                smart contracts and also ensures the security of these tokens
                while transfering to or from counterparties.
              </p>
            </div>
          </div>
        </div>
        <Swiper
          loop={true}
          slidesPerView={window.innerWidth < 330 ? 1 : 1.3}
          spaceBetween={15}
          centeredSlides={true}
          className="Feature Feature-mob"
        >
          <SwiperSlide className="Feature-col">
            <div className="Feature-img">
              <img src={Feature1} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>Wallet-as-a-Service</h3>
              <p>
                Enhance operational efficiency by leveraging our highly secure
                ERC-1155 wallet. DigiTrust Wallet provides you with a complete
                overview of your portfolio performance. It also facilitates
                multiple wallet integration along with both Hot Wallet and Cold
                Wallet features to protect assets from market volatility and
                provide returns in the long term.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="Feature-col">
            <div className="Feature-img">
              <img src={Feature2} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>Permissioned Distributed Ledger Network </h3>
              <p>
                Increase legitimacy and security in your deals and transactions
                with our Permissioned Blockchain Network platform built on
                private quorum network. Our Permissioned DeFi market ensures
                that the identity of all participants is KYC/KYB certified and
                AML compliant. Thus, allowing institutions, investors, and
                participants to access and move funds effortlessly.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="Feature-col">
            <div className="Feature-img">
              <img src={Feature3} alt="" />
            </div>
            <div className="Feature-col-inner">
              <h3>Tokenized Assets</h3>
              <p>
                Cognier Technology Group allows you to create Trust Tokens
                (unique tokens/NFTs) backed by real assets that are listed as
                shares for investors to trade. Our token engine oversees the
                minting, transferring, and burning of Trust Tokens by leveraging
                smart contracts and also ensures the security of these tokens
                while transfering to or from counterparties.
              </p>
            </div>
          </SwiperSlide>

          <div className="custom-card-nav d-flex justify-content-between">
            <CardPrevBtn>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.02344 9.99865L13.1484 5.87365L11.9701 4.69531L6.66677 9.99865L11.9701 15.302L13.1484 14.1236L9.02344 9.99865Z"
                  fill="currentcolor"
                />
              </svg>
            </CardPrevBtn>
            <CardNextBtn>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.9766 9.99865L6.85156 5.87365L8.0299 4.69531L13.3332 9.99865L8.0299 15.302L6.85156 14.1236L10.9766 9.99865Z"
                  fill="currentcolor"
                />
              </svg>
            </CardNextBtn>
          </div>
        </Swiper>
      </div>
      <VerticalTabs />
      {/* <img src={pageImgTwo} alt="" className="w-100" /> */}
      <div className="tokenize">
        <div className="tokenize-inner">
          <div className="HowDigitrust-T">
            <div className="Tcontainer">
              <MultiText
                h1="Manage, Invest, and Trade with our"
                span="Whitelabel Solutions"
                h1Size="36px"
                boxSize="490px"
              />
            </div>
          </div>
          <div className="tokenize-row">
            <div className="tokenize-col">
              <img src={Tokenize1} alt="" />
              <div className="tokenize-col-inner modifiedInner">
                <h3 style={{ marginBottom: '20px' }}>Manage portfolio</h3>
                <p>
                Monitor your investment portfolio and receive regular updates with our all-in-one dashboard and make the most of your investment. 
                </p>
              </div>
            </div>
            <div className="tokenize-col sec">
              <img src={Tokenize2} alt="" />
              <div className="tokenize-col-inner modifiedInner">
                <h3 style={{ marginBottom: '20px' }}>Attract Investors</h3>
                <p>
                Get investors onboard easily by providing diversified assets and customizing investment opportunities.
                </p>
              </div>
            </div>
            <div className="tokenize-col third">
              <img src={Tokenize3} alt="" />
              <div className="tokenize-col-inner modifiedInner">
                <h3 style={{ marginBottom: '20px' }}>Peer-to-peer token trading</h3>
                <p>
                Make your marketing campaigns more successful using website demographics and actionable engagement metrics that help you enhance the relevance of your ads
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default WhiteLablePage