import React from 'react'
import './SecOneND.scss'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import Header from './../Header'
// import * as ReactBootstrap from "react-bootstrap";
import Loader from './../Loader/Loader'

const SecOneND = () => {
  const ndR = useSelector((state) => state.ndR)
  const [newsSummary, setNewsSummary] = useState('')
  const [newsTitle, setNewsTitle] = useState('')

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)


    async function getData() {
      const res = await axios.get(
        `https://business.lilypads.com/wp-json/wp/v2/posts?per_page=100&_embed`,
      )
      setLoading(true)

      setNewsTitle(res.data[`${ndR}`].title.rendered)
      setNewsSummary(res.data[`${ndR}`].content.rendered)
    }
    getData()
  }, [])

  return (
    <div className="SectionOneND">
      <Header active="6" />

      {!loading ? (
        <Loader />
      ) : (
        <>
          <div className="NDTitle">
            <h2>{newsTitle}</h2>
          </div>
          <div className="NDcontent">
            <div dangerouslySetInnerHTML={{ __html: newsSummary }}></div>
          </div>
        </>
      )}
      {/* <div className="NDTitle">
        <h2>{newsTitle}</h2>
      </div>
      <div className="NDcontent">
        <div dangerouslySetInnerHTML={{ __html: newsSummary }}></div>
      </div> */}

 
    </div>
  )
}

export default SecOneND

// <div className="NDTitle">
// <h2>{newsTitle}</h2>
// </div>
// <div className="NDcontent">
// <div dangerouslySetInnerHTML={{ __html: newsSummary }}></div>
// </div>
