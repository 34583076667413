import React, { useEffect, useState } from 'react'

import 'aos/dist/aos.css'
import Header from './../Header'

import './SecOneWho.scss'

import whoBG from './../../assets/who/whoBG.png'
import etl from './../../assets/who/etl.png'
import etr from './../../assets/who/etr.png'
import miL from './../../assets/who/miL.png'
import miR from './../../assets/who/miR.png'
import { TweenMax, TimelineLite, Power3, Power0 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import oneT from './../../assets/LP/ListImg/01.svg'
import twoT from './../../assets/LP/ListImg/02.svg'
import threeT from './../../assets/LP/ListImg/03.svg'
import fourT from './../../assets/LP/ListImg/04.svg'
import gsap from 'gsap'
import CI from './../../assets/C.png'
import demoImg from './../../assets/Demo.png'

gsap.registerPlugin(ScrollTrigger)

const SecOneWho = () => {
  const [rightHS, setRightHS] = useState(false)
  const [leftHS, setLeftHS] = useState(false)
  const [disabled, setDisabled] = useState(false)
  let tl = new TimelineLite({ delay: 0.4 })
  useEffect(() => {
    window.scrollTo(0, 0)
    // tl.from(
    //   '.backgroundBuilding ',
    //   1.5,
    //   { y: '190px', ease: Power3.easeOut },
    //   'Start',
    // )
    //   .from('.mLeft .mIMG', 1.5, { x: '15px', ease: Power3.easeOut }, 0.1)
    //   .from('.mRight .mIMG', 1.5, { x: '-15px', ease: Power3.easeOut }, 0.1)
    //   .from('.mRight h3', 1.5, { y: '45px', ease: Power3.easeOut }, 0.9)
    //   .from('.mLeft h3', 1.5, { y: '45px', ease: Power3.easeOut }, 0.9)
    //   .from('.mLeft h4', 2.5, { y: '350px', ease: Power3.easeOut }, 1.3)
    //   .from('.mRight h4', 2.5, { y: '350px', ease: Power3.easeOut }, 1.3)
    //   .from(
    //     '.sectionOneWhoBTM h1',
    //     2.5,
    //     { y: '350px', ease: Power3.easeOut },
    //     1.5,
    //   )
    //   .from(
    //     '.sectionOneWhoBTM h2',
    //     2.5,
    //     { y: '350px', ease: Power3.easeOut },
    //     1.5,
    //   )
    tl.from(
      '.backgroundBuilding ',
      1.5,
      { y: '190px', ease: Power3.easeOut },
      'Start',
    )
      .from('.hoverNCR ', 0.5, { right: '50%' }, 0.1)
      .from('.hoverNCL ', 0.5, { left: '50%' }, 0.1)
      .from('.hTL .Hmtcon h3', 1.5, { y: '165px', ease: Power3.easeOut }, 0.9)
      .from('.hTR .Hmtcon h3', 1.5, { y: '165px', ease: Power3.easeOut }, 1.2)
      .from('.hTL .Hmtcon h4', 1.5, { y: '205px' }, 1.7)
      .from('.hTR .Hmtcon h4', 1.5, { y: '205px' }, 2.0)
  }, [])
  useEffect(() => {})
  const disableMenu = () => {
    setDisabled(!disabled)
    setTimeout(() => {
      setDisabled(false)
    }, 900)
  }

  const HoverrightActive = () => {
    setRightHS(true)
    disableMenu()
  }

  const HoverrightDeActive = () => {
    if (disabled == false) {
      setRightHS(false)
    } else if (disabled == true) {
      setTimeout(() => {
        setRightHS(false)
      }, 900)
    }
  }

  const HoverLeftActive = () => {
    setLeftHS(true)
    disableMenu()
  }

  const HoverLeftDeActive = () => {
    if (disabled == false) {
      setLeftHS(false)
    } else if (disabled == true) {
      setTimeout(() => {
        setLeftHS(false)
      }, 900)
    }
  }

  return (
    <div className="sectionOneWhoWhole">
      <Header active="2" trigger={leftHS} trigger2={rightHS}   />
      <div className="sectionOneWho">
        <div className="staticImgCon">
          <img className="staticIL" src={etl} alt="" />
          <img className="staticIR" src={etr} alt="" />
        </div>

        <div
          onMouseOver={HoverLeftActive}
          onMouseOut={HoverLeftDeActive}
          className={'hTL ' + (leftHS && ' active ') + ' DESKV '}
        >
          <div className="Hmtcon   DESKV ">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h3>Vision</h3>
            </div>
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h4>
                Our vision in a world of business monotone is to coalesce
                leading-edge, dependable innovations in technology with
                extraordinary investment opportunities in real estate
              </h4>
            </div>
          </div>
        </div>
        <div
          onMouseOver={HoverrightActive}
          onMouseOut={HoverrightDeActive}
          className={'hTR ' + (rightHS && ' active ') + ' DESKV '}
        >
          <div className="Hmtcon   DESKV ">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h3>Mission</h3>
            </div>
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h4>
                Our real estate investment partners utilize our research-led
                data science approach and platform technologies that align with
                their core commitment to reap higher returns in the digital
                economy
              </h4>
            </div>
          </div>
        </div>
        <div
          onMouseOver={HoverrightActive}
          onMouseOut={HoverrightDeActive}
          className={'hoverNCL ' + (rightHS && ' active ') + ' DESKV '}
        >
          <img className="mIMG rightTI rIMG  DESKV " src={miR} alt="" />
        </div>

        <div
          onMouseOver={HoverLeftActive}
          onMouseOut={HoverLeftDeActive}
          className={'hoverNCR ' + (leftHS && ' active ') + ' DESKV '}
        >
          <img className="mIMG rightTI  +  DESKV " src={miL} alt="" />
        </div>

        <div className="uMHcon">
          <div className="mtcon">
            <h3>Vision</h3>
            <div className="umhcL"></div>
            <h4>
              Our vision in a world of business monotone is to coalesce
              leading-edge, dependable innovations in technology with
              extraordinary investment opportunities in real estate
            </h4>
          </div>
          <div className="mtconR">
            <h3 style={{ marginTop: '30px' }}>Mission</h3>
            <div className="umhcL"></div>
            <h4>
              Our real estate investment partners utilize our research-led data
              science approach and platform technologies that align with their
              core commitment to reap higher returns in the digital economy
            </h4>
          </div>
        </div>
        <div className="backgroundBuilding">
          <img src={whoBG} alt="" />
        </div>
        <div className="sectionOneWhoBTMMOB">
          <img className="sectionOneWhoBTMMOB_BG_IMG" src={CI} alt="" />
          <img className="sectionOneWhoBTMMOB_BG_IMG_R" src={CI} alt="" />
          <div data-aos="fade-up" className="sectionOneWhoBTMMOBTOp">
            <h1>We bring science to commercial real estate investing</h1>
            <p>
            Cognier Technology implements research-backed data science approach and advanced
              platform technology innovations to aim for long-term growth and
              stand out in the crowd
            </p>
          </div>
          <div className="sectionOneWhoBTMMOBCARDS">
            <div className="soBC">
              <img src={oneT} alt="" />
              <h1>Partnership</h1>
              <p>
                Cultivate long-term association with our partners through
                successive investments
              </p>
            </div>

            <div className="soBC">
              <img src={twoT} alt="" />
              <h1>Research</h1>
              <p>
                We thrive on our commitment to unlock untapped opportunities
              </p>
            </div>
            <div className="soBC">
              <img src={threeT} alt="" />
              <h1>Progress</h1>
              <p>
                We utilize our vast expertise in data science innovations for
                future-proof advancement
              </p>
            </div>
            <div className="soBC">
              <img src={fourT} alt="" />
              <h1>Value Generation</h1>
              <p>
                Our approach is scalable to the extent of unlocking superlative
                value for our clients
              </p>
            </div>
          </div>
          <div className="sectionOneWhoBTMMOBBTM">
            <h1>Our Strategies</h1>
            <p>
              Cognier Technology Group embodies unparalleled strategies that act
              as a blueprint for securing steady yields{' '}
            </p>
          </div>
        </div>
        <div
          className={
            ' sectionOneWhoBTM ' +
            (leftHS && ' active ') +
            (rightHS && ' active ')
          }
        >
          <div className="sectionOneWhoBTML">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h1 style={{ color: '#fff' }}>
                We bring science to commercial real estate investing
              </h1>
            </div>
          </div>
          <div className="sectionOneWhoBTMR">
            <div style={{ overflow: 'hidden' }} className="ofhcon">
              <h2>
                Cognier Technology Group implements research-backed data science
                approach and advanced platform technology innovations to aim for
                long-term growth and stand out in the crowd
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecOneWho
