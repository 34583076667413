import React, { useEffect, useState } from 'react'
import Header from './../Header'
import appBG from './../../assets/ApproachPage/top/bg.png'
import txtI from './../../assets/ApproachPage/top/txt.png'
import bgCircle from './../../assets/ApproachPage/bgC.svg'
import './SecOneAP.scss'
import SecTwoAP from './SecTwoAP'
import SecThreeAP from './SecThreeAP'
import mb from './../../assets/tech/mb.png';
import mt from './../../assets/tech/mt.png';
import { TweenMax, TimelineLite, Power3, Power0 } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'
gsap.registerPlugin(ScrollTrigger)
const SecOneAP = () => {
  let tl = new TimelineLite({ delay: 0.4 })
  useEffect(() => {
    window.scrollTo(0, 0)
    tl.from('.sectionOneapproachBTML h1 ', 1.5, { y: '190px', ease: Power3.easeOut  }, 'Start')
   .from('.sectionOneapproachBTMR .dV', 1.5, {   y: '190px', ease: Power3.easeOut },0.3)
    
  })
  return (
    <div className="sectionOneAP">
      <Header active="7" />
      <div className="bgCircleCon">
        <img src={bgCircle} alt="bgCircle" />
      </div>
      <div className="sectionOneapproach">
        <div className="backgroundBuilding">
          <img src={appBG} alt="" />
        </div>
        <div className="sectionOneapproachBTM">
          <div style={{overflow: 'hidden'}} className="sectionOneapproachBTML">
            <h1>
              <span>Pathbreaking technology</span> that accomodates your
              investment needs in cRE
            </h1>
          </div>
          <div style={{overflow: 'hidden'}} className="sectionOneapproachBTMR">
            <img className="dV" src={txtI} alt="" />
            <img className="mvT" src={mt} alt="" />
            <img className="mvT" src={mb} alt="" />
          </div>
        </div>
      </div>

      <SecTwoAP />
      <SecThreeAP />
    </div>
  )
}

export default SecOneAP
