import React, { useEffect } from 'react'
import LandingPage from './../pages/LandingPage'
import CareerListingPage from './../pages/CareerListingPage'
import WhoPage from './../pages/WhoPage'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ApproachPage from './../pages/ApproachPage'
import WhatPage from './../pages/WhatPage'
import JobDetailPage from './../pages/JobDetailPage'
import NewsListingPage from './../pages/NewsListingPage'
import NewsDetailPage from './../pages/NewsDetailPage'

import ApplicationForm from '../pages/ApplicationForm'
import TermsAndConditionPage from './../pages/TermsAndConditionPage'
import PrivacyPolicypage from './../pages/PrivacyPolicypage'
import ContactForm from './../pages/ContactForm'
import NewsList from './SectionNewsListing/NewsList'

import Aos from 'aos'
import TechnologyPage from './../pages/TechnologyPage'
// import Home from '../pages/new/Home/Home'
// import Home from './../pages/new/Home/Home';

import DealRoom from '../pages/KycAml'


import TrustToken from './../pages/TrustToken'
import SlidersComp from './Sliders/SlidersComp'
import WhiteLablePage from './../pages/WhiteLablePage'
import SecuritiesPage from './../pages/SecuritiesPage'
import MultiSignatureWallet from './../pages/MultiSignatureWallet';
import BlueBlockNet from './../pages/BlueBlockNet';
import KycAml from './../pages/KycAml';
import Securex from './../pages/Securex';
import Tokenization from './../pages/Tokenization';
import { useSelector } from 'react-redux';
import Test from './../pages/Test';

const MainRouter = () => {


  const current = useSelector((state) => state.current.current_page)



  useEffect(() => {
    Aos.init({ duration: 1300 })
  }, [])
  return (
    <div className={`mainCompWhole`}>
      <Router>
        <Switch>
          <Route exact path={'/'}>
            <LandingPage />
          </Route>
          <Route path={'/Careers'}>
            <CareerListingPage />
          </Route>
          <Route path={'/Technology'}>
            <TechnologyPage />
          </Route>
          <Route path={'/Who'}>
            <WhoPage />
          </Route>
          <Route path={'/What'}>
            <WhatPage />
          </Route>
          <Route path={'/Approach'}>
            <ApproachPage />
          </Route>
          <Route path={'/JobDetails'}>
            <JobDetailPage />
          </Route>
          <Route path={'/News'}>
            <NewsListingPage />
          </Route>
          <Route path={'/NewsDetails'}>
            <NewsDetailPage />
          </Route>
          <Route path={'/Application'}>
            <ApplicationForm />
          </Route>
          <Route path={'/TermsAndCondition'}>
            <TermsAndConditionPage />
          </Route>
          <Route path={'/PrivacyPolicy'}>
            <PrivacyPolicypage />
          </Route>
          <Route path={'/Contact'}>
            <ContactForm />
          </Route>
          <Route path={'/NewsList'}>
            <NewsList />
          </Route>
          {/* DONT MESS WITH THE OLD ROUTES ,ADD YOUR OWN BELOW */}
          <Route path={'/kyc-aml'}>
            <KycAml/>
          </Route>
          <Route path={'/blue-block-network'}>
            <BlueBlockNet/>
          </Route>
          <Route path={'/TrustToken'}>
            <TrustToken />
          </Route>
          <Route path={'/Tokenization'}>
            <Tokenization/>
          </Route>
          <Route path={'/whiteLabelSolution'}>
            <WhiteLablePage />
          </Route>
          <Route path={'/securix'}>
            <Securex/>
          </Route>
          <Route path={'/multi-signature-wallet'}>
            <MultiSignatureWallet/>
          </Route>
          <Route path={'/test'}>
            <Test/>
          </Route>
          <Route path={'/SlidersComp'}>
            <SlidersComp />
          </Route>{' '}
          <Route path={'/Securities'}>
            <SecuritiesPage />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default MainRouter
