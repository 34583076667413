import React from "react";
import Tooltip from '@material-ui/core/Tooltip';

const Test = () => {
  return (
    <div>
      <Tooltip
        title="Tooltip text"
        placement="top"
        disablePortal={true}
        PopperProps={{ positionFixed: true }}
      >
        <button>Hover me</button>
      </Tooltip>
      hi
    </div>
  );
};

export default Test;
