import React from 'react'
import './AllCard.scss'
const WhatCtgCard = (props) => {
  return (
    <div className="whatCtgCard">
      <div className="whatCtgCardL">
        <h4> {props.number}.</h4>
      </div>
      <div className="whatCtgCardR">
        <h1>{props.title}</h1>
        <p>{props.content}</p>
      </div>
    </div>
  )
}

export default WhatCtgCard
