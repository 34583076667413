import React from 'react'
import './NSecThreeAP.scss'
import tck from './../../../assets/ApproachPage/new/tck.png'
import c1 from './../../../assets/ApproachPage/new/c1.png'
import c2 from './../../../assets/ApproachPage/new/c2.png'
import c3 from './../../../assets/ApproachPage/new/c3.png'
import mi1 from './../../../assets/ApproachPage/new/mi1.png'
import mi1m from './../../../assets/ApproachPage/new/mi1m.png'
import mi2 from './../../../assets/ApproachPage/new/mi2.png'
import mi2m from './../../../assets/ApproachPage/new/mi2m.png'
import NSecFourAP from './../../SectionWhat/newWhat/NSecFourAP';
const NSecThreeAP = () => {
  return (
    <div className="NSecThreeAPM">
      <div className="NSecThreeAPMC">
        <div data-aos="fade-right" className="NSecThreeAPMCL">
          <h2>Set off your journey as a lilypads <span>Investor</span></h2>
          <div className="listCon">
            <img src={tck} alt="" />
            <p>
              Everyday new investment-grade properties are added to the
              marketplace, investors join the network and resources are shared
              throughout the platform.
            </p>
          </div>
          <div className="listCon">
            <img src={tck} alt="" />
            <p>Lilypads helps you turn an Opportunity into transaction.</p>
          </div>
          <div className="listCon">
            <img src={tck} alt="" />
            <p>
              Connect with the real estate investing world by becoming a part of
              the Lilypads platform.
            </p>
          </div>
          <div className="ListImCon">
            <div className="ListImConW">
              <img src={c2} alt="" />
              <h3>Quickly Sell Properties</h3>
            </div>
            <div className="ListImConW">
              <img src={c1} alt="" />
              <h3>Find Investment Properties</h3>
            </div>
            <div className="ListImConW">
              <img src={c3} alt="" />
              <h3>Private Funding</h3>
            </div>
          </div>
          <button onClick={()=> window.open("https://cogniertechnology.com/contact", "_blank")} className="MainGetStartedBTNNB btnHideM">
            Start as an Invester now{' '}
            <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i>
          </button>
        </div>
        <div data-aos="fade-left" className="NSecThreeAPMCR">
          <img  className="dImg" src={mi1} alt="" />
        </div>
        <img  style={{marginBottom:"40px"}} className="mobImg" src={mi1m} alt="" />
        <button className="MainGetStartedBTN mobbtn fwb">Start as an Invester now  <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i></button>

      </div>

      {/* TWO */}
{/*
      <div className="NSecThreeAPMC sTwo">
        <div className="NSecThreeAPMCR">
          <img  className="dImg" src={mi2} alt="" />
        </div>
        <div className="NSecThreeAPMCL">
          <h2>Join us as a <span>Credit Partner</span>  on Lilypads</h2>
          <div className="listCon">
            <img src={tck} alt="" />
            <p>
            Full visibility of everything that’s going on with your properties
            </p>
          </div>
          <div className="listCon">
            <img src={tck} alt="" />
            <p>Better access to insights. Keep track of leads and marketing data right from the app</p>
          </div>
          <div className="listCon">
            <img src={tck} alt="" />
            <p>
            Stay better connected, with logged communications with buyers
            </p>
          </div>
          <div className="listCon">
            <img src={tck} alt="" />
            <p>
            Automated contracting and closing, right where you want it
            </p>
          </div>

          <button className="MainGetStartedBTNNB btnHideM">
          Start as an Credit Partner now{' '}
            <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i>
          </button>
          <img style={{ marginLeft: '-5vw',marginBottom:"30px"}}  className="mobImg" src={mi2m} alt="" />
          <button style={{ marginLeft: '5vw'}}className="MainGetStartedBTN mobbtn fwb">   Start as an Credit Partner   <i style={{ marginLeft: '10px' }} className="fas fa-arrow-right"></i></button>

        </div>
      </div> */}


    </div>
  )
}

export default NSecThreeAP
