import React, { useEffect } from "react";
import SecOne from "./../components/SectionsLP/SecOne";
import SecTwo from "./../components/SectionsLP/SecTwo";
import SecFive from "./../components/SectionsLP/SecFive";
import SecSeven from "./../components/SectionsLP/SecSeven/SecSeven";
import Footer from "./../components/SectionsLP/Footer";
import FounderPhoto from "./../components/founderPhoto/FounderPhoto";
import SecFiveWho from "./../components/SectionWho/SecFiveWho";
import SecFourNew from "../components/SectionsLP/SecFourNew";
import SecSixNew from "./../components/SectionsLP/SecSixNew";
import LandingPageNew from "./LandingPageNew";
import CardSlider from "./../components/CardSlider/CardSlider";
import Solutions from "../components/Solutions/Solutions";
import BenchmarkSection from "./../newPages/LandingPage/BenchmarkSection";
import SafeGuardAssets from "./../newPages/LandingPage/SafeGuardAssets";
import OurProduct from "../newPages/LandingPage/OurProduct";
import OurProductMobile from "../newPages/LandingPage/OurProductMobile";
import TestimonialCard from "../components/Testimonial/TestimonialCard";

const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <SecOne />
      <BenchmarkSection />
      <SafeGuardAssets />
      <OurProductMobile />

      {/* <LandingPageNew /> */}
      <CardSlider />
      <Solutions />
      {/* <SecTwo /> */}
      {/* <SecFourNew />
      <SecFive />
      <SecSixNew /> */}
      <FounderPhoto />
      {/* <SecSeven />
      <SecFiveWho /> */}
      {/* <TestimonialCard/> */}
      <OurProduct />
      <Footer mode="landing" />
    </div>
  );
};

export default LandingPage;
