
import Footer from './../components/SectionsLP/Footer'
import SecOneND from './../components/SectionNewsDetails/SecOneND'
import { useEffect } from 'react'
import SecTwoND from './../components/SectionNewsDetails/SecTwoND'
const NewsDetailPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="NewsDetailPage">
      <SecOneND />
      <SecTwoND />
      <Footer />
    </div>
  )
}

export default NewsDetailPage
