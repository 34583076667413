import React from "react";
import Footer from "../components/SectionsLP/Footer";
import Header from "../components/Header";
// import pageImg from "./../assets/PAGESIMG/TokenPage.png";
import TEsecOne from '../newPages/TokenEnginePage/TEsecOne';
import TEsecTwo from '../newPages/TokenEnginePage/TEsecTwo';
import TEsecFeatures from "../newPages/TokenEnginePage/TEsecFeatures";

const BlueBlockNet = () => {
  return (
    <div className="BlueBlockNet">
      <Header active="1" />
      <TEsecOne/>
      <TEsecFeatures />
      <TEsecTwo/>
      {/* <img src={pageImg} alt="" className="w-100" /> */}
     
      <Footer />
    </div>
  );
};

export default BlueBlockNet;
