import React from 'react'
import Footer from './../components/SectionsLP/Footer'
import Header from './../components/Header'
import pageImg from './../assets/PAGESIMG/Securities.png'
import SecuritiesOne from './../newPages/Securities/SecuritiesOne'
import SecTwo from './../components/SectionsLP/SecTwo'

const SecuritiesPage = () => {
  return (
    <div>
      <Header active="1" />
      {/* <img src={pageImg} alt="" className="w-100" /> */}
      <SecuritiesOne />
      <SecTwo />

      <Footer mode="landing" />
    </div>
  )
}

export default SecuritiesPage
