import React from 'react'
import './CardNLSmall.scss'
import doodle from './../../../assets/NewsListing/doodle.png'
import { nds } from '../../actions'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
const CardNLSmall = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const ndR = useSelector((state) => state.ndR)

  const recomendedReadF = () => {
    window.location.reload()
    // window.scrollTo(0, 0)
  }
  return (
    <div>
      <div
        onClick={() => {
          dispatch(nds(props.index))
          history.push('/NewsDetails')

          props.recomendedRead ? recomendedReadF() : console.log('nrr')

          console.log(props.index)
          try {
            props.trigger(true)
          } catch (err) {
            console.log(err)
          }
        }}
        key={props.index}
        className="cardNls"
      >
        <div className="cardNlWrapper">
          <div className="textNl">
            <div className="time">{/* <h6>4 hours ago</h6> */}</div>
            <div className="content">
              <h2>{props.name}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${props.content}`,
                }}
              ></div>
            </div>
            <div className="footerNL">
              <div className="footerNLLeft">
                <div className="propertyBox">
                  <h5>Property</h5>
                </div>
                <h3>Prabali Chattoraj</h3>
              </div>
              <div className="footerNLRight">
                <img src={doodle} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardNLSmall
