import React, { useEffect, useState, useCallback } from "react";
import HeaderBlank from './../components/HeaderBlank';
const PrivacyPolicypage = () => {
  const [y, setY] = useState(window.scrollY);
  const [scrollD, setScrollD] = useState();
  const [direction, setDirection]  = useState("up");
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        if (window.scrollY < 290) {
          setScrollD();
        }

        setDirection("up")
        console.log("scrolling up : " + window.scrollY);
      } else if (y < window.scrollY) {
        setDirection("up")
        console.log("scrolling down : " + window.scrollY);

        if (window.scrollY > 300) {
          setScrollD(true);
        }
      }
      setY(window.scrollY);
    },
    [y]
  );
  
  useEffect(() => {
   
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <div className="TermsAndConditionPageMain">
      <HeaderBlank direction = {direction} />
      <div className="TermsAndConditionPageMainWrapper">
        <div className="leftTAC">
          <div className={"leftTACWrapper " + (scrollD && " active ")}>
            <a href="/">
              <h4>Cognier {">"} Privacy Policy</h4>
            </a>
            <h3>Privacy Policy</h3>
            <ul>
              <li>
                <a href="#s1">Overview</a>
              </li>
              <li>
                <a href="#s2">General</a>
              </li>
              <li>
                <a href="#s3">Disclaimer</a>
              </li>
              <li>
                <a href="#s4">Forward-looking and cautionary statements</a>
              </li>
              <li>
                <a href="#s5">Confidential information</a>
              </li>
              <li>
                <a href="#s6">U.S. government restricted rights</a>
              </li>
              <li>
                <a href="#s7">Business relationships</a>
              </li>
              <li>
                <a href="#s8">Linking to this site</a>
              </li>
              <li>
                <a href="#s9">DISCLAIMER OF WARRANTY</a>
              </li>
              <li>
                <a href="#s10">LIMITATION OF LIABILITY</a>
              </li>
              
            </ul>
          </div>
        </div>
        <div className="rightTAC">
          <div className="TacRComp" id="s1">
            <h3>Overview</h3>
            <span>
              The following are the terms of an agreement between you and
              Cognier Technology Group, also known as Cognier Technology Group. By accessing, or
              using this Website, you acknowledge that you have read,
              understand, and agree to be bound by these terms and to comply
              with all applicable laws and regulations, including export and
              re-export control laws and regulations. If you do not agree to
              these terms, please do not use this Web site.
            </span>
            <br />
            <br />
            <span>
              Cognier Technology Group may, without notice to you, at any time, revise these Terms of
              Use and any other information contained in this Website. Cognier Technology Group may
              also make improvements or changes in the products, services, or
              programs described in this site at any time without notice.
            </span>
          </div>
          <div className="TacRComp" id="s2">
            <h3>General</h3>
            <span>
              This Website contains proprietary notices and copyright
              information, the terms of which must be observed and followed.
            </span>
            <br />
            <br />
            <span>
              Cognier Technology Group grants you a non-exclusive, non-transferable, limited
              permission to access and display the Web pages within this site as
              a customer or potential customer of Cognier Technology Group provided you comply with
              these Terms of Use, and all copyright, trademark, and other
              proprietary notices remain intact. You may only use a crawler to
              crawl this Website as permitted by this Web site’s robots.txt
              protocol, and Cognier Technology Group may block any crawlers in its sole discretion.
              The use authorized under this agreement is non-commercial in
              nature (e.g., you may not sell the content you access on or
              through this Web site.) All other use of this site is prohibited.
            </span>
            <br />
            <br />
            <span>
              Except for the limited permission in the preceding paragraph, Cognier Technology Group
              does not grant you any express or implied rights or licenses under
              any patents, trademarks, copyrights, or other proprietary or
              intellectual property rights. You may not mirror any of the
              content from this site on another Web site or in any other media.
              Any software and other materials that are made available for
              downloading, access, or other use from this site with their own
              license terms will be governed by such terms, conditions, and
              notices. Your failure to comply with such terms or any of the
              terms on this site will result in automatic termination of any
              rights granted to you, without prior notice, and you must
              immediately destroy all copies of downloaded materials in your
              possession, custody or control.
            </span>
          </div>
          <div className="TacRComp" id="s3">
            <h3>Disclaimer</h3>
            <span>
              From time to time, this Website may contain technical inaccuracies
              or typographical errors, and we do not warrant the accuracy of any
              posted information. Please confirm you are using the most
              up-to-date pages on this Website, and confirm the accuracy and
              completeness of information before using it to make decisions
              relating to services, products, or other matters described in this
              Web site.,
            </span>
            <br />
            <br />
            <span>
              If any term in this Terms of Use is found by competent judicial
              authority to be unenforceable in any respect, the validity of the
              remainder of this Terms of Use will be unaffected, provided that
              such unenforceability does not materially affect the parties’
              rights under this Terms of Use.
            </span>
          </div>
          <div className="TacRComp" id="s4">
            <h3>Forward-looking and cautionary statements</h3>
            <span>
              Except for historical information and discussions, statements set
              forth throughout this website may constitute forward-looking
              statements within the meaning of the Private Securities Litigation
              Reform Act of 1995 or other applicable laws. These statements
              involve a number of risks, uncertainties, and other factors that
              could cause actual results to differ materially, as discussed in
              the company's filings with the U.S. Securities and Exchange
              Commission. See the “SEC filings” tab under “Investor relations”
              on this Web site for copies of such filings.
            </span>
          </div>
          <div className="TacRComp" id="s5">
            <h3>Confidential information</h3>
            <span>
              Cognier Technology Group does not want to receive confidential or proprietary
              information from you through our Website. Please note that any
              information or material sent to Cognier Technology Group will be deemed NOT to be
              confidential. By sending Cognier Technology Group any information or material, you
              grant Cognier Technology Group an unrestricted, irrevocable license to copy, reproduce,
              publish, upload, post, transmit, distribute, publicly display,
              perform, modify, create derivative works from, and otherwise
              freely use, those materials or information. You also agree that
              Cognier Technology Group is free to use any ideas, concepts, know-how, or techniques
              that you send us for any purpose. However, we will not release
              your name or otherwise publicize the fact that you submitted
              materials or other information to us unless: (a) we obtain your
              permission to use your name; or (b) we first notify you that the
              materials or other information you submit to a particular part of
              this site will be published or otherwise used with your name on
              it; or (c) we are required to do so by law.
              Personally-identifiable information that you submit to Cognier Technology Group for the
              purpose of receiving products or services will be handled in
              accordance with our privacy policies. Please see the tab entitled
              “Privacy” for information regarding Cognier Technology Group’s privacy policies.
            </span>
          </div>
          <div className="TacRComp" id="s6">
            <h3>U.S. government restricted rights</h3>
            <span>
              Cognier Technology Group provides the software downloaded from this Website to U.S.
              Government users with “RESTRICTED RIGHTS." Use, reproduction, or
              disclosure is subject to the restrictions set forth in Cognier Technology Group’s GSA
              ADP Schedule contract.
            </span>
          </div>
          <div className="TacRComp" id="s7">
            <h3>Business relationships</h3>
            <span>
              This Website may provide links or references to non-Cognier Technology Group Websites
              and resources. Cognier Technology Group makes no representations, warranties, or other
              commitments or endorsements whatsoever about any non-Cognier Technology Group Websites
              or third-party resources. When you access a non-Cognier Technology Group Website, even
              one that may contain the Cognier Technology Group-logo, please understand that it is
              not independent of Cognier Technology Group, and that Cognier Technology Group does control the content on
              that Website. It is up to you to take precautions to protect
              yourself from viruses, worms, Trojan horses, and other potentially
              destructive programs, and to protect your information.
            </span>
          </div>
          <div className="TacRComp" id="s8">
            <h3>Linking to this site</h3>
            <span>
              Cognier Technology Group consents only to links to this Website in which the link and
              the pages that are activated by the link do not: (a) create frames
              around any page on this Web site or use other techniques that
              alter in any way the visual presentation or appearance of any
              content within this site; (b) misrepresent your relationship with
              Cognier Technology Group; (c) imply that Cognier Technology Group approves or endorses you, your Website, or
              your service or product offerings; and (d) present false or
              misleading impressions about Cognier Technology Group or otherwise damage the goodwill
              associated with the Cognier Technology Group name or trademarks. As a further condition
              to being permitted to link to this site, you agree that Cognier Technology Group may at
              any time, in its sole discretion, terminate permission to link to
              this Website. In such event, you agree to immediately remove all
              links to this Web site and to cease any related use of Cognier Technology Group
              trademarks.
            </span>
          </div>
          <div className="TacRComp" id="s9">
            <h3>DISCLAIMER OF WARRANTY</h3>
            <span>
              USE OF THIS SITE IS AT YOUR SOLE RISK. ALL MATERIALS, INFORMATION,
              PRODUCTS, SOFTWARE, PROGRAMS, AND SERVICES ARE PROVIDED "AS IS,"
              WITH NO WARRANTIES OR GUARANTEES WHATSOEVER. Cognier Technology Group EXPRESSLY
              DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS,
              IMPLIED, STATUTORY, AND OTHER WARRANTIES, GUARANTEES, OR
              REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT OF PROPRIETARY AND INTELLECTUAL PROPERTY RIGHTS.
              WITHOUT LIMITATION, Cognier Technology Group MAKES NO WARRANTY OR GUARANTEE THAT THIS
              WEBSITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE.
            </span>
            <br />
            <br />
            <span>
              YOU UNDERSTAND AND AGREE THAT IF YOU DOWNLOAD OR OTHERWISE OBTAIN
              MATERIALS, INFORMATION, PRODUCTS, SOFTWARE, PROGRAMS, OR SERVICES
              FROM THIS WEBSITE, YOU DO SO AT YOUR OWN DISCRETION AND RISK AND
              THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES THAT MAY
              RESULT, INCLUDING LOSS OF DATA OR DAMAGE TO YOUR COMPUTER SYSTEM.
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF WARRANTIES, SO
              THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU
            </span>
          </div>
          <div className="TacRComp" id="s10">
            <h3>LIMITATION OF LIABILITY</h3>
            <span>
              TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT
              WILL Cognier Technology Group BE LIABLE TO ANY PARTY FOR ANY DIRECT, INDIRECT,
              INCIDENTAL, SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES OF ANY
              TYPE WHATSOEVER RELATED TO OR ARISING FROM THIS WEBSITE OR ANY USE
              OF THIS WEBSITE, OR OF ANY SITE OR RESOURCE LINKED TO, REFERENCED,
              OR ACCESSED THROUGH THIS WEBSITE, OR FOR THE USE OR DOWNLOADING
              OF, OR ACCESS TO, ANY MATERIALS, INFORMATION, PRODUCTS, OR
              SERVICES, INCLUDING, WITHOUT LIMITATION, ANY LOST PROFITS,
              BUSINESS INTERRUPTION, LOST SAVINGS OR LOSS OF PROGRAMS OR OTHER
              DATA, EVEN IF Cognier Technology Group IS EXPRESSLY ADVISED OF THE POSSIBILITY OF SUCH
              DAMAGES. THIS EXCLUSION AND WAIVER OF LIABILITY APPLIES TO ALL
              CAUSES OF ACTION, WHETHER BASED ON CONTRACT, WARRANTY, TORT, OR
              ANY OTHER LEGAL THEORIES.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicypage;
