
import jobDetailReducer from './jdR';

import {combineReducers} from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import newsDetailReducer from './ndR';
import currentPageReducer from './current';



const persistConfig = {
    key: 'root',
    storage,
    whitelist:['jdR' , 'ndR']
}

const rootReducer =combineReducers({
    ndR : newsDetailReducer,
    jdR : jobDetailReducer,
     current : currentPageReducer
});

// const allReducer = combineReducers({
//     ndR : newsDetailReducer,
//     jdR : jobDetailReducer
// });

// export default allReducer;
export default persistReducer(persistConfig,rootReducer);
